import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import ReactPaginate from "react-paginate";
import "./pagination.css";

// core components
import { Button, MenuItem, Select, Tooltip } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

//styling
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/reportsStyle';

// icons
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import { zip } from "lodash";

function Table(props) {
    const {
        classes,
        reservations,
        history,
        data,
        pageNumber,
        setPageNumber,
        index,
        setIndex,
        endIndex,
        setEndIndex,
        itemsPerPage,
        handlePageClick,
        headers,
        points,
        numberFormatter,
        numberFormatterDecimals
    } = props;

    useEffect(() => {
        data.slice(index, endIndex);
    }, [index]);
    const renderTableHeader = () => {
        return (
            <tr
                className={classes.tableRow}
                style={{
                    height: "38px",
                    border: "1px solid #8E8E8E",
                }}
            >
                {headers.map((key, index) => {
                    return (
                        <th className={classes.tableHeaders} key={index}>
                            {key.label}
                        </th>
                    );
                })}
            </tr>
        );
    };

    const renderTableData = () => {
        return data
            .slice(
                pageNumber * itemsPerPage,
                pageNumber * itemsPerPage + itemsPerPage
            )
            .map((d, index) => {
                return (
                    <tr
                        className={index % 2 === 0 ? classes.tableRow : classes.tableRowOdd}
                        key={index}
                    >
                        <td className={classes.tableCell} >{d[0].merchantNumber}</td>
                        <td className={classes.tableCell}>{d[0].merchantName}</td>
                        <td className={classes.tableCell}>{d[0].transactionCount}</td>
                        <td className={classes.tableCell}>{numberFormatter.format(Number(d[1]))}</td>
                        <td className={classes.tableCell}>${numberFormatterDecimals.format(d[0].netAmount)}</td>
                        <td className={classes.tableCell}>
                        <Tooltip
                                    id="tooltip-top"
                                    title="View"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        style={{ background: "#FECD2C" }}
                                        className={classes.actionButton}
                                        onClick={() => history.push(`/admin/reports/batch/${d[0].parentDocId}/${d[0].docId}`)}
                                    >
                                        <ViewIcon />
                                    </Button>
                                </Tooltip>
                        </td>
                    </tr>
                );
            });
    };

    return (
        <GridContainer>
            <GridItem xs={12}>
                <table className={classes.reportsTable} style={{ marginBottom: "10px", marginTop: "10px" }}>
                    <tbody>
                        {renderTableHeader()}
                        {renderTableData()}
                    </tbody>
                </table>
                <GridContainer>
                    <GridItem xs={11} style={{ marginTop: "5px", display: "flex", justifyContent: "flex-end" }}>
                        <h5
                            className={classes.indexes}
                        >
                            {index + 1} - {endIndex} of{" "}
                            {Math.ceil(data.length)}
                        </h5>
                    </GridItem>
                    <GridItem xs={1}>
                        <ReactPaginate
                            paginationSize={0}
                            onPageChange={handlePageClick}
                            pageCount={Math.ceil(data.length / itemsPerPage)}
                            nextLabel={
                                <ArrowRight
                                    style={{
                                        fontSize: 25,
                                        fontWeight: "bold",
                                        color: "white",
                                    }}
                                />
                            }
                            previousLabel={
                                <ArrowLeft
                                    style={{
                                        fontSize: 25,
                                        fontWeight: "bold",
                                        color: "white",
                                    }}
                                />
                            }
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />
                    </GridItem>
                </GridContainer>

            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(dashboardStyle)
)(React.memo(Table));
