import React, { createRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { useDropzone } from 'react-dropzone';
import Dropzone from 'react-dropzone'
// core components
import GridContainer from 'components/Grid/GridContainer';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem';
import { Button, Card, Hidden, IconButton, MenuItem, Select, TextField } from '@material-ui/core';

// icons
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";
import { BsDownload } from "react-icons/bs";


// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/rewardsStyle';
import { remove } from 'lodash';
import { Close } from '@material-ui/icons';


export function RewardGallery(props) {
    const {
        classes,
        history,
        rewardImages,
        setRewardImages,
    } = props;



    const removeImage = (index) => {
        const newFiles = [...rewardImages];     // make a var for the new array
        newFiles.splice(index, 1);        // remove the file from the array
        setRewardImages(newFiles);
    }


    return (
        <div>
            <Hidden smDown implementation="css">
                <Card className={classes.card} style={{ marginTop: "15px" }}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <h5 className={classes.cardHeader}>Reward Gallery</h5>
                        </GridItem>
                        <GridItem xs={12}>
                            <div style={{ marginLeft: "20px" }}>
                                <Dropzone
                                    multiple
                                    open
                                    noClick
                                    accept=".jpeg, .png, .jpg"
                                    onDrop={acceptedFiles => {
                                        var array = [...rewardImages]
                                        Array.from(acceptedFiles).forEach(file => {
                                            if (!array.includes(file)) {
                                                array.unshift(file)
                                            }

                                        });
                                        setRewardImages(array)

                                    }}>
                                    {({ getRootProps, getInputProps, open }) => (
                                        <div {...getRootProps()}
                                            className={classes.imgSquare}>
                                            <input {...getInputProps()} />
                                            {rewardImages.length === 0 ?
                                                <div style={{ textAlign: "center" }}>
                                                    <p style={{ marginTop: "10px", fontSize: "18px" }}>
                                                        <BsDownload
                                                            style={{ color: "#56CA93", fontSize: "50px", marginBottom: "-10px" }} />
                                                        {"  "}
                                                    </p>
                                                    <p>
                                                        Drop reward image files here or click  “Add reward gallery images” below
                                                    </p>
                                                </div>
                                                :
                                                <GridContainer >
                                                    {rewardImages.map((f, i) => {
                                                        return (
                                                            <>
                                                                <GridItem xs={6} style={{ display: "flex" }} key={i} >
                                                                    <div >
                                                                        <IconButton
                                                                            style={{ width: "14px", height: "21px", color: "#56CA93" }}
                                                                            onClick={() => removeImage(i)}
                                                                        >
                                                                            <Close />
                                                                        </IconButton>
                                                                        <img
                                                                            key={i}
                                                                            style={{ height: "50px", width: '50px', margin: "5px" }}
                                                                            src={typeof (f) === "string" ? f : URL.createObjectURL(f)}
                                                                        />
                                                                    </div>
                                                                </GridItem>
                                                            </>
                                                        )
                                                    })}
                                                </GridContainer>
                                            }
                                            <p
                                                style={{ textDecoration: "underline", cursor: "pointer", }}
                                                className={classes.imgDescription}
                                                onClick={open}
                                            >
                                                Add reward gallery images
                                            </p>
                                        </div>
                                    )}
                                </Dropzone>
                            </div>
                        </GridItem>
                        {rewardImages.length === 0 ?
                            <>
                                <GridItem style={{ marginTop: "15px" }} xs={12}>
                                    <p
                                        style={{ fontSize: "14px" }}
                                        className={classes.imgDescription}
                                    >
                                        For best results, upload JPEG or PNG files that are
                                        1000 by 1000 pixels or larger.
                                        Maximum upload file size: 100 MB.
                                    </p>
                                </GridItem>
                            </>
                            :
                            null
                        }
                    </GridContainer>
                </Card>
            </Hidden>
        </div>
    );
}
function mapStateToProps(state) {
    return {
        // nothing yet
    };
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}
export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(RewardGallery));