import React, { useEffect, useState } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import ItemInfo from "./components/ItemInfo";
// styling
import Style from "assets/jss/material-dashboard-pro-react/views/cartStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import tuzoLogo from "assets/img/tuzo/Tuzo-square.png";

// core components
import { Hidden, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Summary from "./components/Summary";
import Loader from "components/Loader/Loader";

export function CartPage(props) {
  const { classes, history, cart } = props;

  const [totalAmount, setTotalAmount] = useState(0);
  const [copyArr, setCopyArr] = useState([]);

  const numberFormatterDecimals = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const numberFormatter = new Intl.NumberFormat("en-US", {});

  useEffect(() => {
    const total = cart.reduce(
      (partialSum, a) => partialSum + Number(a.salePrice),
      0
    );
    setTotalAmount(total);
    const newArr = [...cart];
    newArr.map((d) => (d.checked = true));
    setCopyArr(newArr);
  }, [cart]);

  if (cart.length > 0 && copyArr.length === 0) {
    return <Loader />;
  }

  const handleCheck = (i) => {
    const arr = [...copyArr];
    const checkedValue = arr[i]["checked"];
    arr[i]["checked"] = !checkedValue;
    const totalArr = arr.filter((f) => {
      if (f.checked === true) {
        return true;
      }
    });
    const newTotal = totalArr.reduce(
      (partialSum, a) => partialSum + Number(a.salePrice),
      0
    );
    setTotalAmount(newTotal);
    setCopyArr(arr);
  };

  const deselectAll = () => {
    const arr = [...copyArr];
    for (let i = 0; i < arr.length; i++) {
      arr[i].checked = false;
    }
    const totalArr = arr.filter((f) => {
      if (f.checked === true) {
        return true;
      }
    });
    const newTotal = totalArr.reduce(
      (partialSum, a) => partialSum + Number(a.salePrice),
      0
    );
    setTotalAmount(newTotal);
    setCopyArr(arr);
  };

  return (
    <div>
      <Hidden mdUp implementation="css">
        <GridContainer style={{ marginTop: "60px" }}>
          <GridItem
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography variant="h2" className={classes.cartTitle}>
              Your Cart
            </Typography>
          </GridItem>

          <GridItem xs={12}>
            <ItemInfo
              cart={copyArr}
              totalAmount={totalAmount}
              numberFormatterDecimals={numberFormatterDecimals}
              handleCheck={handleCheck}
              deselectAll={deselectAll}
              history={history}
            />
          </GridItem>

          <GridItem xs={12} style={{ marginTop: "-30px" }}>
            <Summary
              history={history}
              cart={cart}
              totalAmount={totalAmount}
              numberFormatterDecimals={numberFormatterDecimals}
              numberFormatter={numberFormatter}
            />
          </GridItem>
        </GridContainer>
      </Hidden>

      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography variant="h2" className={classes.cartTitle}>
              Your Cart
            </Typography>
          </GridItem>

          <GridItem xs={8}>
            <ItemInfo
              cart={copyArr}
              totalAmount={totalAmount}
              numberFormatterDecimals={numberFormatterDecimals}
              handleCheck={handleCheck}
              deselectAll={deselectAll}
              history={history}
            />
          </GridItem>

          <GridItem xs={4}>
            <Summary
              history={history}
              cart={cart}
              totalAmount={totalAmount}
              numberFormatterDecimals={numberFormatterDecimals}
              numberFormatter={numberFormatter}
            />
          </GridItem>
        </GridContainer>
      </Hidden>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    cart: state.cart.cart,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({});
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(CartPage);
