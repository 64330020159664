import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Datetime from "react-datetime";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import moment from "moment"

// core components
import Box from '@material-ui/core/Box';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import Button from 'components/CustomButtons/Button.js';
import { FormControl, InputAdornment, MenuItem, Select, TextField, Typography } from '@material-ui/core';

// @material-ui/icons
import { ReactComponent as Plane } from "assets/img/tuzo/icons/Plane.svg";
import { ReactComponent as Experience } from "assets/img/tuzo/icons/Experience.svg";
import { ReactComponent as Cruise } from "assets/img/tuzo/icons/Cruise.svg";
import { ReactComponent as Hotel } from "assets/img/tuzo/icons/Hotel.svg";
import { ReactComponent as SearchIcon } from "assets/img/tuzo/icons/SearchIconGreen.svg";
import { ReactComponent as CalendarIcon } from "assets/img/tuzo/icons/calendarIconWhite.svg";
import { ReactComponent as Person } from "assets/img/tuzo/icons/Person.svg";

// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/travelStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import "./calendar.css"
import CarRentalFilter from './CarRentalFilter';


export function RewardsFilter(props) {
    const {
        classes,
        history,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        guests,
        setGuests,
        filterPageNames,
        handleTravelButtonFilter,
        travelButtonFilter,
        setTravelButtonFilter,
        guestsArr,
        searchHotel,
        dataLoaded,
        handleSelect,
        address,
        setAddress,
        radius,
        setRadius,
        radiusArr,
        pickUpTime,
        setPickUpTime,
        dropOffTime,
        setDropOffTime,
        searchCarRental,
        dropOffAddress,
        setDropOffAddress
    } = props;

    const yesterday = moment().subtract(1, "day");
    const disablePastDt = (current) => {
        return current.isAfter(yesterday);
    };
    const disableFromDt = (current) => {
        return current.isAfter(fromDate);
    };

    return (
        <div>
            <GridContainer>
                <Card className={classes.travelCardStyling}>
                    <GridContainer>
                        <GridItem xs={12}>
                            {filterPageNames.map((name) => {
                                return (
                                    <Button
                                        onClick={() => {
                                            sessionStorage.clear();
                                            handleTravelButtonFilter(name)
                                        }}
                                        style={travelButtonFilter === name ? { background: "#56CA93" } : {}}
                                        className={classes.travelFiltersButton}
                                    >
                                        {
                                            name === "Hotels" ?
                                                <Hotel style={{ marginRight: "10px", marginTop: "-5px", width: "24px", height: "24px" }} />
                                                :
                                                name === "Flights" ?
                                                    <Plane style={{ marginRight: "10px", marginTop: "-5px", width: "24px", height: "24px" }} />
                                                    :
                                                    name === "Experiences" ?
                                                        <Experience style={{ marginRight: "10px", marginTop: "-5px", width: "24px", height: "24px" }} />
                                                        :
                                                        <Cruise style={{ marginRight: "10px", marginTop: "-5px", width: "24px", height: "24px" }} />
                                        }

                                        {name}
                                    </Button>
                                )
                            })}
                        </GridItem>

                        {travelButtonFilter === "Hotels" ?
                            <GridItem xs={12} style={{ marginTop: "20px" }}>
                                <GridContainer>
                                    <GridItem xs={3} className={classes.gridItemFlexCenter}>
                                        <PlacesAutocomplete
                                            value={address}
                                            onChange={(e) => {
                                                setAddress(e)
                                            }}
                                            onSelect={(e) => handleSelect(e, "Going to")}
                                            googleCallbackName={"initOne"}
                                            searchOptions={{
                                                // componentRestrictions: { country: "us" },
                                            }}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <FormControl>
                                                        <TextField
                                                            className={classes.searchBar}
                                                            value={address}
                                                            onChange={(e) => {
                                                                setAddress(e.target.value)
                                                            }}
                                                            InputProps={{
                                                                autocomplete: "new-password",
                                                                form: {
                                                                    autocomplete: "off",
                                                                },
                                                                disableUnderline: true,
                                                                classes: {
                                                                    root: classes.searchBar,
                                                                    adornedEnd: classes.adournedEnd,
                                                                    input: classes.input
                                                                },
                                                                startAdornment: (

                                                                    <InputAdornment
                                                                        position="start"
                                                                    >
                                                                        <SearchIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            {...getInputProps({
                                                                placeholder: "Going to",
                                                                className: "location-search-input",
                                                            })}
                                                        />
                                                        <div className="autocomplete-dropdown-container">
                                                            {loading && <div>Loading...</div>}
                                                            {suggestions.map((suggestion) => {
                                                                const className = suggestion.active
                                                                    ? "suggestion-item--active"
                                                                    : "suggestion-item";
                                                                // inline style for demonstration purpose
                                                                const style = suggestion.active
                                                                    ? {
                                                                        backgroundColor: "#fafafa",
                                                                        cursor: "pointer",
                                                                        padding: "10px"
                                                                    }
                                                                    : {
                                                                        backgroundColor: "#ffffff",
                                                                        cursor: "pointer",
                                                                        padding: "10px"
                                                                    };
                                                                return (
                                                                    <div
                                                                        {...getSuggestionItemProps(suggestion, {
                                                                            className,
                                                                            style,
                                                                        })}
                                                                    >
                                                                        <span>{suggestion.description}</span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </FormControl>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </GridItem>
                                    <GridItem xs={7} className={classes.gridItemFlexCenter}>
                                        <Typography variant="h2" className={classes.travelFilterDesc} style={{ marginLeft: "0px" }}>
                                            From
                                        </Typography>

                                        <Datetime
                                            isValidDate={disablePastDt}
                                            className='travel'
                                            selected={fromDate}
                                            dateFormat="MM/DD/YY"
                                            onChange={(date, e) => {
                                                setFromDate(date.format("MM/DD/YY"));
                                                sessionStorage.setItem('fromDate', date.format("MM/DD/YY"))
                                            }}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            closeOnClickOutside
                                            renderInput={(props, openCalendar, closeCalendar) => (
                                                <div onClick={openCalendar}>
                                                    <TextField
                                                        className={classes.datetime}
                                                        value={fromDate}
                                                        variant="outlined"
                                                        InputProps={{
                                                            classes: {
                                                                adornedEnd: classes.adournedEnd,
                                                                input: classes.inputCalendar
                                                            },
                                                            endAdornment: (
                                                                <InputAdornment
                                                                    className={classes.adournedEnd}
                                                                    position="end"
                                                                >
                                                                    <Button className={classes.datetimeButton}>
                                                                        <CalendarIcon
                                                                            className={classes.calendarIcon}
                                                                            onClick={openCalendar}
                                                                        />
                                                                    </Button>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        />
                                        <Typography variant="h2" className={classes.travelFilterDesc}>
                                            To
                                        </Typography>

                                        <Datetime
                                            isValidDate={disableFromDt}
                                            className='travel'
                                            selected={toDate}
                                            dateFormat="MM/DD/YY"
                                            onChange={(date, e) => {
                                                setToDate(date.format("MM/DD/YY"));
                                                sessionStorage.setItem('toDate', date.format("MM/DD/YY"))
                                            }}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            closeOnClickOutside
                                            renderInput={(props, openCalendar, closeCalendar) => (
                                                <div onClick={openCalendar}>
                                                    <TextField
                                                        className={classes.datetime}
                                                        value={toDate}
                                                        variant="outlined"
                                                        InputProps={{
                                                            classes: {
                                                                adornedEnd: classes.adournedEnd,
                                                                input: classes.inputCalendar
                                                            },
                                                            endAdornment: (
                                                                <InputAdornment
                                                                    className={classes.adournedEnd}
                                                                    position="end"
                                                                >
                                                                    <Button className={classes.datetimeButton}>
                                                                        <CalendarIcon
                                                                            className={classes.calendarIcon}
                                                                            onClick={openCalendar}
                                                                        />
                                                                    </Button>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        />

                                        <Typography variant="h2" className={classes.travelFilterDesc}>
                                            Guests
                                        </Typography>

                                        <Select
                                            className={classes.selectMenuSortTravelPage}
                                            value={guests}
                                            disableUnderline
                                            classes={{
                                                root: classes.selectText,
                                            }}
                                            onChange={(e) => {
                                                setGuests(e.target.value)
                                            }}
                                        >
                                            {guestsArr.map((o, i) => {
                                                return (
                                                    <MenuItem
                                                        key={i}
                                                        value={o}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                        }}
                                                    >
                                                        <div style={{ display: "flex", margin: "0px 0px 0px 15px" }}>
                                                            <Person style={{ marginRight: "15px" }} /> {o}
                                                        </div>
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>

                                        <Typography variant="h2" className={classes.travelFilterDesc}>
                                            Radius(mi)
                                        </Typography>

                                        <Select
                                            className={classes.selectMenuSortTravelPage}
                                            value={radius}
                                            disableUnderline
                                            classes={{
                                                root: classes.selectText,
                                            }}
                                            onChange={(e) => {
                                                setRadius(e.target.value)
                                            }}
                                        >
                                            {radiusArr.map((o, i) => {
                                                return (
                                                    <MenuItem
                                                        key={i}
                                                        value={o}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                        }}
                                                    >
                                                        {o}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </GridItem>

                                    <GridItem xs={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        {!dataLoaded ?
                                            <Button
                                                style={{ background: "#1A405F", marginTop: "-5px", width: "100%" }}
                                                className={classes.travelFiltersButton}
                                                onClick={() => searchHotel()}
                                            >
                                                Search
                                            </Button>
                                            :
                                            <Button
                                                style={{ marginTop: "-5px", }}
                                                className={classes.updateButton}
                                                onClick={() => searchHotel()}
                                            >
                                                Update
                                            </Button>
                                        }

                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            :
                            <GridItem xs={12}>
                                <CarRentalFilter
                                    fromDate={fromDate}
                                    setFromDate={setFromDate}
                                    toDate={toDate}
                                    setToDate={setToDate}
                                    searchCarRental={searchCarRental}
                                    dataLoaded={dataLoaded}
                                    handleSelect={handleSelect}
                                    address={address}
                                    setAddress={setAddress}
                                    dropOffAddress={dropOffAddress}
                                    setDropOffAddress={setDropOffAddress}
                                    radius={radius}
                                    setRadius={setRadius}
                                    radiusArr={radiusArr}
                                    disablePastDt={disablePastDt}
                                    disableFromDt={disableFromDt}
                                    pickUpTime={pickUpTime}
                                    setPickUpTime={setPickUpTime}
                                    dropOffTime={dropOffTime}
                                    setDropOffTime={setDropOffTime}

                                />
                            </GridItem>

                        }

                    </GridContainer>
                </Card>
            </GridContainer>

        </div>
    );
}

function mapStateToProps(state) {
    return {
        // nothing yet
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(RewardsFilter));
