import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

// core components
import GridContainer from 'components/Grid/GridContainer';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem';
import { Button, Card, Hidden, MenuItem, Select, TextField } from '@material-ui/core';

// icons
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";


// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/rewardsStyle';


export function RewardName(props) {
    const { classes, history, rewardName, setRewardName } = props;
    return (
        <div>
            <Hidden smDown implementation="css">
                <Card className={classes.card}>
                    <GridContainer>
                        <GridItem xs={12} style={{ display: "flex" }}>
                            <p style={{ fontWeight: 600, marginRight:"10px", marginTop:"5px", whiteSpace: "noWrap", }}>Reward Name</p>
                            <TextField
                                className={classes.textField}
                                value={rewardName}
                                onChange={(e) => setRewardName(e.target.value)}
                                // variant="outlined"
                                InputProps={{
                                    disableUnderline: true,
                                    classes: {
                                        adornedEnd: classes.adournedEnd,
                                        input: classes.input
                                    },
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                </Card>
            </Hidden>
        </div>
    );
}
function mapStateToProps(state) {
    return {
        // nothing yet
    };
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}
export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(RewardName));