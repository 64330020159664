
import { addValidations, addWorkflow, addCloseHandlers, actionsForStep, actionsForForm } from '../form';
import { userTypes } from 'variables/config';
import AuthActions from 'store/actions/auth';
import { errorCodes } from 'variables/errors';
import { config } from '../../reducers/forms/registration';
import { push } from 'connected-react-router';
import { chain } from 'store/actions/util';
import { OpenMode } from 'variables/constants';
import { updateuserType } from '../auth.js'

function getField(state, stepName, fieldName) {
    const form = state.form.registration;
    const step = form && form.steps ? form.steps[stepName] : undefined;
    return step && step.fields ? step.fields[fieldName] : undefined;
}

function verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
        return true;
    }
    return false;
}

function validationError(message) {
    return {
        code: errorCodes.FORM_FIELD_VALIDATION_ERROR,
        message
    }
}

const basicDetailsValidations = {
    validateField: (field, value, getState) => {
        console.log('emailemailemail', field, value)
        // Don't validate blank fields
        if (value === undefined || value === '') return {};
        switch (field) {
            case "email":
                const email = getField(getState(), "basicDetails", "email");
                if (!verifyEmail(value))
                    return { email: validationError("Not a valid email address") }
                else
                    if (window.localStorage.getItem('emailForSignIn')) {
                        return { email: window.localStorage.getItem('emailForSignIn') }
                    } else {
                        return { email: undefined }
                    }

            case "password":
                const confirmPass = getField(getState(), "basicDetails", "confirmPassword");
                if (value === confirmPass)
                    return { password: undefined, confirmPass: undefined }
                else
                    return { password: validationError("Does not match confirmation"), confirmPassword: validationError("Does not match password") }
            case "confirmPassword":
                const password = getField(getState(), "basicDetails", "password");
                if (value === password)
                    return { password: undefined, confirmPass: undefined }
                else
                    return { password: validationError("Does not match confirmation"), confirmPassword: validationError("Does not match password") }
            // case "userType":
            //     if (userTypes[value])
            //         return { userType: undefined }
            //     else
            //         return { userType: validationError("Not an accepted user type") }
            case "acceptedTerms":
                if (value)
                    return { acceptedTerms: undefined }
                else
                    return { acceptedTerms: validationError("Terms must be accepted") }

        }
    }
}

const commonProfileValidations = {
    validateField: (field, value) => {
        // Don't validate blank fields
        console.log('commmmmon', field, value)
        if (value === undefined || value === '') return {};
        switch (field) {
            case "companyName":
                if (value.length > 1)
                    return { lastName: undefined }
                else
                    return { lastName: validationError("Please input your company name in full") }
            case "firstName":
                if (value.length > 1)
                    return { firstName: undefined }
                else
                    return { firstName: validationError("Please input your first name in full") }
            case "lastName":
                if (value.length > 1)
                    return { lastName: undefined }
                else
                    return { lastName: validationError("Please input your last name in full") }
            case "middleInitial":
                if (value.length == 1)
                    return { middleInitial: undefined }
                else
                    return { middleInitial: validationError("Middle initial can only be one letter") }
            case "fullName":
                if (value.length > 1)
                    return { fullName: undefined }
                else
                    return { fullName: validationError("Please input your first AND last name") }
            case "birthDate":
                if (!isNaN(Date.parse(value)))
                    return { birthDate: undefined }
                else
                    return { birthDate: validationError("Not a valid date") }
            case "officePhone":
                if (value.length > 6)
                    return { phoneNumber: undefined }
                else
                    return { phoneNumber: validationError("Please input your telephone number in full") }
            case "cellPhone":
                if (value.length > 6)
                    return { phoneNumber: undefined }
                else
                    return { phoneNumber: validationError("Please input your telephone number in full") }
            case "merchantID":
                if (value.length > 6)
                    return { merchantID: undefined }
                else
                    return { merchantID: validationError("Please input your entire ID") }
            case "userTypes":
                if (value.length < 1)
                    return { userTypes: undefined }
                else
                    return { userTypes: validationError("Please input your telephone number in full") }
            default:
                return {};
        }
    }
}

function nextStep(currentStep, getState) {
    switch (currentStep) {
        case "basicDetails":
            return { step: "commonProfile", mode: OpenMode.UPDATE };
        case "commonProfile":
            // const userType = getField(getState(), "basicDetails", "userType");
            return { step: "submit" }
        default:
            return { step: "basicDetails", mode: OpenMode.UPDATE };
    }
}

function lastStep(currentStep) {
    switch (currentStep) {
        case "commonProfile":
            return { step: "basicDetails", mode: OpenMode.UPDATE };
        default:
            return undefined;
    }
}

const closeHandlers = {
    submit: () => (dispatch, getState) => {
        console.log('getState()', getState())
        var file = ''
        if (getState().form.profilePic) {
            file = getState().form.profilePic
        }
        const registration = getState().form.registration;
        let userType = "Merchants"
        const { confirmPassword, password, ...basicDetails } = registration.steps.basicDetails.fields;
        const commonProfile = (registration.steps.commonProfile && registration.steps.commonProfile.fields) || {};
        const profile = { ...basicDetails, ...commonProfile, userType }
        return dispatch(chain(AuthActions.startEmailSignup({ email: basicDetails.email, password, file }, profile), push('/admin/user-page')));
    }
}

const form = addCloseHandlers(actionsForForm(config, "registration"), closeHandlers);
const basicDetails = addValidations(actionsForStep(config, "registration", "basicDetails"), basicDetailsValidations);
const commonProfile = addValidations(actionsForStep(config, "registration", "commonProfile"), commonProfileValidations);

export default addWorkflow(form, { basicDetails, commonProfile }, nextStep, lastStep);


