/*eslint-disable*/

const dropdown = "All"
export const initState = {
    dropdown
};

export default function reduce(state = initState, action) {
    switch (action.type) {
        case "CHANGE_VALUE":
            var toReturn = { ...state }
            toReturn.dropdown = action.dropdown
            return toReturn
        default:
            return state;
    }
}