import React, { useState, createRef, useEffect } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import actionsForNavigation from "store/actions/navigation";
import AuthActions from "store/actions/auth";
import { db, firebase, storage } from "firebase/client";
import UserInfo from "./components/UserInfo";
import BillingInfo from "./components/BillingInfo";
import { deleteUserPhoto } from "store/actions/auth";
import {
  sendIsoAdminSignUpLinkLive,
  sendSignUpLinkLive,
  sendCloudFunction,
  sendIrisCloudFunction,
  sendMavCloudFunction,
} from "firebase/client";
import { sendAdminSignUpLinkLive } from "firebase/client";
import GatewayPage from "./components/Gateway";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import Slide from "@material-ui/core/Slide";
import { MenuItem, Select, Typography } from "@material-ui/core";

import {
  updateUserPhoto,
  updateUserLogo,
  deleteUserContract,
} from "store/actions/auth.js";

// @material-ui/icons
import { ReactComponent as Add } from "assets/img/tuzo/icons/AddCircle.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";
import { ReactComponent as Delete } from "assets/img/tuzo/icons/Delete.svg";

//styling
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import tuzoBg from "assets/img/tuzo/carouselBackground.png";
import "./newProfileCalendar.css";
import PublicCalculator from "./components/PublicCalculator";
import PointsSetup from "./components/PointsSetup";

const userTypeArr = ["Merchant", "ISO", "Administrator"];

const processorArr = ["Fiserv AccessOne", "IRIS", "Maverick"];

const brandingArr = ["Default", "Co-Branding", "Priority"]

const gatewayArr = [
  {
    type: "Fiserv AccessOne",
    id: 0,
  },
  {
    type: "IRIS",
    id: 1,
  },
  {
    type: "Maverick",
    id: 2,
  },
];

export function NewProfile(props) {
  const { classes, history, profilePic } = props;

  const EmailValidation = require("emailvalid");
    const ev = new EmailValidation({ allowFreemail: true,  whitelist: ['att.net', 'ymail.com']});
  const [type, setType] = useState("Merchant");
  const [processorName, setProcessorName] = useState("Fiserv AccessOne");
  const [userBasisPts, setUserBasisPts] = useState("");
  const [signUpBonus, setSignUpBonus] = useState("");
  const [docFile, setDocFile] = useState([]);
  const [merchantId, setMerchantId] = useState("");
  const [username, setUsername] = useState("");
  const [firstNameState, setFirstNameState] = useState("");
  const [lastNameState, setLastNameState] = useState("");
  const [emailState, setEmailState] = useState("");
  const [cellPhoneState, setCellPhoneState] = useState("");
  const [secondaryPhoneState, setSecondaryPhoneState] = useState("");
  const [shippingAddressState, setShippingAddressState] = useState("");
  const [companyState, setCompanyState] = useState("");
  const [birthdayState, setBirthdayState] = useState("");
  const [whiteLabel, setWhiteLabel] = useState("Default");
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [globalEdit, setGlobalEdit] = useState(false);
  const [billingEdit, setBillingEdit] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [expDate, setExpDate] = useState("");
  const [nameOnCard, setNameOnCard] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [asDefault, setAsDefault] = useState(true);
  const [newPayment, setNewPayment] = useState(false);
  const [typeOfPayment, setTypeOfPayment] = useState({
    card: true,
    ach: false,
  });
  const [apiUsername, setApiUsername] = useState("");
  const [apiPassword, setApiPassword] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [errors, setErrors] = useState({
    username: false,
    firstName: false,
    lastName: false,
    company: false,
    points: false,
    id: false,
    email: false,
    emailTypo: false,
    apiUsername,
    apiPassword,
  });
  const [gateway, setGateway] = useState("Fiserv AccessOne");
  const [apiInfo, setApiInfo] = useState([
    {
      apiType: "Fiserv AccessOne",
      apiKey: "",
      apiSecret: "",
      status: "Unused",
      id: 0,
    },
    {
      apiType: "IRIS",
      apiKey: "",
      apiSecret: "",
      status: "Unused",
      id: 1,
    },
    {
      apiType: "Maverick",
      apiKey: "",
      apiSecret: "",
      status: "Unused",
      id: 2,
    },
  ]);
  const [ptsSchedule, setPtsSchedule] = useState("60");
  const [autoApprove, setAutoApprove] = useState(false);
  const [lockSchedule, setLockSchedule] = useState(false);

  useEffect(() => {
    if (progress === 100) {
      setUploading(false);
    }
  }, [progress]);

  function formatPhoneNumber(phoneNumber) {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return "N/A";
  }

  const handleDropImgUpload = (image) => {
    console.log("checking image", image);
    var previewUrl = URL.createObjectURL(image[0]);
    var file = image[0];
    setImageFile(previewUrl);
    const storageRef = storage.ref("newUser");
    let fileRef;

    if (type === "Merchants" || type === "Administrator") {
      fileRef = storageRef.child("profilePic");
    } else {
      fileRef = storageRef.child("logoPic");
    }

    var uploadTask = fileRef.put(file);

    // fileRef.put(file).then((snapshot) => {
    setUploading(true);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      (snapshot) => {
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then((downloadURL) => {
            console.log("downloadURL", downloadURL);
            setImgUrl(downloadURL);
            // props.updateUserPhoto(uid, { url: downloadURL, });
          })
          .catch(() => {});
      }
    );
  };

  const handleImageUpload = (event) => {
    var previewUrl = URL.createObjectURL(event.target.files[0]);
    var file = event.target.files[0];
    setImageFile(previewUrl);
    const storageRef = storage.ref("newUser");
    let fileRef;

    if (type === "Merchants" || type === "Administrator") {
      fileRef = storageRef.child("profilePic");
    } else {
      fileRef = storageRef.child("logoPic");
    }

    var uploadTask = fileRef.put(file);

    // fileRef.put(file).then((snapshot) => {
    setUploading(true);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      (snapshot) => {
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then((downloadURL) => {
            console.log("downloadURL", downloadURL);
            setImgUrl(downloadURL);
            // props.updateUserPhoto(uid, { url: downloadURL, });
          })
          .catch(() => {});
      }
    );
  };

  const getBgImage = () => {
    return tuzoBg;
  };

  const deleteImage = () => {
    setImageFile("");
  };

  const handleDocUpload = (event) => {
    var file = event.target.files[0];

    const storageRef = storage.ref("contracts");
    let fileRef;

    fileRef = storageRef.child(file.name);

    var uploadTask = fileRef.put(file);

    // fileRef.put(file).then((snapshot) => {
    setUploading(true);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Documnet Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      (snapshot) => {
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then((downloadURL) => {
            const fileName = uploadTask.snapshot.ref.name;

            const uploadInfo = {
              downloadURL: downloadURL,
              filePath: `contracts/${fileName}`,
              fileName: fileName,
            };

            console.log(uploadInfo);

            setDocFile((prevDocFile) => [...prevDocFile, uploadInfo]);
          })
          .catch(() => {});
      }
    );
  };

  const deleteDoc = (fileName, filePath) => {
    setDocFile((prevDocFile) =>
      prevDocFile.filter((file) => file.filePath !== filePath)
    );

    props.deleteUserContract(fileName, filePath);
  };

  const sendCustomerEmail = async (e) => {
    e.preventDefault();
    const emailCheck = ev.check(emailState);
    if (
      (type === "Merchant" && processorName.includes("Fiserv AccessOne")) ||
      (processorName.includes("Maverick") &&
        (!userBasisPts ||
          !companyState ||
          !emailState ||
          !merchantId ||
          !username ||
          !apiUsername ||
          !apiPassword ||
          !firstNameState ||
          !lastNameState ||
          emailCheck.typo ||
          emailCheck.valid === false))
    ) {
      let newErrs = {
        username: false,
        firstName: false,
        lastName: false,
        company: false,
        points: false,
        id: false,
        email: false,
        emailTypo: false,
        apiUsername: false,
        apiPassword: false,
      };
      if (!username) {
        newErrs = { ...newErrs, username: true };
      }
      if (!apiUsername) {
        newErrs = { ...newErrs, apiUsername: true };
      }
      if (!apiPassword) {
        newErrs = { ...newErrs, apiPassword: true };
      }
      if (!firstNameState) {
        newErrs = { ...newErrs, firstName: true };
      }
      if (!lastNameState) {
        newErrs = { ...newErrs, lastName: true };
      }
      if (!companyState) {
        newErrs = { ...newErrs, company: true };
      }
      if (!userBasisPts) {
        newErrs = { ...newErrs, points: true };
      }
      if (!merchantId) {
        newErrs = { ...newErrs, id: true };
      }
      if (!emailState) {
        newErrs = { ...newErrs, email: true };
      }
      if (emailCheck.typo || emailCheck.valid === false) {
        newErrs = { ...newErrs, emailTypo: true };
      }
      setErrors({ ...newErrs });
    } else if (
      type === "Merchant" &&
      processorName !== "Fiserv AccessOne" &&
      (!userBasisPts ||
        !companyState ||
        !emailState ||
        !merchantId ||
        !username ||
        !apiUsername ||
        !firstNameState ||
        !lastNameState ||
        emailCheck.typo ||
        emailCheck.valid === false)
    ) {
      let newErrs = {
        username: false,
        firstName: false,
        lastName: false,
        company: false,
        points: false,
        id: false,
        email: false,
        emailTypo: false,
        apiUsername: false,
      };
      if (!username) {
        newErrs = { ...newErrs, username: true };
      }
      if (!apiUsername) {
        newErrs = { ...newErrs, apiUsername: true };
      }
      if (!firstNameState) {
        newErrs = { ...newErrs, firstName: true };
      }
      if (!lastNameState) {
        newErrs = { ...newErrs, lastName: true };
      }
      if (!companyState) {
        newErrs = { ...newErrs, company: true };
      }
      if (!userBasisPts) {
        newErrs = { ...newErrs, points: true };
      }
      if (!merchantId) {
        newErrs = { ...newErrs, id: true };
      }
      if (!emailState) {
        newErrs = { ...newErrs, email: true };
      }
      if (emailCheck.typo || emailCheck.valid === false) {
        newErrs = { ...newErrs, emailTypo: true };
      }
      setErrors({ ...newErrs });
    } else if (
      type === "ISO" &&
      (!userBasisPts ||
        !companyState ||
        !emailState ||
        !username ||
        !firstNameState ||
        !lastNameState ||
        emailCheck.typo ||
        emailCheck.valid === false)
    ) {
      let newErrs = {
        username: false,
        firstName: false,
        lastName: false,
        company: false,
        points: false,
        email: false,
        emailTypo: false,
      };
      if (!username) {
        newErrs = { ...newErrs, username: true };
      }
      if (!firstNameState) {
        newErrs = { ...newErrs, firstName: true };
      }
      if (!lastNameState) {
        newErrs = { ...newErrs, lastName: true };
      }
      if (!companyState) {
        newErrs = { ...newErrs, company: true };
      }
      if (!userBasisPts) {
        newErrs = { ...newErrs, points: true };
      }
      if (!emailState) {
        newErrs = { ...newErrs, email: true };
      }
      if (emailCheck.typo || emailCheck.valid === false) {
        newErrs = { ...newErrs, emailTypo: true };
      }
      setErrors({ ...newErrs });
    } else if (
      type === "Administrator" &&
      (!companyState ||
        !emailState ||
        !username ||
        !firstNameState ||
        !lastNameState ||
        emailCheck.typo ||
        emailCheck.valid === false)
    ) {
      let newErrs = {
        username: false,
        firstName: false,
        lastName: false,
        company: false,
        email: false,
        emailTypo: false,
      };
      if (!username) {
        newErrs = { ...newErrs, username: true };
      }
      if (!firstNameState) {
        newErrs = { ...newErrs, firstName: true };
      }
      if (!lastNameState) {
        newErrs = { ...newErrs, lastName: true };
      }
      if (!companyState) {
        newErrs = { ...newErrs, company: true };
      }
      if (!emailState) {
        newErrs = { ...newErrs, email: true };
      }
      if (emailCheck.typo || emailCheck.valid === false) {
        newErrs = { ...newErrs, emailTypo: true };
      }
      setErrors({ ...newErrs });
    } else {
      const docId = db.collection("temp-user").doc().id;
      let tempData = {};
      if (type === "Merchant") {
        tempData = {
          processor: processorName,
          userType: "Merchants",
          username,
          firstName: firstNameState,
          lastName: lastNameState,
          docFile: docFile,
          companyName: companyState,
          birthday: birthdayState,
          email: emailState,
          cellPhone: cellPhoneState,
          secondaryPhone: secondaryPhoneState,
          shippingAddress: shippingAddressState,
          id: docId,
          url: imgUrl,
          userBasisPts: Number(userBasisPts),
          merchantId,
          emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
          apiUsername,
          apiPassword,
        };
      } else if (type === "ISO") {
        tempData = {
          userType: "IsoAdmin",
          username,
          firstName: firstNameState,
          lastName: lastNameState,
          companyName: companyState,
          docFile: docFile,
          birthday: birthdayState,
          email: emailState,
          cellPhone: cellPhoneState,
          secondaryPhone: secondaryPhoneState,
          shippingAddress: shippingAddressState,
          id: docId,
          url: imgUrl,
          branding: whiteLabel,
          signUpBonus,
          userBasisPts: Number(userBasisPts),
          emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
          apiInfo,
          autoApprove,
          ptsReleaseDay: ptsSchedule,
          lockSchedule,
        };
      } else {
        tempData = {
          userType: "Admin",
          username,
          firstName: firstNameState,
          lastName: lastNameState,
          companyName: companyState,
          docFile: docFile,
          birthday: birthdayState,
          email: emailState,
          cellPhone: cellPhoneState,
          secondaryPhone: secondaryPhoneState,
          shippingAddress: shippingAddressState,
          id: docId,
          url: imgUrl,
          emailSendDate: firebase.firestore.Timestamp.fromDate(new Date()),
        };
      }

      await db
        .collection("temp-user")
        .doc(docId)
        .set(tempData)
        .then(() => {
          if (type.includes("Merchant")) {
            sendSignUpLinkLive(tempData);
          } else if (type.includes("Admin")) {
            sendAdminSignUpLinkLive(tempData);
          } else {
            sendIsoAdminSignUpLinkLive(tempData);
          }
        })
        .then(async () => {
          if (type === "Merchant") {
            let idObj = {
              merchantId: merchantId,
              apiUsername: apiUsername,
              apiPassword: apiPassword,
              apiUrl: companyState.companyName.replace(/ /g, "").toLowerCase(),
            };
            if (processorName.includes("IRIS")) {
              await sendIrisCloudFunction(idObj);
            } else if (processorName.includes("Maverick")) {
              await sendMavCloudFunction(idObj);
            } else {
              await sendCloudFunction(idObj);
            }
          }
        })
        .then(() => {
          history.push("/admin/dashboard");
        });
    }
  };

  return (
    <div>
      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem xs={8} style={{ display: "flex" }}>
            <Typography variant="h2" className={classes.headerTitle}>
              New User
            </Typography>
            <div>
              <Button
                className={classes.editButton}
                style={{ background: "#1A405F", width: "128px" }}
                onClick={sendCustomerEmail}
              >
                <SaveIcon className={classes.icons} /> Save
              </Button>
              <Button
                onClick={() => history.goBack()}
                className={classes.editButton}
                style={{ background: "#E2A400", width: "143px" }}
              >
                <Delete className={classes.icons} /> Cancel
              </Button>

              <Button
                className={classes.editBillingButton}
                style={{ width: "284px", background: "#56CA93" }}
                onClick={sendCustomerEmail}
              >
                <Add className={classes.icons} /> Send Verification Email
              </Button>
            </div>
          </GridItem>

          <GridItem
            xs={2}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "-10px",
              }}
            >
              <Typography variant="h2" className={classes.headerDesc}>
                User Type
              </Typography>
              <Select
                style={{ marginTop: "0px", width: "150px", paddingTop: "5px" }}
                className={classes.selectMenuSortProfile}
                value={type}
                disableUnderline
                classes={{
                  root: classes.selectText,
                }}
                onChange={(e) => setType(e.target.value)}
              >
                {userTypeArr.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </GridItem>

          <GridItem
            xs={2}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "-10px",
              }}
            >
              <Typography variant="h2" className={classes.headerDesc}>
                Account Number
              </Typography>
              <Typography
                variant="h2"
                className={classes.headerDesc}
                style={{
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "18px",
                  marginTop: "10px",
                }}
              >
                -----
              </Typography>
            </div>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} style={{ marginTop: "20px" }}>
            <UserInfo
              docFile={docFile}
              deleteDoc={deleteDoc}
              handleDocUpload={handleDocUpload}
              handleDropImgUpload={handleDropImgUpload}
              merchantId={merchantId}
              setMerchantId={setMerchantId}
              userBasisPts={userBasisPts}
              setUserBasisPts={setUserBasisPts}
              profilePic={profilePic}
              setImageFile={setImageFile}
              imageFile={imageFile}
              username={username}
              setUsername={setUsername}
              apiUsername={apiUsername}
              setApiUsername={setApiUsername}
              apiPassword={apiPassword}
              setApiPassword={setApiPassword}
              firstNameState={firstNameState}
              setFirstNameState={setFirstNameState}
              lastNameState={lastNameState}
              setLastNameState={setLastNameState}
              companyState={companyState}
              setCompanyState={setCompanyState}
              birthdayState={birthdayState}
              setBirthdayState={setBirthdayState}
              emailState={emailState}
              setEmailState={setEmailState}
              cellPhoneState={cellPhoneState}
              setCellPhoneState={setCellPhoneState}
              secondaryPhoneState={secondaryPhoneState}
              setSecondaryPhoneState={setSecondaryPhoneState}
              shippingAddressState={shippingAddressState}
              setShippingAddressState={setShippingAddressState}
              globalEdit={globalEdit}
              handleImageUpload={handleImageUpload}
              uploading={uploading}
              progress={progress}
              deleteImage={deleteImage}
              formatPhoneNumber={formatPhoneNumber}
              type={type}
              setType={setType}
              userTypeArr={userTypeArr}
              whiteLabel={whiteLabel}
              setWhiteLabel={setWhiteLabel}
              signUpBonus={signUpBonus}
              setSignUpBonus={setSignUpBonus}
              processorName={processorName}
              setProcessorName={setProcessorName}
              processorArr={processorArr}
              brandingArr={brandingArr}
              errors={errors}
            />
          </GridItem>

          {type === "Merchant" ? (
            <GridItem xs={12}>
              <BillingInfo
                billingEdit={billingEdit}
                setBillingEdit={setBillingEdit}
                cardNumber={cardNumber}
                setCardNumber={setCardNumber}
                expDate={expDate}
                setExpDate={setExpDate}
                nameOnCard={nameOnCard}
                setNameOnCard={setNameOnCard}
                billingAddress={billingAddress}
                setBillingAddress={setBillingAddress}
                asDefault={asDefault}
                setAsDefault={setAsDefault}
                newPayment={newPayment}
                setNewPayment={setNewPayment}
                typeOfPayment={typeOfPayment}
                setTypeOfPayment={setTypeOfPayment}
              />
            </GridItem>
          ) : type === "ISO" ? (
            <>
              <GridItem xs={4}>
                <GatewayPage
                  gatewayArr={gatewayArr}
                  gateway={gateway}
                  setGateway={setGateway}
                  apiInfo={apiInfo}
                  setApiInfo={setApiInfo}
                />
              </GridItem>

              <GridItem xs={4}>
                <PublicCalculator
                  defaultCalcBasisPts={userBasisPts}
                  setDefaultCalcBasisPts={setUserBasisPts}
                  errors={errors}
                />
              </GridItem>

              <GridItem xs={4}>
                <PointsSetup
                  ptsSchedule={ptsSchedule}
                  setPtsSchedule={setPtsSchedule}
                  autoApprove={autoApprove}
                  setAutoApprove={setAutoApprove}
                  lockSchedule={lockSchedule}
                  setLockSchedule={setLockSchedule}
                />
              </GridItem>
            </>
          ) : null}
        </GridContainer>
      </Hidden>
    </div>
  );
}

NewProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    edit: (key) =>
      dispatch(
        actionsForNavigation.openChild("Profile Editor", `/admin/user/editor`)
      ),
    updateUserPhoto: (id, document) => dispatch(updateUserPhoto(id, document)),
    deleteUserPhoto: (id) => dispatch(deleteUserPhoto(id)),
    updateUserLogo: (id, document) => dispatch(updateUserLogo(id, document)),
    deleteUserContract: (fileName, filePath) =>
      dispatch(deleteUserContract(fileName, filePath)),
    logout: () => dispatch(AuthActions.startLogout()),
  });
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(NewProfile);
