const wishlistStyle = (theme) => ({
    wishlistTitle: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "30px",
        color: "#1A405F"
    },
    gridSpace: {
        display: "flex",
        padding: "0px 40px"
    },
    wishlistCardListStyling: {
        height: "335px",
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        padding: "10px",
        borderRadius: "7px",
        boxShadow: "2px #DADADA",
        color: "#1A405F",
        justifyContent: "center",
        width: "420px",
        [theme.breakpoints.down("lg")]: {
            width: "400px"
        },
    },
    cardListImg: {
        width: "180px",
        height: "150px",
        objectFit: "contain",
    },
    divHover: {
        textAlign: "center",
        borderBottom: "1px solid #56CA93",
        "&:hover ": {
            cursor: "pointer"
        }
    },
    cardListTitleNoOverflow: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "20px",
        textTransform: "uppercase",
        marginTop: "10px",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    divStyle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    outerCard: {
        padding: "0px 10px",
        borderRadius: "7px",
        border: "0.5px solid #56CA93",
        boxShadow: "0px 2px 2px 0px #DADADA",
    },
    cardListDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "16px",
        textTransform: "capitalize",
        marginTop: "10px",
        paddingLeft: "10px"
    },
    cardListPts: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        fontSize: "17px",
        lineHeight: "17px",
        marginTop: "10px",
        paddingLeft: "10px"
    },
    noWishlistCard: {
        border: "0.5px solid #56CA93",
        alignItems: "center",
        padding: "50px 0px",
    },
    noWishlistTitle: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "21px",
        lineHeight: "21px",
        textAlign: "center",
        color: "#1A405F",
        marginTop: "30px",
        // [theme.breakpoints.down("sm")]: {
        //     fontSize: "24px",
        // },
    },
    noWishlistNotBold: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "21px",
        textAlign: "center",
        color: "#1A405F",
        marginTop: "20px"
    },
    noWishlistButton: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "17px",
        lineHeight: "17px",
        color: "#FFFFFF",
        background: "#56CA93",
        textTransform: "capitalize",
        height: "42px",
        padding: "15px",
        borderRadius: "7px",
        marginTop: "20px",
        "&:hover": {
            background: "#56CA93",
        },
    },
    headerDesc: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "15px",
        textTransform: "capitalize",
        color: "#1A405F",
        lineHeight: "15px"
    },
    indexes: {
        display: "flex",
        justifyContent: "flex-end",
        fontWeight: "500",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        color: "#1A405F"
    },
})

export default wishlistStyle;