// /*eslint-disable*/
import React, { useEffect, useState } from 'react';
import Recaptcha from "react-google-recaptcha"
import { db } from 'firebase/client';
import { sendTempPassword } from 'firebase/client';
import PasswordPage from './registration/PasswordPage';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import compose from "recompose/compose";

// @material-ui/core components
import { Button, Card, Hidden, IconButton, TextField, Typography } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Close } from '@material-ui/icons';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';


//styling
import Style from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import withStyles from "@material-ui/core/styles/withStyles";


export function RegisterPage(props) {
  const { classes, history } = props;
  const [newTempPw, setNewTempPw] = useState("")
  const [checkCap, setCheckCap] = useState(false)
  const [nextPage, setNextPage] = useState(true)
  const [companyName, setCompanyName] = useState("")
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("");
  const [merchantId, setMerchantId] = useState("")
  const [checkForNext, setCheckForNext] = useState(false)
  const [tempId, setTempId] = useState("")

  useEffect(() => {
    if (checkCap && merchantId && companyName && email) {
      setCheckForNext(true)
    }
  }, [checkCap, merchantId])


  const generateTempPw = () => {
    let charList = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!^+%&/()=?_#${[]}|;:>÷`<.*-@'
    const charLength = charList.length
    let pw = ''

    for (let i = 0; i < 16; i++) {
      pw += charList.charAt(Math.floor(Math.random() * charLength)
      )
    }
    return pw
  }

  const sendTempPw = async () => {
    const generatePw = generateTempPw();
    setNewTempPw(generatePw)
    const data = {
      companyName,
      firstName: fullName.split(" ")[0],
      lastName: fullName.split(" ")[1] ? fullName.split(" ")[1] : "",
      email,
      merchantId,
      newPw: generatePw
    }
    await db
      .collection('temp-user')
      .add(data)
      .then(async function (docRef) {
        setTempId(docRef.id)
        db.collection('temp-user').doc(docRef.id).update({
          tempPw: generatePw,
          id: docRef.id
        })
      })
      .then(() => {
        sendTempPassword(data)
        setNextPage(true)
      })
  }

  if (nextPage) {
    return <PasswordPage companyName={companyName} firstName={fullName.split(" ")[0]}
      lastName={fullName.split(" ")[1]} email={email} tempId={tempId} tempUserPw={newTempPw} merchantId={merchantId} />
  }

  return (
    <div style={{ overflow: "auto" }}>
      <Hidden mdUp implementation='css'>
        <Card className={classes.mobileMainContainer}>
          <CardHeader className={classes.cardHeaderTitle}>
            <GridContainer>
              <GridItem xs={6}>
                Sign Up
              </GridItem>

              <GridItem xs={6} style={{ display: "flex", justifyContent: "end" }}>
                <IconButton
                  style={{ color: "#004253", marginTop: "-15px" }}
                  onClick={() => history.push('/auth/login-page')}
                >
                  <Close />
                </IconButton>
              </GridItem>
              <GridItem xs={12}
                style={{ fontSize: "14px", fontWeight: 400, color: "#929292" }}
              >
                Step 1 of 2
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody className={classes.cardBody} style={{ marginTop: "30px" }}>
            <GridContainer>
              <GridItem xs={12}>
                <Typography className={classes.textFieldLabel}>
                  Company Name*
                </Typography>
                <TextField
                  onChange={(e) => setCompanyName(e.target.value)}
                  className={classes.textFieldMobile}
                  value={companyName}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input
                    },
                  }}
                />
              </GridItem>

              <GridItem xs={12} className={classes.gridItemMobileMarginTop}>
                <Typography className={classes.textFieldLabel}>
                  Full Name
                </Typography>
                <TextField
                  onChange={(e) => setFullName(e.target.value)}
                  className={classes.textFieldMobile}
                  value={fullName}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input
                    },
                  }}
                />
              </GridItem>

              <GridItem xs={12} className={classes.gridItemMobileMarginTop}>
                <Typography className={classes.textFieldLabel}>
                  Email*
                </Typography>
                <TextField
                  onChange={(e) => setEmail(e.target.value)}
                  className={classes.textFieldMobile}
                  value={email}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input
                    },
                  }}
                />
              </GridItem>

              <GridItem xs={12} className={classes.gridItemMobileMarginTop}>
                <Typography className={classes.textFieldLabel}>
                  Merchant ID*
                </Typography>
                <TextField
                  className={classes.textFieldMobile}
                  value={merchantId}
                  onChange={(e) => setMerchantId(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input
                    },
                  }}
                />

                {/* <Button
                  className={classes.addIdButtonMobile}
                  style={{ marginTop: "35px" }}
                >
                  Add Merchant ID
                </Button> */}
              </GridItem>

              <GridItem xs={12}>
                <div className={classes.captchaDiv}>
                  <Recaptcha
                    sitekey="6LcC51YoAAAAAIYCdO7dQ_BANZBPVLmKE_k3xAhU"
                    onChange={() => setCheckCap(true)}
                    onExpired={() => setCheckCap(false)}
                    size="normal"
                  />
                </div>
              </GridItem>

              <GridItem xs={12} >
                <Button
                  className={classes.addIdButtonMobile}
                  style={{ background: "#56CA93", width: "100%" }}
                  disabled={!checkForNext}
                  onClick={() => {
                    sendTempPw()
                    // callMerchantInfo()
                  }}
                >
                  Next
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <div className={classes.linkContainerMobile}>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <span className={classes.termsStyle}
              // onClick={handlePasswordReset}
              onClick={() => window.open("https://www.tuzorewards.com/terms-of-use/", "_blank", 'noopener,noreferrer')}

            >
              Terms of Use
            </span>
            <span className={classes.termsStyle}
              // onClick={handlePasswordReset}
              onClick={() => window.open("https://www.tuzorewards.com/privacy-policy/", "_blank", 'noopener,noreferrer')}
            >
              Privacy Policy
            </span>
          </div>
        </div>
      </Hidden>

      <Hidden smDown implementation='css'>
        <Card className={classes.mainContainer}>
          <CardHeader className={classes.cardHeaderTitle}>
            <GridContainer>
              <GridItem xs={6}>
                Sign Up
              </GridItem>

              <GridItem xs={6} style={{ display: "flex", justifyContent: "end" }}>
                <IconButton
                  style={{ color: "#004253", marginTop: "-15px" }}
                  onClick={() => history.push('/auth/login-page')}
                >
                  <Close />
                </IconButton>
              </GridItem>
              <GridItem xs={12}
                style={{ fontSize: "14px", fontWeight: 400, color: "#929292" }}
              >
                Step 1 of 2
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody className={classes.cardBody} style={{ marginTop: "30px" }}>
            <GridContainer>
              <GridItem xs={4}>
                <div style={{ display: "flex", flexDirection: "column", height: "23vh", justifyContent: "space-between" }}>
                  <Typography className={classes.textFieldLabel}>
                    Company Name*
                  </Typography>

                  <Typography className={classes.textFieldLabel}>
                    Full Name
                  </Typography>

                  <Typography className={classes.textFieldLabel}>
                    Email*
                  </Typography>

                  <Typography className={classes.textFieldLabel}>
                    Merchant ID*
                  </Typography>
                </div>
                <Button
                  className={classes.addIdButton}
                  style={{ margin: "36px 0px 10px 0px" }}
                >
                  Add Merchant ID
                </Button>
              </GridItem>

              <GridItem xs={8}>
                <div style={{ display: "flex", flexDirection: "column", height: "25vh", justifyContent: "space-between" }}>

                  <TextField
                    onChange={(e) => setCompanyName(e.target.value)}
                    className={classes.textField}
                    value={companyName}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.input
                      },
                    }}
                  />

                  <TextField
                    onChange={(e) => setFullName(e.target.value)}
                    className={classes.textField}
                    value={fullName}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.input
                      },
                    }}
                  />

                  <TextField
                    onChange={(e) => setEmail(e.target.value)}
                    className={classes.textField}
                    value={email}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.input
                      },
                    }}
                  />

                  <TextField
                    className={classes.textField}
                    value={merchantId}
                    onChange={(e) => setMerchantId(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.input
                      },
                    }}
                  />
                </div>
              </GridItem>

              <GridItem xs={6}>
                <div style={{ transform: "scale(0.95)", marginTop: "5px", marginLeft: "-10px" }}>
                  <Recaptcha
                    sitekey="6LcC51YoAAAAAIYCdO7dQ_BANZBPVLmKE_k3xAhU"
                    onChange={() => setCheckCap(true)}
                    onExpired={() => setCheckCap(false)}
                  />
                </div>
              </GridItem>

              <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  className={classes.addIdButton}
                  style={{ background: "#56CA93", marginLeft: "15px", }}
                  disabled={!checkForNext}
                  onClick={() => {
                    sendTempPw()
                    // callMerchantInfo()
                  }}
                >
                  Next
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <div className={classes.linkContainerMobile}>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <span className={classes.termsStyle}
              // onClick={handlePasswordReset}
              onClick={() => window.open("https://www.tuzorewards.com/terms-of-use/", "_blank", 'noopener,noreferrer')}

            >
              Terms of Use
            </span>
            <span className={classes.termsStyle}
              // onClick={handlePasswordReset}
              onClick={() => window.open("https://www.tuzorewards.com/privacy-policy/", "_blank", 'noopener,noreferrer')}
            >
              Privacy Policy
            </span>
          </div>
        </div>
      </Hidden>
    </div>
  );
}

function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {

  }
}


export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(RegisterPage));
