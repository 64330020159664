import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import ReactPaginate from "react-paginate";
import "./pagination.css";
import moment from "moment";

// mui core
import { MenuItem, Select } from "@material-ui/core";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// styling
import withStyles from "@material-ui/core/styles/withStyles";
import reportsStyle from 'assets/jss/material-dashboard-pro-react/views/reportsStyle';

// icons
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import { zip } from "lodash";

function BatchDetailsTable(props) {
    const {
        classes,
        reservations,
        history,
        detailsData,
        pageNumber,
        setPageNumber,
        index,
        setIndex,
        endIndex,
        setEndIndex,
        itemsPerPage,
        handlePageClick,
        headers,
        points
    } = props;




    useEffect(() => {
        detailsData.slice(index, endIndex);
    }, [index]);
    const renderTableHeader = () => {
        return (
            <tr
                // className={classes.tableRow}
                style={{
                    height: "38px",
                    border: "1px solid #8E8E8E",
                }}
            >
                {headers.map((key, index) => {
                    return (
                        <th className={classes.tableHeaders} key={index}>
                            {key.label}
                        </th>
                    );
                })}
            </tr>
        );
    };

    const renderTableData = () => {
        let data = zip(detailsData, points)
        return data
            .slice(
                pageNumber * itemsPerPage,
                pageNumber * itemsPerPage + itemsPerPage
            )
            .map((d, index) => {
                return (
                    <tr
                        className={index % 2 === 0 ? classes.tableRow : classes.tableRowOdd}
                        key={index}
                    >
                        <td className={classes.tableCell} style={{ textAlign: "left" }}> {d[0].cardDescription} Ending in {d[0].cardNumber.split('x')[4]}</td>
                        <td className={classes.tableCell}>{d[0].authorizationNumber}</td>
                        <td className={classes.tableCell}>
                            {d[0].transactionTime.nil ? moment(d[0].reportDate).format("MM/DD/YYYY") :
                                moment(d[0].reportDate).format("MM/DD/YYYY") + " " + moment(d[0].transactionTime, "hh:mm:ss").format("hh:mm A")}
                        </td>
                        <td className={classes.tableCell}>${Number(d[0].transactionAmount).toFixed(2)}</td>
                        <td className={classes.tableCell}>
                            <p
                                style={d[1] === 0 ? {
                                    fontWeight: 700,
                                    marginLeft: "-15px"
                                }
                                    : {
                                        fontWeight: 700,
                                    }}>
                                {d[1] === 0 ? "< 1" : d[1]}
                            </p>

                        </td>
                    </tr>
                );
            });
    };

    return (
        <GridContainer>
            <GridItem xs={12}>
                <table className={classes.reportsTable} style={{ marginBottom: "10px", marginTop: "10px" }}>
                    <tbody>
                        {renderTableHeader()}
                        {renderTableData()}
                    </tbody>
                </table>
                <GridContainer>
                    <GridItem xs={11} style={{ marginTop: "5px", display: "flex", justifyContent: "flex-end" }}>
                        <h5
                            className={classes.indexes}
                        >
                            {index + 1} - {endIndex} of{" "}
                            {Math.ceil(detailsData.length)}
                        </h5>
                    </GridItem>
                    <GridItem xs={1}>
                        <ReactPaginate
                            paginationSize={0}
                            onPageChange={handlePageClick}
                            pageCount={Math.ceil(detailsData.length / itemsPerPage)}
                            nextLabel={
                                <ArrowRight
                                    style={{
                                        fontSize: 25,
                                        fontWeight: "bold",
                                        color: "white",
                                    }}
                                />
                            }
                            previousLabel={
                                <ArrowLeft
                                    style={{
                                        fontSize: 25,
                                        fontWeight: "bold",
                                        color: "white",
                                    }}
                                />
                            }
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />
                    </GridItem>
                </GridContainer>

            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(reportsStyle)
)(React.memo(BatchDetailsTable));
