import { max } from "lodash";

const profileStyle = (theme) => ({
  headerTitle: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "24px",
    textTransform: "capitalize",
    color: "#1A405F",
    lineHeight: "24px",
  },
  headerDesc: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "15px",
    textTransform: "capitalize",
    color: "#1A405F",
    lineHeight: "15px",
  },
  profileBannerTextBreakSpace: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "20px",
    color: "#FFFFFF",
    lineHeight: "20px",
    paddingLeft: "20px",
    [theme.breakpoints.down("1500")]: {
      whiteSpace: "break-spaces",
    },
  },
  profileBannerText: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "20px",
    color: "#FFFFFF",
    lineHeight: "20px",
  },
  profileBannerDurationText: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "17px",
    color: "#FFFFFF",
    lineHeight: "33px",
  },
  profileBannerPts: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "27px",
    textTransform: "capitalize",
    color: "#56CA93",
    lineHeight: "27px",
  },
  profileBannerDurationPts: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "20px",
    textTransform: "capitalize",
    color: "#56CA93",
    lineHeight: "33px",
  },
  editButton: {
    width: "120px",
    borderRadius: "5px",
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "19px",
    lineHeight: "19px",
    textTransform: "capitalize",
    color: "#FFFFFF",
    background: "#FECD2C",
    height: "42px",
    "&:hover, &:focus": {
      backgroundColor: "#FECD2C",
    },
    marginLeft: "15px",
    marginTop: "-10px",
    paddingTop: "15px",
    opacity: "100%",
  },
  editButtonMobile: {
    width: "140px",
    borderRadius: "5px",
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "16px",
    textTransform: "capitalize",
    color: "#FFFFFF",
    background: "#FECD2C",
    height: "42px",
    "&:hover, &:focus": {
      backgroundColor: "#FECD2C",
    },
    paddingTop: "15px",
    opacity: "100%",
  },
  profileCardStyling: {
    padding: "25px",
    borderRadius: "7px",
    boxShadow: "0px 1px 4px 0px #00000040",
    height: "100%",
    overflow: "visible",
    minHeight: "215px",
  },
  profileCardStyling2: {
    padding: "25px",
    borderRadius: "7px",
    boxShadow: "0px 1px 4px 0px #00000040",
    height: "100%",
    width: "100%",
    // [theme.breakpoints.up("1366")]: {
    //     width: "422px"
    // },
    // [theme.breakpoints.up("1700")]: {
    //     width: "100%"
    // },
    // [theme.breakpoints.down("md")]: {
    //     width: "100%"
    // },
  },
  cardHeader: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "20px",
    color: "#1A405F",
  },
  cardHeaderModal: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "30px",
    color: "#1A405F",
    textAlign: "center",
  },
  cardDesc: {
    fontFamily: "Josefin Sans",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16px",
    color: "#1A405F",
    marginTop: "20px",
  },
  cardDescLineHeight: {
    fontFamily: "Josefin Sans",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#1A405F",
    marginTop: "20px",
  },
  cardDescBilling: {
    fontFamily: "Josefin Sans",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16px",
    color: "#1A405F",
    marginTop: "20px",
    whiteSpace: "noWrap",
  },
  cardDescBillingMobile: {
    fontFamily: "Josefin Sans",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#1A405F",
    marginTop: "10px",
    whiteSpace: "normal",
  },
  cardDescBillingNewPaymentMobile: {
    fontFamily: "Josefin Sans",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#1A405F",
    marginTop: "10px",
    whiteSpace: "normal",
  },
  cardDescLessWeight: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "16px",
    color: "#1A405F",
    marginTop: "20px",
    whiteSpace: "pre-wrap",
  },
  cardDescWeight500: {
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "16px",
    color: "#1A405F",
    marginTop: "20px",
    whiteSpace: "pre-wrap",
  },
  cardDescLessWeightEmail: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "16px",
    color: "#1A405F",
    marginTop: "20px",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  cardDescLessWeightPaddingLeft: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "16px",
    color: "#1A405F",
    marginTop: "20px",
    paddingLeft: "10px",
  },
  cardDescLessWeightOverflow: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "16px",
    color: "#1A405F",
    marginTop: "20px",
    whiteSpace: "pre-wrap",
  },
  cardDescLessWeightContactInfo: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "16px",
    color: "#1A405F",
    marginTop: "20px",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    overflowWrap: "break-word",
  },
  img: {
    width: "120px",
    height: "120px",
    objectFit: "contain",
  },
  uploadButton: {
    width: "140px",
    borderRadius: "7px",
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "19px",
    lineHeight: "19px",
    textTransform: "capitalize",
    color: "#FFFFFF",
    background: "#1A405F",
    height: "42px",
    "&:hover, &:focus": {
      backgroundColor: "#1A405F",
    },
    marginRight: "15px",
    marginTop: "15px",
  },
  uploadButtonIso: {
    width: "140px",
    borderRadius: "7px",
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "19px",
    lineHeight: "19px",
    textTransform: "capitalize",
    color: "#FFFFFF",
    background: "#1A405F",
    height: "42px",
    "&:hover, &:focus": {
      backgroundColor: "#1A405F",
    },
    "&.MuiButtonBase-root.Mui-disabled": {
      color: "#FFFFFF !important",
      opacity: ".7",
    },
    marginRight: "15px",
    marginTop: "15px",
  },
  copyButton: {
    width: "200px",
    borderRadius: "7px",
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "19px",
    lineHeight: "19px",
    textTransform: "capitalize",
    color: "#FFFFFF",
    background: "#FECD2C",
    height: "42px",
    "&:hover, &:focus": {
      backgroundColor: "#FECD2C",
    },
    marginLeft: "20px",
    marginTop: "-10px",
    [theme.breakpoints.down("1400")]: {
      width: "100%",
    },
  },
  uploadButtonMobile: {
    width: "100%",
    borderRadius: "7px",
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "19px",
    lineHeight: "19px",
    textTransform: "capitalize",
    color: "#FFFFFF",
    background: "#1A405F",
    height: "42px",
    "&:hover, &:focus": {
      backgroundColor: "#1A405F",
    },
    marginRight: "15px",
    marginTop: "15px",
    padding: "8px 10px",
  },
  editBillingButton: {
    width: "100px",
    borderRadius: "7px",
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "19px",
    lineHeight: "19px",
    textTransform: "capitalize",
    color: "#FFFFFF",
    background: "#FECD2C",
    height: "42px",
    "&:hover, &:focus": {
      backgroundColor: "#FECD2C",
    },
    marginLeft: "10px",
    marginTop: "-10px",
    [theme.breakpoints.down("1000")]: {
      marginLeft: "0",
      width: "50%",
    },
    opacity: "100%",
  },
  uploadDocument: {
    background: "#f6f6f6",
    height: "300px",
    borderRadius: "8px",
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "52px",
    color: "#56CA93",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 30,
  },
  contractDesc: {
    fontFamily: "Josefin Sans",
    fontWeight: 600,
    fontSize: "16px",
    color: "#1A405F",
    marginTop: 10,
  },
  userInfoTextField: {
    width: "100%",
    background: "white",
    height: "30px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    border: "1px solid #1A405F",
    paddingLeft: "5px",
    borderRadius: "7px",
    marginTop: "14px",
  },
  userInfoTextFieldContactFirst: {
    width: "100%",
    background: "white",
    height: "30px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    border: "1px solid #1A405F",
    paddingLeft: "5px",
    borderRadius: "7px",
    marginTop: "20px",
  },
  userInfoTextFieldContact: {
    width: "100%",
    background: "white",
    height: "30px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    border: "1px solid #1A405F",
    paddingLeft: "5px",
    borderRadius: "7px",
    marginTop: "35px",
  },
  billingInfoTextField: {
    width: "60%",
    background: "white",
    height: "30px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    border: "1px solid #1A405F",
    paddingLeft: "5px",
    borderRadius: "7px",
    marginTop: "10px",
    marginLeft: "20px",
    [theme.breakpoints.down("1000")]: {
      width: "100%",
      marginLeft: "0px",
    },
  },
  input: {
    color: "#1A405F",
    fontWeight: 400,
    fontFamily: "Josefin Sans",
    fontSize: "16px",
    "&.MuiInputBase-input:-webkit-autofill": {
      height: "14px",
      marginLeft: "-5px",
      paddingLeft: "5px",
    },
  },
  inputNoPadding: {
    color: "#1A405F",
    fontWeight: 400,
    fontFamily: "Josefin Sans",
    fontSize: "16px",
    padding: "0px",
  },
  inputDatetime: {
    color: "#1A405F",
    fontWeight: 400,
    fontFamily: "Josefin Sans",
    fontSize: "16px",
    marginLeft: "-10px",
    // '&::placeholder': {
    //     opacity:1,
    //     fontFamily: "Josefin Sans",
    //     fontWeight: 400,
    //     color:"#1A405F",
    //   },
  },
  datetimeButton: {
    background: "#1A405F",
    display: "block",
    margin: "0px !important",
    padding: "5px 0px !important",
    height: "30px",
    color: "white",
    width: "40px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 500,
    "&:hover": {
      background: "#1A405F",
    },
  },
  adournedEnd: {
    paddingRight: "0px !important",
    margin: "0px !important",
    maxHeight: "none !important",
    height: "30px",
  },
  deleteButton: {
    width: "100px",
    borderRadius: "7px",
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "15px",
    textTransform: "capitalize",
    color: "#FFFFFF",
    background: "#56CA93",
    height: "30px",
    "&:hover, &:focus": {
      backgroundColor: "#56CA93",
    },
    marginTop: "5px",
    marginLeft: "20px",
    paddingTop: "10px",
  },
  checkboxLabel: {
    fontFamily: "Josefin Sans",
    fontSize: "15px",
    lineHeight: "15px",
    fontWeight: 400,
    color: "#1A405F",
    marginTop: "15px",
  },
  noProfilePic: {
    background: "#1A405F",
    width: "120px",
    height: "120px",
    borderRadius: "8px",
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "52px",
    color: "#56CA93",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icons: {
    marginRight: "10px !important",
    marginTop: "-5px",
  },
  tighterGrid: {
    marginRight: "-5px",
  },
  marginTopGrid: {
    marginTop: "20px",
  },
  confirmModalCard: {
    padding: "15px",
    boxShadow: "0px 1px 4px 0px #00000040",
    borderRadius: "7px",
    margin: "0px",
    width: "434px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  noPaddingGridItem: {
    padding: "0 !important",
  },
  rootAddress: {
    width: "100%",
    background: "white",
    height: "30px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    border: "1px solid #1A405F",
    // paddingLeft: "5px",
    borderRadius: "7px",
    marginTop: "14px",
  },
  selectMenuItem: {
    fontFamily: "Josefin Sans",
    color: "#1A405F",
    fontWeight: "500",
    fontSize: "16px",
  },
  selectMenuSortProfile: {
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#1A405F",
    background: "#FFFFFF",
    border: "1px solid #1A405F",
    borderRadius: "7px",
    width: "100%",
    height: "30px",
    textAlign: "center",
    margin: "15px 0px 0px 0px",
  },
  selectMenuSort: {
    fontFamily: "Josefin Sans",
    color: "#1A405F",
    background: "#FFFFFF",
    border: "2px solid #1A405F",
    borderRadius: "7px",
    width: "30%",
    height: "40px",
    textAlign: "center",
    fontWeight: "500",
    paddingTop: "5px",
    margin: "0px 0px 10px 0px",
  },
  selectAsc: {
    color: "#1A405F",
    fontFamily: "Josefin Sans",
    background: "#FFFFFF",
    borderRadius: "7px",
    border: "2px solid #1A405F",
    width: "18%",
    height: "40px",
    textAlign: "center",
    fontWeight: "500",
    margin: "0px 0px 10px 10px",
  },
  selectPlaceHolder: {
    fontWeight: 500,
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "16px",
    color: "#1A405F",
    paddingTop: "5px",
    opacity: "1 !important",
  },
  selectText: {
    fontWeight: "500",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "16px",
    color: "#1A405F",
  },
  viewWishListButton: {
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#FFFFFF",
    borderRadius: "7px",
    background: "#FECD2C",
    "&:hover": {
      background: "#FECD2C",
    },
    textTransform: "capitalize",
    marginLeft: "20px",
  },
  icon: {
    marginRight: "10px",
  },
  errText: {
    fontFamily: "Josefin Sans",
    fontWeight: 600,
    color: "red",
  },
  autoApprove: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#56CA93 !important",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      color: "#56CA93 !important",
      backgroundColor: "#56CA93",
      opacity: 1,
    },
    "& .MuiSwitch-track": {
      backgroundColor: "red",
    },
    color: "#1A405F",
    "&.MuiSwitch-root": {
      marginLeft: "-10px",
      marginTop: "7px",
    },
  },
  changeSchedule: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#FF0000",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#FF0000",
      opacity: 0,
    },
    "& .MuiSwitch-track": {
      opacity: 0,
    },
    color: "#56CA93",
    "&.MuiSwitch-root": {
      marginLeft: "0px",
      marginTop: "5px",
    },
  },
  datetime: {
    marginTop: "5px",
    width: "100%",
    height: "30px !important",
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "17px",
    textTransform: "capitalize",
    borderRadius: "7px",
    background: "white",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#1A405F",
      },
      "&:hover fieldset": {
        borderColor: "#1A405F",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1A405F",
      },
    },
  },
  popperTitle: {
    fontFamily: "Josefin Sans",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16px",
    color: "#1A405F",
    whiteSpace: "noWrap",
    display: "flex",
    justifyContent: "center",
    margin: "10px",
  },
  downloadButton: {
    width: "280px",
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "16px",
    background: "#E2A400",
    color: "#FFFFFF",
    textTransform: "capitalize",
    borderRadius: "7px",
    "&:hover": {
      background: "#E2A400",
    },
    marginTop: "10px",
  },
  closeButton: {
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "16px",
    background: "#E2A400",
    color: "#FFFFFF",
    textTransform: "capitalize",
    borderRadius: "7px",
    "&:hover": {
      background: "#E2A400",
    },
    fontWeight: 700,
    height: "30px",
    width: "20px",
  },
  addModalButton: {
    width: "120px",
    borderRadius: "5px",
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "19px",
    lineHeight: "19px",
    textTransform: "capitalize",
    color: "#FFFFFF",
    background: "#FECD2C",
    height: "42px",
    "&:hover, &:focus": {
      backgroundColor: "#FECD2C",
    },
    marginLeft: "15px",
    paddingTop: "15px",
    opacity: "100%",
  },
  addModalCard: {
    padding: "15px",
    boxShadow: "0px 1px 4px 0px #00000040",
    borderRadius: "7px",
    margin: "0px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  requestModal: {
    padding: "15px",
    overflow: "hidden",
    position: "absolute",
    top: "0% !important",
    left: "5% !important",
  },
  addUserModal: {
    padding: "15px",
    overflow: "hidden",
    position: "absolute",
    top: "0% !important",
    left: "5% !important",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "1500px", // Set your width here
      },
    },
  },

  uploadUserModal: {
    padding: "15px",
    overflow: "hidden",
    position: "absolute",
    top: "0% !important",
    left: "5% !important",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "500px",
        height: "100%",
        maxHeight: "230px",
      },
    },
  },

  tooltipPts: {
    fontFamily: "Josefin Sans",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 400,
    textAlign: "center",
    backgroundColor: "#1A405F !important",
    color: "white",
    whiteSpace: "pre-line",
    textAlign: "left",
  },
  infoIcon: {
    color: "#1A405F",
    marginTop: "-15px",
  },
  divHover: {
    "&:hover": {
      cursor: "pointer !important",
    },
  },
  imgSquare: {
    height: "100%",
    border: "2px dashed #56CA93",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
  },
  rewardsTable: {
    width: "100% !important",
    borderCollapse: "collapse",
    padding: "0px 30px 0px 20px"
    // [theme.breakpoints.down("md")]: {
    //   tableLayout: "fixed",
    //   width: "100%",
    // },
  },
  tableRow: {
    height: "30px",
    background: "#FFFFFF !important"
  },
  tableRowOdd: {
    height: "30px",
    background: "#E8EBEF  !important"
  },
  tableHeaders: {
    border: "0.5px solid #1A405F",
    background: "#1A405F",
    color: "#FFFFFF",
    fontFamily: "Josefin Sans",
    fontSize: "15px",
    padding: "10px",
    textTransform: "uppercase",
  },
  tableCell: {
    textAlign: "center",
    border: "0.5px solid #1A405F",
    padding: "10px 20px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#1A405F",
    
  },
  indexes: {
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: "500",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    color: "#1A405F"
  },
  sortByTypography: {
    fontFamily: "Josefin Sans",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "16px",
    color: "#1A405F",
    marginRight:"10px"
  },
  input: {
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    color: "#1A405F"
  },
  searchBar: {
    width: "100%",
    background: "white",
    height: "40px",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    border: "2px solid #1A405F",
    paddingLeft: "10px",
    borderRadius: "7px",
    color: "#1A405F"
  },
  adournedEndFilter: {
    paddingRight: "0px !important",
    margin: "0px !important",
    maxHeight: "none !important",
    height: "40px"
  },
  searchButton: {
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "7px",
    background: "#1A405F",
    // display: "block",
    margin: "-5px -1px 0px -1px !important",
    padding: "8px 14px !important",
    height: "40px",
    width: "120px",
    color: "#FFFFFF",
    fontFamily: "Josefin Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    textTransform: "capitalize",
    "&:hover, &:active, &:focus": {
      background: "#1A405F",
    },
  },
  
});

export default profileStyle;
