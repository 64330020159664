/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

//mui core
import { Button, Card, Checkbox, InputAdornment, MenuItem, Select, Snackbar, TextField, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

//components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

//styling
import withStyles from '@material-ui/core/styles/withStyles';
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";

//icons
import { ReactComponent as Copy } from "assets/img/tuzo/icons/CopyIcon.svg";
import { ReactComponent as Embed } from "assets/img/tuzo/icons/EmbedIcon.svg";

export function PublicCalculator(props) {
    const {
        classes,
        defaultCalcBasisPts,
        setDefaultCalcBasisPts,
        errors
    } = props;

    const [defaultBasisPts, setDefaultBasisPts] = useState("")
    const [copy, setCopy] = useState(false)
    const [iFrame, setIFrame] = useState(false)

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} style={{ marginTop: "15px" }}>
                    <Card className={classes.profileCardStyling}>
                        <GridContainer>
                            <GridItem xs={12} style={{ display: "flex" }}>
                                <Typography variant="h2" className={classes.cardHeader} style={{ whiteSpace: 'nowrap' }}>
                                    Public Calculator
                                </Typography>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDescWeight500}>
                                            Default Basis Points
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <TextField
                                            className={classes.userInfoTextField}
                                            value={defaultCalcBasisPts}
                                            onChange={(e) => setDefaultCalcBasisPts(e.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                        {errors.points ? (
                                            <span className={classes.errText}>Please fill out the field above</span>
                                        ) : null}
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                        </GridContainer>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(profileStyle)
)(PublicCalculator));