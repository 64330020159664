import React, { useEffect } from "react";
import { useState } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { db } from "firebase/client";

// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Button, Card, Hidden, Typography } from "@material-ui/core";

// styling
import Style from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  CheckCircle,
  DesktopAccessDisabledSharp,
  Settings,
} from "@material-ui/icons";
import { orderBy } from "lodash";
import Loader from "components/Loader/Loader";

export function Notifications(props) {
  const { classes, history, userId, user } = props;

  const [notiData, setNotiData] = useState(false);

  const handleAllRead = () => {
    db.collection("users")
      .doc(userId)
      .collection("notifications")
      .get()
      .then((snapshots) => {
        if (!snapshots.empty) {
          snapshots.forEach((s) => {
            let docId = s.id;
            db.collection("users")
              .doc(userId)
              .collection("notifications")
              .doc(docId)
              .update({
                read: true,
              });
          });
        }
      })
      .then(() => {
        getData();
      });
  };

  const getData = () => {
    const arr = [];
    return db
      .collection("users")
      .doc(userId)
      .collection("notifications")
      .orderBy("timestamp", "desc")
      .get()
      .then((snapshots) => {
        if (!snapshots.empty) {
          snapshots.forEach((s) => {
            s = s.data();
            arr.push(s);
          });
          setNotiData(arr);
        } else {
          setNotiData([]);
        }
      });
  };

  useEffect(() => {
    if (!notiData) {
      getData();
    }
  }, []);

  if (!notiData) return <Loader />;

  return (
    <div>
      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h2" className={classes.title}>
              Notifications
            </Typography>

            <Button
              onClick={() => handleAllRead()}
              className={classes.markButton}
            >
              <CheckCircle style={{ marginRight: "10px" }} /> Mark All as Read
            </Button>

            {/* <Button
                            style={{ background: "#56CA93" }}
                            className={classes.markButton}
                        >
                            <Settings style={{ marginRight: "10px" }} /> Notifications Settings
                        </Button> */}
          </GridItem>

          <GridItem xs={12}>
            {notiData.map((d, i) =>
              d.read === true ? (
                <Card className={classes.card}>
                  {d.type === "Assign Reward" ? (
                    <div>
                      <Typography className={classes.cardDesc}>
                        {d.data + " "}
                        <span className={classes.cardDescUnderline}>
                          Click here
                        </span>{" "}
                        to view reward.
                      </Typography>
                    </div>
                  ) : d.type === "Points Approved" ? (
                    <div>
                      <Typography className={classes.cardDesc}>
                        {d.data + " "}
                        <span
                          className={classes.cardDescUnderline}
                          onClick={() => {
                            if (user.IsoAdminCompanyName) {
                              history.push(
                                `/iso-merchant/${user.IsoAdminCompanyName.replace(
                                  / /g,
                                  ""
                                ).toLowerCase()}/${user.companyName
                                  .replace(/ /g, "")
                                  .toLowerCase()}/wish-list`
                              );
                            } else {
                              history.push("/merchant/wish-list");
                            }
                          }}
                        >
                          Click here{" "}
                        </span>{" "}
                        to view your wish list.
                      </Typography>
                    </div>
                  ) : (
                    <div>
                      <Typography className={classes.cardDesc}>
                        {d.data + " "}
                        <span
                          className={classes.cardDescUnderline}
                          onClick={() => {
                            window.open(
                              "https://www.tuzorewards.com/contact/",
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        >
                          Click here
                        </span>{" "}
                        to contact us.
                      </Typography>
                    </div>
                  )}
                  {d.message ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <Typography
                        className={classes.cardDesc}
                        style={{ fontWeight: 700 }}
                      >
                        Message:
                      </Typography>
                      <Typography
                        className={classes.cardDesc}
                        style={{ marginLeft: "5px" }}
                      >
                        {d.message}
                      </Typography>
                    </div>
                  ) : null}
                </Card>
              ) : (
                <Card className={classes.cardUnread}>
                  {d.type === "Assign Reward" ? (
                    <div>
                      <Typography className={classes.cardDesc}>
                        {d.data + " "}
                        <span
                          className={classes.cardDescUnderline}
                          onClick={() => {
                            if (user.IsoAdminCompanyName) {
                              history.push(
                                `/iso-merchant/${user.IsoAdminCompanyName.replace(
                                  / /g,
                                  ""
                                ).toLowerCase()}/${user.companyName
                                  .replace(/ /g, "")
                                  .toLowerCase()}/reward-detail/${
                                  d.rewardData.rewardId
                                }`,
                                {
                                  rewardData: devicePixelRatio,
                                }
                              );
                            } else {
                              history.push(
                                `/merchant/reward-detail/${d.rewardData.rewardId}`,
                                {
                                  rewardData: d.rewardData,
                                }
                              );
                            }
                          }}
                        >
                          Click here
                        </span>{" "}
                        to view reward.
                      </Typography>
                    </div>
                  ) : d.type === "Points Approved" ? (
                    <div>
                      <Typography className={classes.cardDesc}>
                        {d.data + " "}
                        <span
                          className={classes.cardDescUnderline}
                          onClick={() => {
                            if (user.IsoAdminCompanyName) {
                              history.push(
                                `/iso-merchant/${user.IsoAdminCompanyName.replace(
                                  / /g,
                                  ""
                                ).toLowerCase()}/${user.companyName
                                  .replace(/ /g, "")
                                  .toLowerCase()}/wish-list`
                              );
                            } else {
                              history.push("/merchant/wish-list");
                            }
                          }}
                        >
                          Click here{" "}
                        </span>{" "}
                        to view your wish list.
                      </Typography>
                    </div>
                  ) : (
                    <div>
                      <Typography className={classes.cardDesc}>
                        {d.data + " "}
                        <span
                          className={classes.cardDescUnderline}
                          onClick={() => {
                            window.open(
                              "https://www.tuzorewards.com/contact/",
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        >
                          Click here
                        </span>{" "}
                        to contact us.
                      </Typography>
                    </div>
                  )}
                  {d.message ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <Typography
                        className={classes.cardDesc}
                        style={{ fontWeight: 700 }}
                      >
                        Message:
                      </Typography>
                      <Typography
                        className={classes.cardDesc}
                        style={{ marginLeft: "5px" }}
                      >
                        {d.message}
                      </Typography>
                    </div>
                  ) : null}
                </Card>
              )
            )}
          </GridItem>
        </GridContainer>
      </Hidden>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userId: state.auth.user.uid,
    user: state.auth.user,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({});
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(Notifications)
);
