import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

//styling
import Style from 'assets/jss/material-dashboard-pro-react/views/rewardsStyle';
import withStyles from '@material-ui/core/styles/withStyles';

//icons
import { ReactComponent as NoRewardsIcon } from "assets/img/tuzo/icons/NoRewardsIcon.svg";
import { ReactComponent as NoTravelIcon } from "assets/img/tuzo/icons/NoTravelIcon.svg";

import { AiFillGift } from 'react-icons/ai';

//components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Button, Typography } from '@material-ui/core';
import Card from 'components/Card/Card';

export function NoRewardsPage(props) {
    const {
        classes,
        handleButtonFilter,
        buttonFilter
    } = props

    return (
        <div>
            <GridContainer>
                <GridItem xs={12}>
                    {buttonFilter === "Travel" ?
                        <div>
                            <Card className={classes.noRewardsCard}>
                                <NoTravelIcon style={{ width: "50%", height:"50%" }} />

                                <Typography className={classes.noRewardsTitle}>
                                    No travel rewards have been added yet
                                </Typography>


                                <Typography className={classes.noRewardsNotBold}>
                                    Email us at info@tuzorewards.com now to start planning your next vacation.
                                    Please include your name and contact information in the email
                                    and we'll book your flights, hotels, rentals cars, and more!
                                </Typography>

                                <Button
                                    className={classes.noRewardsButton}
                                    onClick={() => handleButtonFilter("All")}
                                >
                                    <AiFillGift className={classes.icon} style={{ marginRight: "10px" }} /> Browse All Rewards
                                </Button>
                            </Card>
                        </div>
                        :
                        <Card className={classes.noRewardsCard}>
                            <NoRewardsIcon />

                            <Typography className={classes.noRewardsTitle}>
                                There are no rewards to display at the moment
                            </Typography>


                            <Typography className={classes.noRewardsNotBold}>
                                Check back later to see when we have updated our reward offerings
                            </Typography>

                            <Button
                                className={classes.noRewardsButton}
                                onClick={() => handleButtonFilter("All")}
                            >
                                <AiFillGift className={classes.icon} style={{ marginRight: "10px" }} /> Browse All Rewards
                            </Button>
                        </Card>
                    }

                </GridItem>
            </GridContainer>

        </div>
    )
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(NoRewardsPage));