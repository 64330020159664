/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

//mui core
import { Button, Card, Checkbox, InputAdornment, MenuItem, Select, Snackbar, TextField, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

//components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

//styling
import withStyles from '@material-ui/core/styles/withStyles';
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";

//icons
import { ReactComponent as Copy } from "assets/img/tuzo/icons/CopyIcon.svg";
import { ReactComponent as Embed } from "assets/img/tuzo/icons/EmbedIcon.svg";

export function PublicCalculator(props) {
    const {
        classes,
        globalEdit,
        userData,
        defaultCalcBasisPts,
        setDefaultCalcBasisPts
    } = props;

    const [defaultBasisPts, setDefaultBasisPts] = useState("")
    const [copy, setCopy] = useState(false)
    const [iFrame, setIFrame] = useState(false)

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} style={{ marginTop: "15px" }}>
                    <Card className={classes.profileCardStyling}>
                        <GridContainer>
                            <GridItem xs={12} style={{ display: "flex" }}>
                                <Typography variant="h2" className={classes.cardHeader} style={{whiteSpace:'nowrap'}}>
                                    Public Calculator
                                </Typography>
                                {!globalEdit ?
                                    <div>
                                        <CopyToClipboard text={`https://tuzo.app/auth/${userData.companyName.replace(/ /g, '').toLowerCase()}/iso-calculator`}
                                            onCopy={() => setCopy(true)}>
                                            <Button
                                                className={classes.copyButton}
                                            >
                                                <Copy style={{ marginRight: "10px", marginTop: "-5px" }} /> Copy Public URL
                                            </Button>
                                        </CopyToClipboard>
                                        <Snackbar
                                            open={copy}
                                            onClose={() => setCopy(false)}
                                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                            autoHideDuration={3000}
                                            style={{ top: "80px" }}
                                        >
                                            <Alert
                                                onClose={() => setCopy(false)}
                                                severity={"success"}
                                                className={classes.alert}
                                            >
                                                Link Copied
                                            </Alert>
                                        </Snackbar>
                                    </div>
                                    : null}
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDescWeight500}>
                                            Default Basis Points
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        {globalEdit ?
                                            <>
                                                <TextField
                                                    className={classes.userInfoTextField}
                                                    value={defaultCalcBasisPts}
                                                    onChange={(e) => setDefaultCalcBasisPts(e.target.value)}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        classes: {
                                                            adornedEnd: classes.adournedEnd,
                                                            input: classes.input
                                                        },
                                                    }}
                                                />
                                            </>
                                            :
                                            <Typography variant="h2" className={classes.cardDescWeight500}>
                                                {defaultCalcBasisPts ? defaultCalcBasisPts : "N/A"}
                                            </Typography>
                                        }
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                            {!globalEdit ?
                                <>
                                    <GridItem xs={12}>
                                        <CopyToClipboard text={`<iframe src="https://tuzo.app/auth/${userData.companyName.replace(/ /g, '').toLowerCase()}/iso-calculator" 
                                        name ="embedCalc" 
                                        scrolling="No"
                                        height: "800px"
                                        width: "1000px"
                                        ></iframe>`

                                        }
                                            onCopy={() => setIFrame(true)}
                                        >
                                            <Button
                                                style={{ background: "#E2A400", width: "200px", textTransform: "none" }}
                                                className={classes.uploadButton}
                                            >
                                                <Embed style={{ marginRight: "10px", marginTop: "-5px" }} /> Embed iFrame
                                            </Button>
                                        </CopyToClipboard>
                                        <Snackbar
                                            open={iFrame}
                                            onClose={() => setIFrame(false)}
                                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                            autoHideDuration={3000}
                                            style={{ top: "80px" }}
                                        >
                                            <Alert
                                                onClose={() => setIFrame(false)}
                                                severity={"success"}
                                                className={classes.alert}
                                            >
                                                iFrame Copied
                                            </Alert>
                                        </Snackbar>
                                    </GridItem>
                                </>
                                :
                                null
                            }

                        </GridContainer>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(profileStyle)
)(PublicCalculator));