import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

// core components
import GridContainer from 'components/Grid/GridContainer';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem';
import { Button, Card, Hidden, MenuItem, Select, TextField } from '@material-ui/core';

// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/adStyle';


export function AdName(props) {
    const { classes,
        history,
        buttonText,
        setButtonText,
        link,
        setLink,
        errors
    } = props;
    return (
        <div>
            <Hidden smDown implementation="css">
                <Card className={classes.card}>
                    <GridContainer>
                        <GridItem xs={6} >
                            <div style={{ display: "flex" }}>
                                <p style={{ fontWeight: 600, marginRight: "10px", marginTop: "5px", whiteSpace: "noWrap", }}>
                                    Button Text
                                </p>
                                <TextField
                                    style={{ marginLeft: "20px" }}
                                    className={classes.textField}
                                    value={buttonText}
                                    onChange={(e) => setButtonText(e.target.value)}
                                    // variant="outlined"
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            input: classes.input
                                        },
                                    }}
                                />
                            </div>
                            {errors.buttonText ?
                                <p className={classes.errText}>Input cannot be empty</p>
                                : null
                            }
                        </GridItem>

                        <GridItem xs={6} >
                            <div style={{ display: "flex" }}>
                                <p style={{ fontWeight: 600, marginRight: "10px", marginTop: "5px", whiteSpace: "noWrap", }}>
                                    Link
                                </p>
                                <TextField
                                    style={{ marginLeft: "20px" }}
                                    className={classes.textField}
                                    value={link}
                                    onChange={(e) => setLink(e.target.value)}
                                    // variant="outlined"
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            input: classes.input
                                        },
                                    }}
                                />
                            </div>
                            {errors.link ?
                                <p className={classes.errText}>Input cannot be empty</p>
                                : null
                            }
                        </GridItem>
                    </GridContainer>
                </Card>
            </Hidden>
        </div>
    );
}
function mapStateToProps(state) {
    return {
        // nothing yet
    };
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}
export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(AdName));