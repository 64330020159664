import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import ItemInfo from './components/ItemInfo';
import Summary from './components/Summary';
import ContactInfo from './components/ContactInfo';
import PaymentInfo from './components/PaymentInfo';
import RedeemPoints from './components/RedeemPoints';
import { db, firebase } from 'firebase/client';
import moment from "moment"
// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/cartStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import tuzoLogo from "assets/img/tuzo/Tuzo-square.png";

// core components
import { Dialog, Hidden, Typography } from "@material-ui/core";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { card } from 'assets/jss/material-dashboard-pro-react';
import { removeAll } from 'store/actions/cart';
import { Close } from '@material-ui/icons';
import ConfirmModal from './components/ConfirmModal';
import DialogLoader from 'components/Loader/DialogLoader';
import { sendMerchantOrderEmail } from 'firebase/client';
import { sendAdminOrderEmail } from 'firebase/client';



const paymentArr = [
    "Use Default Payment Method"
]

export function CheckoutPage(props) {
    const {
        classes,
        history,
        cart,
        originalAmount,
        user,
        removeAll
        } = props;

    const [paymentType, setPaymentType] = useState("Use Default Payment Method")
    const [agree, setAgree] = useState(false)
    const [option, setOption] = useState("Use")
    const [redeemPoints, setRedeemPoints] = useState(0)
    const [totalAmount, setTotalAmount] = useState(originalAmount ? originalAmount : 0)
    const [addPayment, setAddPayment] = useState(false)
    const [addPaymentMobile, setAddPaymentMobile] = useState(false)
    const [saveCard, setSaveCard] = useState(false)
    const [cardNumber, setCardNumber] = useState("")
    const [name, setName] = useState("")
    const [expDate, setExpDate] = useState("")
    const [billingAddress, setBillingAddress] = useState("")
    const [securityCode, setSecurityCode] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [zipcode, setZipcode] = useState("")
    const [confirmModal, setConfirmModal] = useState(false)
    const [orderNumber, setOrderNumber] = useState("")
    const [showModal, setShowModal] = useState(false)

    const numberFormatterDecimals = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    const numberFormatter = new Intl.NumberFormat('en-US', {
    });

    const updatePts = () => {
        const ptsToDollar = redeemPoints / 100
        const newTotal = totalAmount - ptsToDollar
        setTotalAmount(newTotal)
    }

    const initialUpdate = (pts) => {
        const ptsToDollar = pts / 100
        const newTotal = totalAmount - ptsToDollar
        setTotalAmount(newTotal)
    }

    const completeOrder = () => {
        setShowModal(true)
        const docId = db.collection('users').doc(user.uid).collection('orders').doc().id
        setOrderNumber(docId)
        let newData = [...cart]
        let emailData = JSON.parse(JSON.stringify(cart));
        for (let i = 0; i < newData.length; i++) {
            newData[i].orderDate = firebase.firestore.Timestamp.fromDate(new Date())
            newData[i].orderId = docId
            newData[i].orderStatus = "Pending"
            newData[i].firstName = user.firstName
            newData[i].lastName = user.lastName
            newData[i].uid = user.uid
        }
        for (let i = 0; i < emailData.length; i++) {
            emailData[i].orderDate = firebase.firestore.Timestamp.fromDate(new Date())
            emailData[i].orderId = docId
            emailData[i].orderStatus = "Pending"
            emailData[i].firstName = user.firstName
            emailData[i].lastName = user.lastName
            emailData[i].uid = user.uid
            emailData[i].salePricePoints = numberFormatter.format(emailData[i].salePricePoints)
        }
        let obj = {
            orderData: newData,
            paymentType: "Points",
            orderId: docId,
            orderDate: firebase.firestore.Timestamp.fromDate(new Date())
        }
        let merchantObj = {
            orderData: emailData,
            paymentType: "Points",
            orderId: docId,
            orderDate: moment.unix((firebase.firestore.Timestamp.fromDate(new Date())).seconds).format("MM/DD/YYYY"),
            totalPts:  numberFormatter.format(originalAmount * 100),
            shippingAddress: user.shippingAddress,
            headerImg: user.branding !== "Default" && user.logo? user.logo : "https://firebasestorage.googleapis.com/v0/b/tuzo-379415.appspot.com/o/EmailImg%2FEmailHeader.png?alt=media&token=f03aab60-d827-408c-9679-01688999f5dd",
            footerImg: user.branding === "Priority" && user.logo? user.logo : "https://firebasestorage.googleapis.com/v0/b/tuzo-379415.appspot.com/o/EmailImg%2FtuzoHorizontal.png?alt=media&token=f161b013-c40c-49f2-b6d7-468beae5a488",
            domain: window.location.host === "www.wpirewards.com" || user.companyName === "Wholesale Payments" || user.IsoAdminCompanyName === "Wholesale Payments" ? "notifications@wpirewards.com" : "notifications@tuzorewards.com",
            emailName: window.location.host === "www.wpirewards.com" || user.companyName === "Wholesale Payments" || user.IsoAdminCompanyName === "Wholesale Payments" ? "WPI" : "Tuzo",
            supportEmail: window.location.host === "www.wpirewards.com" || user.companyName === "Wholesale Payments" || user.IsoAdminCompanyName === "Wholesale Payments" ? "info@wpirewards.com" : "orders@tuzorewards.com",
            email: user.email,
            emailTitle: window.location.host === "www.wpirewards.com" || user.companyName === "Wholesale Payments" || user.IsoAdminCompanyName === "Wholesale Payments" ? "WPI Rewards" : "Tuzo Rewards",

        }
        let adminObj = {
            orderData: emailData,
            paymentType: "Points",
            orderId: docId,
            orderDate: moment.unix((firebase.firestore.Timestamp.fromDate(new Date())).seconds).format("MM/DD/YYYY"),
            merchantName: user.companyName,
            merchantId: user.merchantId,
            totalPts:  numberFormatter.format(originalAmount * 100),
            emailTitle: "Tuzo Rewards",
            shippingAddress: user.shippingAddress,
            merchantEmail: user.email,
            merchantPhoneNumber: user.cellPhone? user.cellPhone : "N/A",
            merchantFullName: user.firstName + " " + user.lastName,
            domain: "notifications@tuzorewards.com"
        }
        if (option === "Use") {
            var newAprrovedPts = (Number(user.approvedPts) - Number(redeemPoints))
        }
        db.collection('orders').doc(docId).set(obj)
        db.collection('users').doc(user.uid).collection('orders').doc(docId).set(obj)
            .then( async () => {
                await db.collection('users').doc(user.uid).update({
                    approvedPts: newAprrovedPts
                })
            })
            .then( async () => {
                await sendMerchantOrderEmail(merchantObj)
                await sendAdminOrderEmail(adminObj)
            })
            .then(() => {
                setShowModal(false)
                setConfirmModal(true)
                // removeAll(cart)
            })
            // .then(() => {
            //     history.push('/merchant/orders')
            //     window.location.reload()
            // })
    }

    useEffect(() => {
        let calcPts = 0
        let initialPts = 0
        if (user.approvedPts) {
            calcPts = user.approvedPts
        }
        if ((calcPts) > (totalAmount * 100)) {
            setRedeemPoints(totalAmount * 100)
            initialPts = totalAmount * 100
        }
        else {
            setRedeemPoints(Number(calcPts))
            initialPts = Number(calcPts)
        }
        initialUpdate(initialPts)
    }, [])

    useEffect(() => {
        if (option === "Keep") {
            setRedeemPoints(0)
            setTotalAmount(originalAmount)
        }
    }, [option])

    return (
        <div>
            <Hidden mdUp implementation='css'>
                <GridContainer style={{ marginTop: "60px" }}>
                    <GridItem xs={12} style={{ display: "flex", justifyContent: "center" }}>
                        <Typography variant="h2" className={classes.cartTitle}>
                            Payment and Review
                        </Typography >
                    </GridItem>

                    <GridItem xs={12}>
                        <ItemInfo
                            cart={cart}
                            totalAmount={totalAmount}
                            numberFormatterDecimals={numberFormatterDecimals}
                            history={history}
                        />
                        {/* <PaymentInfo
                            addPayment={addPayment}
                            setAddPayment={setAddPayment}
                            addPaymentMobile={addPaymentMobile}
                            setAddPaymentMobile={setAddPaymentMobile}
                            paymentType={paymentType}
                            setPaymentType={setPaymentType}
                            paymentArr={paymentArr}
                            history={history}
                            saveCard={saveCard}
                            setSaveCard={setSaveCard}
                            cardNumber={cardNumber}
                            setCardNumber={setCardNumber}
                            name={name}
                            setName={setName}
                            expDate={expDate}
                            setExpDate={setExpDate}
                            billingAddress={billingAddress}
                            setBillingAddress={setBillingAddress}
                            securityCode={securityCode}
                            setSecurityCode={setSecurityCode}
                            phoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                            zipcode={zipcode}
                            setZipcode={setZipcode}
                        /> */}
                        <ContactInfo />
                    </GridItem>

                    <GridItem xs={12}>
                        <Summary
                            originalAmount={originalAmount}
                            redeemPoints={redeemPoints}
                            agree={agree}
                            setAgree={setAgree}
                            cart={cart}
                            totalAmount={totalAmount}
                            numberFormatterDecimals={numberFormatterDecimals}
                            numberFormatter={numberFormatter}
                            completeOrder={completeOrder}
                        />

                        {/* <RedeemPoints
                            updatePts={updatePts}
                            redeemPoints={redeemPoints}
                            setRedeemPoints={setRedeemPoints}
                            option={option}
                            setOption={setOption}
                            totalAmount={totalAmount}
                            numberFormatter={numberFormatter}
                            numberFormatterDecimals={numberFormatterDecimals}
                            originalAmount={originalAmount}
                        /> */}
                    </GridItem>
                </GridContainer>
            </Hidden>

            <Hidden smDown implementation='css'>
                <GridContainer>
                    <GridItem xs={12} style={{ display: "flex", justifyContent: "center" }}>
                        <Typography variant="h2" className={classes.cartTitle}>
                            Payment and Review
                        </Typography >
                    </GridItem>

                    <GridItem xs={8}>
                        <ItemInfo
                            cart={cart}
                            totalAmount={totalAmount}
                            numberFormatterDecimals={numberFormatterDecimals}
                            history={history}
                        />
                        {/* <PaymentInfo
                            addPayment={addPayment}
                            setAddPayment={setAddPayment}
                            paymentType={paymentType}
                            setPaymentType={setPaymentType}
                            paymentArr={paymentArr}
                            history={history}
                            saveCard={saveCard}
                            setSaveCard={setSaveCard}
                            cardNumber={cardNumber}
                            setCardNumber={setCardNumber}
                            name={name}
                            setName={setName}
                            expDate={expDate}
                            setExpDate={setExpDate}
                            billingAddress={billingAddress}
                            setBillingAddress={setBillingAddress}
                            securityCode={securityCode}
                            setSecurityCode={setSecurityCode}
                            phoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                            zipcode={zipcode}
                            setZipcode={setZipcode}
                        /> */}
                        <ContactInfo />
                    </GridItem>

                    <GridItem xs={4}>
                        <Summary
                            originalAmount={originalAmount}
                            redeemPoints={redeemPoints}
                            agree={agree}
                            setAgree={setAgree}
                            cart={cart}
                            totalAmount={totalAmount}
                            numberFormatterDecimals={numberFormatterDecimals}
                            numberFormatter={numberFormatter}
                            completeOrder={completeOrder}
                        />

                        {/* <RedeemPoints
                            updatePts={updatePts}
                            redeemPoints={redeemPoints}
                            setRedeemPoints={setRedeemPoints}
                            option={option}
                            setOption={setOption}
                            totalAmount={totalAmount}
                            numberFormatter={numberFormatter}
                            numberFormatterDecimals={numberFormatterDecimals}
                            originalAmount={originalAmount}
                        /> */}
                    </GridItem>
                </GridContainer>
            </Hidden>

            <DialogLoader showModal={showModal} />

            <Dialog
                className={classes.requestModal}
                open={confirmModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <ConfirmModal
                    classes={classes}
                    history={history}
                    removeAll={removeAll}
                    cart={cart}
                    numberFormatter={numberFormatter}
                    originalAmount={originalAmount}
                    setConfirmModal={setConfirmModal}
                    orderNumber={orderNumber}
                />
            </Dialog>
        </div>
    )
}

function mapStateToProps(state, location) {
    return {
        cart: state.cart.cart,
        originalAmount: location.history.location.state?.totalAmount,
        user: state.auth.user
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
        removeAll: (arr) => dispatch(removeAll(arr))

    });
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(CheckoutPage);