import {
  containerFluid,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
} from 'assets/jss/material-dashboard-pro-react.js';

const headerStyle = (theme) => ({
  appBar: {
    backgroundColor: "white",
    boxShadow: "none",
    marginBottom: "0",
    position: "fixed",
    width: "100%",
    // paddingTop: "10px",
    zIndex: "1029",
    color: "#323232",
    border: "0",
    borderRadius: "3px",
    // padding: "10px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block",
    borderBottom: "1px solid #8E8E8E",
  },
  appBarMobile: {
    backgroundColor: "white",
    boxShadow: "none",
    marginBottom: "0",
    position: "fixed",
    width: "100%",
    // paddingTop: "10px",
    zIndex: "1029",
    color: "#323232",
    border: "0",
    borderRadius: "3px",
    // padding: "10px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block",
    borderBottom: "1px solid #8E8E8E",
  },
  container: {
    minHeight: "50px",
    paddingLeft: "15px",
    paddingRight: "0px !important",
    [theme.breakpoints.down("sm")]: {
      height: "60px !important",
    },
  },
  flex: {
    flex: 1,
  },
  title: {
    ...defaultFont,
    lineHeight: '30px',
    fontSize: '18px',
    borderRadius: '3px',
    textTransform: 'none',
    color: 'inherit',
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    margin: '0 !important',
    letterSpacing: 'unset',
    '&:hover,&:focus': {
      background: 'transparent',
    },
  },
  sixtyPixels: {
    height: "56px !important",
    // width: "100% !important",
    margin: "0px",
  },
  sixtyPixelsMobile: {
    height: "56px !important",
    // width: "100% !important",
    // margin: "0px",
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  sidebarMinimize: {
    float: 'left',
    padding: '0 0 0 15px',
    display: 'block',
    color: grayColor[6],
  },
  sidebarMinimizeRTL: {
    padding: '0 15px 0 0 !important',
  },
  sidebarMiniIcon: {
    width: '32px',
    height: '24px',
    color: 'white',
  },
  img: {
    height: '10vh',
    margin: '0 0 0 60px',
  },
  brandText: {
    width: '100%',
    fontSize: '22px',
    color: 'white',
    marginLeft: '40px',
    display: 'flex',
    flex: '1',
    [theme.breakpoints.down('sm')]: {
      width: '79%',
      textAlign: 'center',
      marginLeft: '0px',
      display: "none"
    },
  },
  gradientUnderline: {
    display: 'flex',
    marginLeft: '2%',
    marginRight: '2%',
    // background: "linear-gradient(90deg, #EEBD09 0%, #56A3A6 100%)",
    borderRadius: '4px',
    // boxSizing: "content-box",
    borderWidth: '0px 0px 2px 0px',
    borderStyle: 'solid',
    borderImage: 'linear-gradient(90deg, #EEBD09 0%, #56A3A6 100%)',
    borderImageSlice: 1,
    border: '0px 0px 1px 0px',
  },
  appResponsive: {
    color: "white",
    fontSize: "30px"
  },
  settingBtn: {
    // width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up('md')]: {
      display: "none"
    }
  },
  routesGridItem: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "16px",
    color: "#1A405F",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
    borderBottom: "5px solid transparent",
    justifyContent: "center"
  },
  routesGridItemUnderline: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    padding: "0px !important",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
    borderBottom: "5px solid transparent",
    color: "#56CA93"
  },
  routesGridItemUnderlineMobile: {
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    // padding: "0px !important",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
    borderBottom: "5px solid transparent",
    color: "#56CA93"
  },
  cartCount: {
    fontFamily: "Josefin Sans",
    fontSize: "14px",
    background: "#E2A400",
    marginLeft: "-40px",
    marginTop:"-23px",
    padding: "5px",
    borderRadius: "50%",
    color: "white",
    fontWeight: 600,
    height: "22px",
    width: "22px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cartCountMobile: {
    fontFamily: "Josefin Sans",
    fontSize: "14px",
    background: "#E2A400",
    marginLeft: "-18px",
    marginTop:"-23px",
    padding: "5px",
    borderRadius: "50%",
    color: "white",
    fontWeight: 600,
    height: "22px",
    width: "22px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  notificationsCount: {
    fontFamily: "Josefin Sans",
    fontSize: "14px",
    background: "#56CA93",
    marginLeft: "15px",
    padding: "5px",
    borderRadius: "50%",
    color: "white",
    fontWeight: 600,
    height: "22px",
    width: "22px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  notificationsCountZero: {
    fontFamily: "Josefin Sans",
    fontSize: "14px",
    background: "#1A405F",
    marginLeft: "15px",
    padding: "5px",
    borderRadius: "50%",
    color: "white",
    fontWeight: 600,
    height: "22px",
    width: "22px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  notificationsCountMobile: {
    fontFamily: "Josefin Sans",
    fontSize: "14px",
    background: "#56CA93",
    marginLeft: "-18px",
    marginTop:"-23px",
    padding: "5px",
    borderRadius: "50%",
    color: "white",
    fontWeight: 600,
    height: "22px",
    width: "22px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  gridItem: {
    fontFamily: "Josefin Sans",
    display: "flex",
    alignItems: "center",
  },
  gridItemHover: {
    fontFamily: "Josefin Sans",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer"
    }
  },
  gridItemSpaceBetween: {
    fontFamily: "Josefin Sans",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  gridItemNoPadding: {
    fontFamily: "Josefin Sans",
    display: "flex",
    alignItems: "center",
    padding: "0px !important"
  },
  routesGridContainer: {
    fontFamily: "Josefin Sans",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    padding: "0px 40px 0px 0px"
  },
  routesGridContainerMobile: {
    fontFamily: "Josefin Sans",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    // padding: "0px 40px 0px 0px"
  },
  hiddenSmall: {
    width: "100%",
    height: "56px !important",
  },
  initialsDiv: {
    fontFamily: "Josefin Sans",
    background: "#1A405F",
    borderRadius: "14px",
    fontWeight: 400,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    padding: "9px 10px",
    color: "#56CA93"
  },
  nameDiv: {
    marginLeft: "15px"
  },
  name: {
    fontFamily: "Josefin Sans",
    fontWeight: 400,
    fontSize: "15px",
    color: "#FFFFFF",
    lineHeight: "15px",
    whiteSpace: "nowrap"
  },
  iconsGridContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    margin: "0px"
  },
  iconsGridContainerMobile: {
    display: "flex",
    width: "100%",
    height: "100%",
    // margin: "0px"
  },
  iconGridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: "10px",
    boxShadow: "-4px 0px 4px rgba(0, 0, 0, 0.18)",
    [theme.breakpoints.up('1444')]: {
      paddingRight: "5px",
    }
  },
  iconGridItemMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px !important",
  },
  iconGridItemTrue: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px !important",
    boxShadow: "-4px 0px 4px rgba(0, 0, 0, 0.18)",
    background: "#56CA93"
  },
  iconGridItemTrueMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px !important",
    borderBottom: "5px solid #56CA93",
  },
  logoImg: {
    height:"25px"
  }
});

export default headerStyle;
