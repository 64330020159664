const cartStyle = (theme) => ({
    cartTitle: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "30px",
        color: "#1A405F"
    },
    bannerTitle: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "20px",
        // textTransform: "capitalize",
        color: "#FFFFFF",
        lineHeight: "29px",
        color: "#1A405F"
    },
    totalCostPts: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "19px",
        color: "#1A405F",
        [theme.breakpoints.down('1300')]: {
            fontSize: "16px"
        },
    },
    totalCost: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "19px",
        lineHeight: "19px",
        color: "#1A405F",
        [theme.breakpoints.down('1300')]: {
            fontSize: "18px"
        },
    },
    totalCostMarginTop: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "19px",
        lineHeight: "19px",
        color: "#1A405F",
        marginTop: "10px"
    },
    card: {
        padding: "20px",
        border: "0.5px solid #56CA93",
        borderRadius: "7px",
        minHeight: "90px",
    },
    deselectButton: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "16px",
        backgroundColor: "#56CA93",
        "&:hover": {
            backgroundColor: "#56CA93",
        },
        color: "white",
        textTransform: "none",
        borderRadius: "7px",
        padding: "10px 8px",
        [theme.breakpoints.down('1000')]: {
            width: "100%",
            marginTop: "10px"
        }
    },
    checkboxes: {
        fontFamily: "Josefin Sans",
        color: "#56CA93",
    },
    mainImg: {
        width: "100px",
        height: "90px",
        objectFit: "contain"
    },
    cardSubtitle: {
        fontFamily: "Josefin Sans",
        color: "#1A405F",
        fontSize: "16px",
        lineHeight: "24px",
        textDecoration: "underline",
        [theme.breakpoints.down('1000')]: {
            marginTop: "10px"
        },
        "&:hover": {
            cursor: "pointer"
        }
    },
    cardDescription: {
        fontFamily: "Josefin Sans",
        color: "#1A405F",
        fontSize: "16px",
        lineHeight: "16px",
        marginTop: "15px"
    },
    cardDescriptionCheckout: {
        fontFamily: "Josefin Sans",
        color: "#1A405F",
        fontSize: "16px",
        lineHeight: "24px",
        marginTop: "15px",
        padding: "1% 0%"
    },
    cardPolicy: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        color: "#1A405F",
        fontSize: "16px",
        lineHeight: "16px",
        marginTop: "15px",
        textDecoration: "underline",
        "&:hover": {
            cursor: "pointer"
        }
    },
    itemSummary: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        fontSize: "15px",
        lineHeight: "24px",
        color: "#1A405F",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    itemPriceSummary: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "20px",
        color: "#1A405F",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    borderDiv: {
        paddingTop: "20px",
        paddingBottom: "10px",
        borderBottom: "1px solid #1A405F"
    },
    tripTotal: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "15px",
        lineHeight: "24px",
        color: "#1A405F",
    },
    remainingPts: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "24px",
        color: "#1A405F",
    },
    checkoutButton: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "16px",
        backgroundColor: "#1A405F",
        "&:hover": {
            backgroundColor: "#1A405F",
        },
        "&:disabled": {
            backgroundColor: "gray",
            color:"rgba(0,0,0, 0.2)",
            opacity: "75%"
        },
        color: "white",
        textTransform: "none",
        borderRadius: "7px",
        padding: "10px 8px",
        width: "100%",
        marginTop: "20px",
        height: "42px"
    },
    itemBorderDiv: {
        padding: "30px 0px",
        borderBottom: "1px solid #56CA93"
    },
    itemBorderTopDiv: {
        marginTop: "10px",
        paddingTop: "10px",
        borderTop: "1px solid #56CA93",
        marginBottom: "-20px"
    },
    selectMenuSort: {
        fontFamily: "Josefin Sans",
        color: "#1A405F",
        background: "#FFFFFF",
        border: "1px solid #56CA93",
        borderRadius: "7px",
        width: "34%",
        height: "35px",
        textAlign: "center",
        fontWeight: "400",
        fontSize: "16px",
        margin: "8px 0px 0px 15px",
        [theme.breakpoints.down('1000')]: {
            margin: "8px 0px 0px 0px",
            paddingLeft: "10px"
        }
    },
    selectMenuItem: {
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        color: "#1A405F",
        fontSize: "16px",
        paddingLeft: "10px"
    },
    editButton: {
        width: "100px",
        borderRadius: "7px",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "16px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        background: "#FECD2C",
        height: "30px",
        "&:hover, &:focus": {
            backgroundColor: "#FECD2C",
        },
        marginLeft: "15px",
        marginTop: "8px",
        padding: "15px 8px",
        [theme.breakpoints.down('1000')]: {
            width: "100%",
            marginLeft: "0px",
            marginTop: "15px"
        }
    },
    cardDescBilling: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "16px",
        color: "#1A405F",
        marginTop: "20px",
        whiteSpace: "noWrap"
    },
    cardDescLessWeight: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "16px",
        color: "#1A405F",
        marginTop: "20px",
    },
    backButton: {
        width: "160px",
        borderRadius: "7px",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "19px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        background: "#56CA93",
        height: "42px",
        "&:hover, &:focus": {
            backgroundColor: "#56CA93",
        },
        marginLeft: "15px",
        padding: "15px 8px",
        marginTop: "-12px",
        marginRight: "5%",
        [theme.breakpoints.down('1000')]: {
            marginLeft: "0px",
            marginBottom: "15px",
            marginTop: "0px"
        }
    },
    quantityDescription: {
        fontFamily: "Josefin Sans",
        color: "#1A405F",
        fontSize: "14px",
        lineHeight: "25px",
        marginTop: "15px"
    },
    ptsTextField: {
        width: "30%",
        background: "white",
        height: "30px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        border: "1px solid #1A405F",
        paddingLeft: "5px",
        borderRadius: "7px",
        marginTop: "-7px",
        [theme.breakpoints.down('1300')]: {
            width: "28%",
        },
        [theme.breakpoints.down('1000')]: {
            width: "50%",
        },
    },
    input: {
        color: "#1A405F",
        fontWeight: 400,
        fontFamily: "Josefin Sans",
        fontSize: "16px",
    },
    usePointsText: {
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1A405F",
    },
    updateButton: {
        width: "80px",
        borderRadius: "7px",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "16px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        background: "#E2A400",
        height: "30px",
        "&:hover, &:focus": {
            backgroundColor: "#E2A400",
        },
        marginTop: "-7px",
        padding: "15px 8px",
        [theme.breakpoints.down('1000')]: {
            marginLeft: "8px"
        }
    },
    modalCard: {
        padding: "15px",
        borderRadius: "7px",
        margin: "0px",
        width: "1100px",
        height: "340px",
        overflow: "hidden",
        [theme.breakpoints.down('1000')]: {
            height: "100%",
            width: "100%"
        }
    },
    saveButton: {
        width: "120px",
        borderRadius: "7px",
        fontFamily: "Josefin Sans",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "16px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        background: "#1A405F",
        height: "42px",
        "&:hover, &:focus": {
            backgroundColor: "#1A405F",
        },
        marginLeft: "15px",
        padding: "15px 8px",
        [theme.breakpoints.down('1000')]: {
            marginLeft: 0,
            marginTop: "10px",
            width: "100%"
        }
    },
    modalGridItem: {
        marginTop: "30px"
    },
    modalSubtitle: {
        fontFamily: "Josefin Sans",
        color: "#1A405F",
        fontSize: "16px",
        lineHeight: "16px",
        fontWeight: 600
    },
    modalTextField: {
        width: "100%",
        background: "white",
        height: "30px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        border: "1px solid #1A405F",
        paddingLeft: "5px",
        borderRadius: "7px",
        marginTop: "-7px",
    },
    adournedEnd: {
        paddingRight: "0px !important",
        margin: "0px !important",
        maxHeight: "none !important",
        height: "30px"
    },
    inputDatetime: {
        color: "#1A405F",
        fontWeight: 400,
        fontFamily: "Josefin Sans",
        fontSize: "16px",
        marginLeft: "-10px"
        // '&::placeholder': {
        //     opacity:1,
        //     fontFamily: "Josefin Sans",
        //     fontWeight: 400,
        //     color:"#1A405F",
        //   },
    },
    datetimeButton: {
        background: "#1A405F",
        display: "block",
        margin: "0px !important",
        padding: "5px 0px !important",
        height: "30px",
        color: "white",
        width: "40px",
        fontFamily: "Josefin Sans",
        fontStyle: "normal",
        fontWeight: 500,
        "&:hover": {
            background: "#1A405F",
        },
    },
    gridItemNoPadding: {
        padding: "0px !important"
    },
    cardDescriptionFees: {
        color: "#1A405F",
        fontWeight: 400,
        fontFamily: "Josefin Sans",
        fontSize: "14px",
        lineHeight: "25px"
    },
    warningBorder: {
        border: "1px solid #B3140C",
        borderRadius: "7px",
        padding: "5px 10px"
    },
    warningText: {
        fontFamily: "Josefin Sans",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "24px",
        color: "#B3140C"
    },
    requestModal: {
        padding: "15px",
        overflow: "hidden",
        position: "absolute",
        top: "0% !important",
        left: "5% !important",
    },
    cardHeader: {
        fontFamily: "Josefin Sans",
        fontWeight: 700,
        fontSize: "19px",
        textTransform: "capitalize",
        color: "#1A405F"
    },
    confirmModalCard: {
        padding: "15px",
        boxShadow: "0px 1px 4px 0px #00000040",
        borderRadius: "7px",
        margin: "0px",
        width: "600px",
        padding: "20px 20px 30px 20px",
    },
})

export default cartStyle;