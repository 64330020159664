import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { compose } from "redux";

// core components
import { Button, Hidden, InputAdornment, TextField, Typography } from "@material-ui/core";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import Autocomplete from "@material-ui/lab/Autocomplete";

// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/assignRewardStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import { Search } from '@material-ui/icons';
import ItemInfo from './ItemInfo';
import UserInfo from './UserInfo';



export function SearchUser(props) {
    const {
        classes,
        userArr,
        userData,
        setUserData,
        history,
        numberFormatter,
        rewardData,
        redeemPoints,
        setRedeemPoints,
        option,
        setOption,
        handleOption,
        updatePts,
        updatePartialPts
    } = props;

    return (
        <div>
            <Card className={classes.card}>
                <GridContainer style={{ alignItems: "center" }}>
                    <GridItem xs={12}>
                        <Typography className={classes.cardTitle}>
                            Recipient
                        </Typography>
                    </GridItem>
                    {!userData ?
                        <>
                            <GridItem xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="country-select-demo"
                                    options={userArr.map((d) => ({
                                        label: d.firstName + " " + d.lastName + "\n" + d.uid,
                                        id: d.uid
                                    }))}
                                    classes={{
                                        option: classes.optionUser,
                                    }}
                                    onChange={(e, value) => {
                                        userArr.map((data) => {
                                            if(data.uid === value.id){
                                                setUserData(data)
                                            }
                                        })
                                    }}
                                    getOptionLabel={(option) => {
                                        return option.label;
                                    }}
                                    autoHighlight
                                    renderInput={(params) => (
                                        <TextField
                                            style={{ width: "40%" }}
                                            {...params}
                                            className={classes.searchBar}
                                            placeholder='Search User'
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "off", // disable autocomplete and autofill
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true,
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                                endAdornment: (
                                                    <InputAdornment
                                                        className={classes.adournedEnd}
                                                        position="end"
                                                    >
                                                        <Button
                                                            className={classes.searchButton}>
                                                            <Search style={{ marginRight: "5px" }} />
                                                            Search
                                                        </Button>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </GridItem>
                        </>
                        :
                        <GridItem xs={12}>
                            <UserInfo
                                data={userData}
                                history={history}
                                numberFormatter={numberFormatter}
                                rewardData={rewardData}
                                redeemPoints={redeemPoints}
                                setRedeemPoints={setRedeemPoints}
                                option={option}
                                setOption={setOption}
                                handleOption={handleOption}
                                updatePts={updatePts}
                                updatePartialPts={updatePartialPts}
                            />
                        </GridItem>
                    }
                </GridContainer>
            </Card>
        </div>
    )
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(SearchUser);
