/*eslint-disable*/
import { db } from 'firebase/client';

export const getRewards = () => {
  return (dispatch) => {
    return db
      .collection('rewards-list-test').get()
      .then((snapshot) => {
        if (snapshot.empty) {
          dispatch(setRewards([]))
        } else {
          snapshot = snapshot.docs
          var rewards = []
          snapshot.forEach((doc) => {
            rewards.push(doc.data())
          })
          dispatch(setRewards(rewards))
        }
      })
  }
}

export const setRewards = (rewards) => {
  return {
    type: "SET_REWARDS",
    rewards
  }
}