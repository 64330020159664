import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Editor } from "react-draft-wysiwyg";
import {
    ContentState,
    EditorState,
    convertFromHTML,
    convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { db, firebase, storage } from 'firebase/client.js';
import AdName from './addAdcomponents/AdName';
import Description from './addAdcomponents/Description';
import TextLink from './addAdcomponents/TextLink';
import BackgroundImage from './addAdcomponents/BackgroundImage';
import AdType from './addAdcomponents/AdType';
// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/adStyle';
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import { Button, Hidden, MenuItem, Select, Typography } from "@material-ui/core";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

// icons
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";
import RewardTable from './addAdcomponents/RewardTable';
import Card from 'components/Card/Card';
import Filter from './addAdcomponents/Filter';

const statusArr = [
    "Draft",
    "Active",
    "Schedule",
];

const ascArr = [
    "Asc",
    "Desc"
]

const sortArr = [
    "Name",
    "Cost",
    "SKU",
    "Category",
]

export function AddAd(props) {
    const {
        classes,
        history,
        rewards
    } = props;

    const [rewardsArr, setRewardsArr] = useState("")
    const [ascDropdown, setAscDropdown] = useState("Asc")
    const [searchString, setSearchString] = useState("")
    const [sortDropdown, setSortDropdown] = useState("Name")
    const [option, setOption] = useState("Ad")
    const [adLocation, setAdLocation] = useState("Travel")
    const [status, setStatus] = useState("Draft")
    const [adName, setAdName] = useState("")
    const [description, setDescription] = useState(EditorState.createEmpty());
    const [buttonText, setButtonText] = useState("")
    const [link, setLink] = useState("")
    const [backgroundImage, setBackgroundImage] = useState([])
    const itemsPerPage = 6;
    const [pageNumber, setPageNumber] = useState(0);
    const [index, setIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(itemsPerPage);
    const [errors, setErrors] = useState({
        adName: false,
        description: false,
        buttonText: false,
        link: false,
        backgroundImage: false
    })

    useEffect(() => {
        if (rewards.length > 0) {
            setRewardsArr(rewards)
        }
    }, [rewards])

    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
        setIndex(selected * itemsPerPage);
        setEndIndex(selected * itemsPerPage + itemsPerPage);
    };

    const selectReward = (data) => {
        setAdName(data.rewardName)
        setLink(`/reward-detail/${data.rewardId}`)
        setDescription(EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(data.topDescription))
        ))
        setBackgroundImage(data.mainImage ? data.mainImage : [])

    }

    const deselectReward = () => {
        setAdName("")
        setLink("")
        setDescription(EditorState.createEmpty())
        setBackgroundImage([])
    }

    const resetPageFilter = (filterArr) => {
        setRewardsArr([...filterArr])
        setIndex(0)
        setEndIndex(itemsPerPage)
        setPageNumber(0)
    }

    const handleFilter = (ascValue, sortValue) => {
        if (ascValue === "Asc") {
            if (sortValue === "Name") {
                const filtered = rewards.sort(function (a, b) {
                    if (a.rewardName < b.rewardName) { return -1; }
                    if (a.rewardName > b.rewardName) { return 1; }
                    return 0;
                })
                resetPageFilter(filtered)
            }
            if (sortValue === "Cost") {
                const filtered = rewards.sort((a, b) =>
                    (a.salePricePoints).toString().localeCompare((b.salePricePoints).toString(),
                        undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    }))
                resetPageFilter(filtered)
            }
            if (sortValue === "SKU") {
                const filtered = rewards.sort((a, b) =>
                    (a.sku).toString().localeCompare((b.sku).toString(),
                        undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    }))
                resetPageFilter(filtered)
            }
            if (sortValue === "Category") {
                let filtered = []
                let copyArr = [...rewards]
                let newObj = {}
                Object.keys(copyArr).map((key) => {
                    let val = copyArr[key]["categories"];
                    Object.keys(val).map((valKey) => {
                        if (val[valKey] === true) {
                            copyArr[key]["filteredCategory"] = valKey
                            filtered.push(copyArr[key])
                        }
                    })
                });
                filtered.sort(function (a, b) {
                    if (a.filteredCategory < b.filteredCategory) { return -1; }
                    if (a.filteredCategory > b.filteredCategory) { return 1; }
                    return 0;
                })
                resetPageFilter(filtered)
            }
        }
        if (ascValue === "Desc") {
            if (sortValue === "Name") {
                const filtered = rewards.sort(function (a, b) {
                    if (a.rewardName > b.rewardName) { return -1; }
                    if (a.rewardName < b.rewardName) { return 1; }
                    return 0;
                })
                resetPageFilter(filtered)
            }
            if (sortValue === "Cost") {
                const filtered = rewards.sort((a, b) =>
                    (b.salePricePoints).toString().localeCompare((a.salePricePoints).toString(),
                        undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    }))
                resetPageFilter(filtered)
            }
            if (sortValue === "SKU") {
                const filtered = rewards.sort((a, b) =>
                    (b.sku).toString().localeCompare((a.sku).toString(),
                        undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    }))
                resetPageFilter(filtered)
            }
            if (sortValue === "Category") {
                let filtered = []
                let copyArr = [...rewards]
                Object.keys(copyArr).map((key) => {
                    let val = copyArr[key]["categories"];
                    Object.keys(val).map((valKey) => {
                        if (val[valKey] === true) {
                            copyArr[key]["filteredCategory"] = valKey
                            filtered.push(copyArr[key])
                        }
                    })
                });
                filtered.sort(function (a, b) {
                    if (a.filteredCategory > b.filteredCategory) { return -1; }
                    if (a.filteredCategory < b.filteredCategory) { return 1; }
                    return 0;
                })
                resetPageFilter(filtered)
            }
        }
    }

    const handleSearch = () => {
        if (searchString) {
            const filtered = rewards.filter((f) => {
                if (
                    f.rewardName.toLowerCase().includes(searchString.toLowerCase())
                ) {
                    return true
                }
            })
            setRewardsArr(filtered)
        }
        else {
            setRewardsArr(rewards)
        }
    }

    const saveForm = async () => {
        let imageUrl = "";

        if (!adName || !description || !buttonText || !link || backgroundImage.length == 0) {
            let newErrors = {
                adName: false,
                description: false,
                buttonText: false,
                link: false,
                backgroundImage: false
            }
            if (!adName) {
                newErrors = { ...newErrors, adName: true }
            }
            if (!description) {
                newErrors = { ...newErrors, description: true }
            }
            if (!buttonText) {
                newErrors = { ...newErrors, buttonText: true }
            }
            if (!link) {
                newErrors = { ...newErrors, link: true }
            }
            if (backgroundImage.length == 0) {
                newErrors = { ...newErrors, backgroundImage: true }
            }
            setErrors({ ...newErrors })
        }
        else {
            const docId = db.collection('ad-list').doc().id
            if (backgroundImage.length > 0) {
                const file = backgroundImage[0]
                await firebase.storage().ref().child(`ad/${adName}/img/${file.name}/`).put(file)
                    .then(async () => {
                        await firebase
                            .storage()
                            .ref()
                            .child(`ad/${adName}/img/${file.name}/`)
                            .getDownloadURL()
                            .then(async (url) => {
                                imageUrl = url
                            })
                        return imageUrl
                    })
            }
            const data = {
                option,
                adLocation,
                status,
                adName,
                description: draftToHtml(
                    convertToRaw(description.getCurrentContent())
                ),
                buttonText,
                link,
                backgroundImage: imageUrl,
                id: docId,
                timestamp: firebase.firestore.Timestamp.fromDate(new Date())
            }

            await db.collection('ad-list').doc(docId).set(data)
                .then(() => {
                    window.location.href = ("/admin/ads")
                })
        }
    }

    const previewAd = async () => {
        let imageUrl = "";
        if (!adName || !description || !buttonText || !link || backgroundImage.length == 0) {
            let newErrors = {
                adName: false,
                description: false,
                buttonText: false,
                link: false,
                backgroundImage: false
            }
            if (!adName) {
                newErrors = { ...newErrors, adName: true }
            }
            if (!description) {
                newErrors = { ...newErrors, description: true }
            }
            if (!buttonText) {
                newErrors = { ...newErrors, buttonText: true }
            }
            if (!link) {
                newErrors = { ...newErrors, link: true }
            }
            if (backgroundImage.length == 0) {
                newErrors = { ...newErrors, backgroundImage: true }
            }
            setErrors({ ...newErrors })
        }
        else {
            // let newObj = {}
            // newObj.adName = adName
            // newObj.description = draftToHtml(
            //     convertToRaw(description.getCurrentContent())
            // )
            // newObj.buttonText = buttonText
            // newObj.link = link
            // newObj.backgroundImage = backgroundImage
            const docId = db.collection('ad-list').doc().id
            if (backgroundImage.length > 0) {
                const file = backgroundImage[0]
                await firebase.storage().ref().child(`ad/${adName}/img/${file.name}/`).put(file)
                    .then(async () => {
                        await firebase
                            .storage()
                            .ref()
                            .child(`ad/${adName}/img/${file.name}/`)
                            .getDownloadURL()
                            .then(async (url) => {
                                imageUrl = url
                            })
                        return imageUrl
                    })

            }

            const data = {
                option,
                adLocation,
                status,
                adName,
                description: draftToHtml(
                    convertToRaw(description.getCurrentContent())
                ),
                buttonText,
                link,
                backgroundImage: imageUrl,
                id: docId,
                timestamp: firebase.firestore.Timestamp.fromDate(new Date())
            }

            await db.collection('ad-list').doc(docId).set(data)
                .then(() => {
                    history.push(
                        `/admin/ads/preview/${docId}`,
                        {

                            adData: data

                        }
                    )
                })

        }
    }



    return (
        <div>
            <Hidden smDown implementation='css'>
                <GridContainer>
                    <GridItem xs={2}>
                        <Typography variant="h2" className={classes.adFormTitle}>
                            Add Promotional Ad
                        </Typography>
                    </GridItem>
                    <GridItem xs={3}>
                        <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "-5px" }}>
                            <Button
                                className={classes.saveButton}
                                style={{
                                    background: "#1A405F",
                                }}
                                onClick={() => saveForm()}
                            >
                                <SaveIcon className={classes.icon} /> Save
                            </Button>
                            <Button
                                className={classes.saveButton}
                                onClick={() => {
                                    console.log('checking ad',)
                                    previewAd()
                                }}
                            >
                                <ViewIcon className={classes.icon} /> Preview
                            </Button>
                            <Button
                                className={classes.saveButton}
                                style={{
                                    background: "#56CA93",
                                }}
                                onClick={() => history.push("/admin/ads")}
                            >
                                <TrashIcon className={classes.icon} /> Delete
                            </Button>
                        </div>
                    </GridItem>

                    <GridItem xs={7} style={{ justifyContent: "flex-end", display: "flex", marginTop: "-5px" }}>
                        <div style={{ display: "block" }}>
                            <p style={{
                                fontFamily: "Josefin Sans",
                                fontWeight: 400,
                                fontSize: "16px",
                                color: "#004253",
                                marginBottom: "-5px"
                            }}
                            >
                                Status</p>
                            <Select
                                style={status === "Active" ? { color: "#56CA93" } : {}}
                                className={classes.selectStatus}
                                value={status}
                                disableUnderline
                                classes={{
                                    root: classes.selectText,
                                }}
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                {statusArr.map((o, i) => {
                                    return (
                                        <MenuItem
                                            key={i}
                                            value={o}
                                            classes={{
                                                root: classes.selectMenuItem,
                                            }}
                                        >
                                            {o}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </div>
                    </GridItem>

                    <GridItem xs={9}>
                        <AdType
                            option={option}
                            setOption={setOption}
                            adLocation={adLocation}
                            setAdLocation={setAdLocation}
                        />

                        {option === "Product" ?
                            <>
                                <Card className={classes.card} style={{paddingBottom:"30px",}}>
                                    <Filter
                                        sortArr={sortArr}
                                        ascArr={ascArr}
                                        searchString={searchString}
                                        setSearchString={setSearchString}
                                        ascDropdown={ascDropdown}
                                        setAscDropdown={setAscDropdown}
                                        sortDropdown={sortDropdown}
                                        setSortDropdown={setSortDropdown}
                                        handleSearch={handleSearch}
                                        handleFilter={handleFilter}
                                    />
                                    <RewardTable
                                        history={history}
                                        rewardsData={rewardsArr}
                                        pageNumber={pageNumber}
                                        setPageNumber={setPageNumber}
                                        index={index}
                                        setIndex={setIndex}
                                        endIndex={endIndex}
                                        setEndIndex={setEndIndex}
                                        itemsPerPage={itemsPerPage}
                                        handlePageClick={handlePageClick}
                                        selectReward={selectReward}
                                        deselectReward={deselectReward}
                                        adName={adName}
                                    />
                                </Card>
                            </>
                            :
                            null
                        }

                        <AdName
                            adName={adName}
                            setAdName={setAdName}
                            errors={errors}
                        />

                        <Description
                            description={description}
                            setDescription={setDescription}
                            errors={errors}
                        />

                        <TextLink
                            buttonText={buttonText}
                            setButtonText={setButtonText}
                            link={link}
                            setLink={setLink}
                            errors={errors}
                        />
                    </GridItem>

                    <GridItem xs={3}>
                        <BackgroundImage
                            errors={errors}
                            classes={classes}
                            backgroundImage={backgroundImage}
                            setBackgroundImage={setBackgroundImage}
                        />
                    </GridItem>
                </GridContainer>
            </Hidden>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        rewards: state.rewards.rewards
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(AddAd);