import Activity from "views/Pages/activity/Activity";
import Profile from "views/Pages/userprofile/Profile";
import Wishlist from 'views/Pages/wishlist/Wishlist';
import BatchReports from "views/Pages/reports/batch/BatchReports";
import BatchDetails from 'views/Pages/reports/batchDetails/batchDetails';
import Rewards from "views/Pages/rewards/Rewards";
import RewardsDetail from "views/Pages/rewardsdetail/RewardsDetail";
import Cart from "views/Pages/cart/Cart";
import Checkout from "views/Pages/checkout/Checkout";
import Travel from "views/Pages/travel/Travel";
import TravelDetails from "views/Pages/travelDetails/TravelDetails";
import Order from 'views/Pages/orders/Order';
import OrdersDetail from 'views/Pages/ordersdetail/OrdersDetail';

var isoMerchantRoutes = [
    {
      path: '/:iso/:merchant/activity',
      name: 'TEST',
      component: Activity,
      layout: '/iso-merchant',
    },
    {
      path: '/:iso/:merchant/profile',
      name: 'Profile',
      component: Profile,
      layout: '/iso-merchant',
    },
    {
      path: '/:iso/:merchant/reward-detail/:id',
      name: 'Rewards Detail',
      component: RewardsDetail,
      layout: '/iso-merchant',
    },
    {
      path: '/:iso/:merchant/rewards',
      name: 'Rewards',
      component: Rewards,
      layout: '/iso-merchant',
    },
    {
      path: '/:iso/:merchant/orders-detail/:id',
      name: 'Orders Detail',
      component: OrdersDetail,
      layout: '/iso-merchant',
    },
    {
      path: '/:iso/:merchant/orders',
      name: 'Orders',
      component: Order,
      layout: '/iso-merchant',
    },
    {
      path: '/:iso/:merchant/travel-detail/:id',
      name: 'Travel Detail',
      component: TravelDetails,
      layout: '/iso-merchant',
    },
    {
      path: '/:iso/:merchant/travel',
      name: 'Travel',
      component: Travel,
      layout: '/iso-merchant',
    },
    {
      path: '/:iso/:merchant/wish-list',
      name: 'Wish List',
      component: Wishlist,
      layout: '/iso-merchant'
    },
    {
      path: '/:iso/:merchant/reports/batch/:id/:id',
      name: 'Batch Details',
      component: BatchDetails,
      layout: '/iso-merchant',
    },
    {
      path: '/:iso/:merchant/reports/batch',
      name: 'Reports',
      component: BatchReports,
      layout: '/iso-merchant',
    },
    {
      path: '/:iso/:merchant/cart',
      name: 'Cart',
      component: Cart,
      layout: '/iso-merchant',
    },
    {
      path: '/:iso/:merchant/checkout',
      name: 'Checkout',
      component: Checkout,
      layout: '/iso-merchant',
    },
]

export default isoMerchantRoutes;