import React, { useEffect, useState } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Preview from "./Preview";
import { db } from "firebase/client";
import allRewards from "assets/img/tuzo/allRewards.png";
import { CopyToClipboard } from "react-copy-to-clipboard";

// styling
import Style from "assets/jss/material-dashboard-pro-react/views/calculatorStyle";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import {
  Button,
  Hidden,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowBackIos, CheckCircle } from "@material-ui/icons";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Alert from "@material-ui/lab/Alert";
// global function
import { ptsFormula } from "globalCalculationFunction/globalCalculationFunction";

// @material-ui/icons
import { ReactComponent as Edit } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as Copy } from "assets/img/tuzo/icons/CopyIcon.svg";
import Loader from "components/Loader/Loader";

export function Calculator(props) {
  const { classes, history, userLogo, authUser } = props;
  const merchantName = window.location.pathname.split("/")[2];
  const [grossAmt, setGrossAmt] = useState("");
  const [basisPts, setBasisPts] = useState("");
  const [editBasisPts, setEditBasisPts] = useState(false)
  const [data, setData] = useState(false);
  const [points, setPoints] = useState("");
  const [goNext, setGoNext] = useState(false);
  const [error, setError] = useState(false);
  const [arr, setArr] = useState(false);
  const [timesRan, setTimesRan] = useState(1);
  const [companyName, setCompanyName] = useState("")
  const [dataLoaded, setDataLoaded] = useState(false)
  let count = 1;
  let oldPts = 0;

  const formatInput = (value) => {
    let newValue = value
      .replace(/[^0-9\.]/g, "")
      .replace(/\./g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setGrossAmt(newValue);
  };

  // const formatBasis = (value) => {
  //   let newValue = value
  //     .replace(/[^0-9\.]/g, "")
  //     .replace(/\./g, "")
  //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   setBasisPts(newValue);
  // };

  const queryPts = (pts) => {
    const items = [];
    db.collection("rewards-list-test")
      .orderBy("salePricePoints", "desc")
      .where("salePricePoints", "<=", pts)
      .onSnapshot((querySnapshot) => {
        if (count == 1 && querySnapshot.docs.length < 4) {
          count = count + 1;
          setTimesRan((prev) => prev + 1);
          queryPts(pts * 12);
        } else if (count > 1 && querySnapshot.docs.length < 4) {
          count = count + 1;
          setTimesRan((prev) => prev + 1);
          queryPts(pts * 3);
        } else {
          querySnapshot.forEach((doc) => {
            items.push(doc.data());
          });
          setArr(items);
        }
      });
  };

  const ptsCalculator = () => {
    let replacedgrossAmt = grossAmt.replace(/,/g, "");
    if (!grossAmt) {
      setError(true);
    } else {
      // let basisPtsFunction = data && data.defaultCalcBasisPts? data.defaultCalcBasisPts : 20;
      const floorDollarPerBassisPts = ptsFormula(
        replacedgrossAmt,
        basisPts
      );

      oldPts = floorDollarPerBassisPts;
      setPoints(floorDollarPerBassisPts);
      queryPts(oldPts);
      setError(false);
      setGoNext(true);
    }
  };

  useEffect(() => {
    if (!data) {
      const getData = () => {
        db.collection("users")
          .where("userType", "==", "IsoAdmin")
          .get()
          .then((snapshots) => {
            if (!snapshots.empty) {
              snapshots.forEach((doc) => {
                doc = doc.data();
                if(doc.companyName.replace(/ /g, '').toLowerCase() === merchantName){
                  setCompanyName(doc.companyName)
                  setBasisPts(doc.defaultCalcBasisPts? doc.defaultCalcBasisPts : 20)
                  setData(doc);
                }
              })
            } else {
              setData({});
            }
          })
          .then(() => {
            setDataLoaded(true)
          })
      };
      getData();
    }
  });

  if (!dataLoaded) {
    return <Loader />;
  }

  if (goNext) {
    return (
      <Preview
        grossAmt={grossAmt}
        points={points}
        arr={arr}
        timesRan={timesRan}
      />
    );
  }
  return (
    <div>
      <Hidden mdUp implementation="css">
        <div className={classes.calculatorCardIsoAdminMobile}>
          <GridContainer style={{ paddingRight: "5%", paddingLeft: "5%" }}>
              <GridItem xs={12}>
                <div>
                    {companyName?
                      <Typography className={classes.calculatorTitleMobile}>
                        {companyName} Rewards Calculator
                      </Typography>
                  :
                    <Typography className={classes.calculatorTitleMobile}>
                        The Tuzo Rewards Calculator
                    </Typography>
                  }

                    {data.logo ? (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <img
                            src={data.logo}
                            style={{
                              width: "450px",
                              paddingRight: "5%", 
                              paddingLeft: "5%",  
                              maxHeight: "450px",
                            }}
                          />
                        </div>
                      ) : null}
                    

                    <Typography
                      className={classes.calculatorIsoAdminSubtitleMobile}
                      style={{ paddingRight: "5%", paddingLeft: "5%" }}
                    >
                      Calculate how many rewards points you can earn!
                    </Typography>
                </div>
              </GridItem>

              <GridItem xs={12}>
                <Typography
                      className={classes.cardHeaderTitleMobile}
                      style={{ paddingRight: "5%", paddingLeft: "5%" }}
                    >
                      Enter your store’s gross monthly processing amount ($){" "}
                      <span style={{ color: "#E53935" }}>*</span>
                      {error ? (
                        <span style={{ color: "red" }}>Required</span>
                      ) : null}
                    </Typography>
                    <TextField
                      className={classes.textFieldMobile}
                      value={grossAmt}
                      onChange={(e) => formatInput(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          ptsCalculator();
                        }
                      }}
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          input: classes.input,
                        },
                      }}
                    />
              </GridItem>
              {editBasisPts?
                    <>
                      <GridItem xs={12}>
                        <Typography className={classes.cardHeaderTitleMobile} style={{marginTop:"10px"}}>
                            Enter your basis points amount ($){" "}
                            <span style={{ color: "#E53935" }}>*</span>
                            {error ? <span style={{ color: "red" }}>Required</span> : null}
                        </Typography>
                          <TextField
                            type="number"
                            className={classes.textFieldMobile}
                            value={basisPts}
                            onChange={(e) => setBasisPts(e.target.value)}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                  input: classes.input
                              },
                              inputProps: {
                                  min: 0,
                                  max: 50
                              }
                          }}
                        />
                        </GridItem>
                    </>  
                    :
                    null
                  }

              <GridItem xs={12} className={classes.gridPadding}>
                  {/* <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  > */}
                    <Link
                      to={{
                        pathname: "/admin/user-page",
                        state: { test: true}
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button className={classes.goBackButtonMobile} style={{ width:"100%" }}>
                        <ArrowBackIos
                          style={{ marginRight: "10px", marginTop: "-3px" }}
                        />{" "}
                        Go to App
                      </Button>
                    </Link>

                    <Button
                      style={{ background:"#FECD2C", width:"100%"  }}
                      className={classes.submitButtonMobile}
                      onClick={() => {
                        setEditBasisPts(true);
                      }}
                    >
                      <Edit
                        style={{ marginRight: "10px", marginTop: "-5px" }}
                      />{" "}
                      Edit Basis Points
                    </Button>
                  {/* </div> */}
                  <Button
                      style={{ width:"100%" }}
                      className={classes.submitButtonMobile}
                      onClick={() => {
                        ptsCalculator();
                      }}
                    >
                      <CheckCircle
                        style={{ marginRight: "10px", marginTop: "-5px" }}
                      />{" "}
                      Submit
                    </Button>
                </GridItem>

                <GridItem xs={12} style={{ display: "flex", justifyContent: "center", marginTop:"5px" }}>
                    <img
                      src={allRewards}
                      style={{
                        padding: "5%",
                        width: "300px",
                        maxHeight: "450px",
                        marginRight: "20px",
                      }}
                    />
                </GridItem>
          </GridContainer>
        </div>
      </Hidden>

      <Hidden smDown implementation="css">
        <div className={classes.calculatorCardIsoAdmin}>
          <GridContainer style={{justifyContent:"center"}}>
            <GridItem xs={12}>
            {companyName?
              <Typography className={classes.calculatorTitle} style={{ paddingTop:"15px", paddingBottom:"0px" }}>
                {companyName} Rewards Calculator
              </Typography>
          :
            <Typography className={classes.calculatorTitle}>
                The Tuzo Rewards Calculator
            </Typography>
          }
          {data.logo ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={data.logo}
                  style={{
                    width: "450px",
                    padding: "1% 2% ",
                    maxHeight: "450px",
                  }}
                />
              </div>
            ) : null}

            <Typography className={classes.calculatorIsoAdminSubtitle}>
              Calculate how many rewards points you can earn!
            </Typography>
            </GridItem>

            <GridItem xs={12}>
              <div className={classes.calculatorCard}>
                <div>
                  <Typography className={classes.cardHeaderTitle}>
                    Enter your store’s gross monthly processing amount ($){" "}
                    <span style={{ color: "#E53935" }}>*</span>
                    {error ? <span style={{ color: "red" }}>Required</span> : null}
                  </Typography>
                  <TextField
                    className={classes.textField}
                    value={grossAmt}
                    onChange={(e) => formatInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        ptsCalculator();
                      }
                    }}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.input,
                      },
                    }}
                  />

                  {editBasisPts?
                    <>
                        <Typography className={classes.cardHeaderTitle} style={{marginTop:"10px"}}>
                            Enter your basis points amount ($){" "}
                            <span style={{ color: "#E53935" }}>*</span>
                            {error ? <span style={{ color: "red" }}>Required</span> : null}
                        </Typography>
                          <TextField
                            type="number"
                            className={classes.textField}
                            value={basisPts}
                            onChange={(e) => setBasisPts(e.target.value)}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                  input: classes.input
                              },
                              inputProps: {
                                  min: 0,
                                  max: 50
                              }
                          }}
                        />
                    </>  
                    :
                    null
                  }
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Link
                      to={authUser? "/admin/user-page" : `/auth/${merchantName}/login`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button 
                        className={classes.goBackButton}
                      >
                          <ArrowBackIos
                          style={{ marginRight: "10px", marginTop: "-3px" }}
                        />{" "}
                        Go to App
                      </Button>
                    </Link>

                    <Button
                      className={classes.goBackButton}
                      style={{ background:"#FECD2C", width:"200px" }}
                      onClick={() => {
                        setEditBasisPts(true);
                      }}
                    >
                      <Edit
                        style={{ marginRight: "10px", marginTop: "-5px" }}
                      />{" "}
                      Edit Basis Points
                    </Button>
                  </div>

                  <Button
                      className={classes.submitButton}
                      style={{ width:"100%" }}
                      onClick={() => {
                        ptsCalculator();
                      }}
                    >
                      <CheckCircle
                        style={{ marginRight: "10px", marginTop: "-5px" }}
                      />{" "}
                      Submit
                    </Button>
                </div>
            </div>
            </GridItem>

            <GridItem xs={12} style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={allRewards}
                    style={{
                      width: "450px",
                      padding: "5%",
                      maxHeight: "450px",
                      marginRight: "65px",
                    }}
                  />
            </GridItem>
          </GridContainer>
        </div>
      </Hidden>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    authUser: state.auth.authenticated
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
  });
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(Calculator)
);
