import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { useDropzone } from 'react-dropzone';
import Dropzone from 'react-dropzone'
// core components
import GridContainer from 'components/Grid/GridContainer';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem';
import { Button, Card, Hidden, MenuItem, Select, TextField } from '@material-ui/core';

// icons
import { BsDownload } from "react-icons/bs";


// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/rewardsStyle';


export function BackgroundImage(props) {
    const {
        classes,
        history,
        backgroundImage,
        setBackgroundImage,
        errors
    } = props;

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        maxFiles: 1
    });

    return (
        <div>
            <Hidden smDown implementation="css">
                <Card className={classes.cardBackground}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <h5 className={classes.cardHeader}>Background Image</h5>
                        </GridItem>
                        <GridItem xs={12}>
                            <div style={{ marginLeft: "20px" }}>
                                <Dropzone
                                    max={104857600}
                                    multiple={false}
                                    open
                                    noClick={backgroundImage.length === 0 ? true : false}
                                    accept=".jpeg, .png, .jpg"
                                    onDrop={acceptedFiles => {
                                        var array = []
                                        Array.from(acceptedFiles).forEach(file => {
                                            array = [...array, file]
                                        });
                                        setBackgroundImage(array)
                                    }}>
                                    {({ getRootProps, getInputProps, open }) => (
                                        <div {...getRootProps()}
                                            className={classes.imgSquare}>
                                            <input {...getInputProps()} />
                                            {backgroundImage.length === 0 ?
                                                <div style={{ textAlign: "center" }}>
                                                    <p style={{ marginTop: "10px", fontSize: "18px" }}>
                                                        <BsDownload
                                                            style={{ color: "#56CA93", fontSize: "50px", marginBottom: "-10px" }} />
                                                        {"  "}
                                                    </p>
                                                    <p>
                                                        Drop  image  here or  click “Set background image” below
                                                    </p>
                                                </div>
                                                :
                                                typeof (backgroundImage) === "string" ?
                                                    <img
                                                        // key={i}
                                                        style={{ height: "100px", width: '150px', marginTop: "20px", objectFit: "contain" }}
                                                        src={backgroundImage}
                                                    />
                                                :
                                                backgroundImage.map((f, i) => {
                                                    return (
                                                        <img
                                                            key={i}
                                                            style={{ height: "100px", width: '150px', marginTop: "20px" }}
                                                            src={URL.createObjectURL(f)}
                                                        />
                                                    )
                                                })
                                            }
                                            {backgroundImage.length === 0 ?
                                                <p
                                                    style={{ textDecoration: "underline", cursor: "pointer" }}
                                                    className={classes.imgDescription}
                                                    onClick={open}
                                                >
                                                    Set background image
                                                </p>
                                                :
                                                null
                                            }

                                        </div>
                                    )}
                                </Dropzone>
                            </div>
                        </GridItem>
                        {backgroundImage.length === 0 ?
                            <>
                                <GridItem style={{ marginTop: "15px" }} xs={12}>
                                    <p
                                        style={{ fontSize: "14px" }}
                                        className={classes.imgDescription}
                                    >
                                        For best results, upload JPEG or PNG files that are
                                        300 by 300 pixels or larger and has square dimensions.
                                    </p>

                                    <p style={{ marginTop: "15px", fontSize: "14px" }}
                                        className={classes.imgDescription}>
                                        Maximum upload file size: 100 MB.
                                    </p>

                                    {errors.link ?
                                        <p className={classes.errText}>Input cannot be empty</p>
                                        : null
                                    }
                                </GridItem>
                            </>
                            :
                            <>
                                <GridItem style={{ marginTop: "15px" }} xs={12}>
                                    <p
                                        className={classes.imgDescription}
                                    >
                                        Click the image to update
                                    </p>
                                    <p
                                        style={{
                                            textDecoration: "underline",
                                            cursor: "pointer"
                                        }}
                                        className={classes.imgDescription}
                                        onClick={() => setBackgroundImage([])}
                                    >
                                        Remove background image
                                    </p>
                                </GridItem>
                            </>
                        }
                    </GridContainer>
                </Card>
            </Hidden>
        </div>
    );
}
function mapStateToProps(state) {
    return {
        // nothing yet
    };
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}
export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(BackgroundImage));