const addProductToCart = (product) => {
  return {
    type: "ADD_PRODUCT",
    product,
  };
};

const removeProductFromCart = (index) => {
  return {
    type: "REMOVE_PRODUCT",
    index
  };
};

const removeAllFromCart = (arr) => {
  return {
    type: "REMOVE_ALL",
    arr
  };
};

export const addProduct = (product) => {
  return (dispatch) => {
    dispatch(addProductToCart(product));
  };
};

export const removeProduct = (index) => {
  return (dispatch) => {
    dispatch(removeProductFromCart(index));
  };
}  

export const removeAll = (arr) => {
  return (dispatch) => {
    dispatch(removeAllFromCart(arr))
  }
}