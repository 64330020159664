export const setGlobalArrowOpen = (boolean) => {
    return {
      type: "SET_GLOBAL_ARROW",
      boolean,
    };
  };

  export const setGlobalCalulcatorOpen = (boolean) => {
    return {
      type: "SET_GLOBAL_CALCULATOR",
      boolean,
    };
  };