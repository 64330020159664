import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

// core components
import GridContainer from 'components/Grid/GridContainer';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem';
import { Button, Card, Hidden, MenuItem, Select, TextField } from '@material-ui/core';

// icons
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";


// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/rewardsStyle';


export function Shipping(props) {
    const {
        classes,
        history,
        weight,
        setWeight,
        length,
        setLength,
        width,
        setWidth,
        height,
        setHeight
    } = props;
    return (
        <div>
            <Hidden smDown implementation="css">
                <Card className={classes.card} style={{ marginTop: "15px" }}>
                    <GridContainer>
                        <GridItem xs={12} style={{ display: "flex" }}>
                            <h5 className={classes.cardHeader}>Shipping</h5>
                        </GridItem>
                        <GridItem xs={6}>
                            <GridContainer>
                                <GridItem xs={3}>
                                    <p className={classes.generalLabel} style={{ marginTop: "5px" }}>
                                        Weight (oz)
                                    </p>
                                </GridItem>
                                <GridItem xs={9}>
                                    <TextField
                                        style={{ marginTop: "0px" }}
                                        className={classes.textField}
                                        value={weight}
                                        onChange={(e) => setWeight(e.target.value)}
                                        // variant="outlined"
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                adornedEnd: classes.adournedEnd,
                                            },
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={6}>
                            <GridContainer>
                                <GridItem xs={3}>
                                    <p className={classes.generalLabel} style={{ marginTop: "5px" }}>
                                        Dimensions (in)
                                    </p>
                                </GridItem>
                                <GridItem xs={9} style={{ display: "flex" }}>
                                    <TextField
                                        style={{ marginTop: "0px", width: "50%", marginRight: "5px" }}
                                        className={classes.textField}
                                        value={length}
                                        onChange={(e) => setLength(e.target.value)}
                                        placeholder='Length'
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                adornedEnd: classes.adournedEnd,
                                            },
                                        }}
                                    />
                                    <TextField
                                        style={{ marginTop: "0px", width: "50%", marginRight: "5px" }}
                                        className={classes.textField}
                                        value={width}
                                        onChange={(e) => setWidth(e.target.value)}
                                        placeholder='Width'
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                adornedEnd: classes.adournedEnd,
                                            },
                                        }}
                                    />
                                    <TextField
                                        style={{ marginTop: "0px", width: "50%" }}
                                        className={classes.textField}
                                        value={height}
                                        onChange={(e) => setHeight(e.target.value)}
                                        placeholder='Height'
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                adornedEnd: classes.adournedEnd,
                                            },
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </Card>
            </Hidden>
        </div>
    );
}
function mapStateToProps(state) {
    return {
        // nothing yet
    };
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}
export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Shipping));