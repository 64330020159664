import React, { useEffect, useState } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { db, firebase, storage } from "firebase/client.js";
import AdName from "./editAdComponents/AdName";
import Description from "./editAdComponents/Description";
import TextLink from "./editAdComponents/TextLink";
import BackgroundImage from "./editAdComponents/BackgroundImage";
import AdType from "./editAdComponents/AdType";

// styling
import Style from "assets/jss/material-dashboard-pro-react/views/adStyle";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import {
  Button,
  Hidden,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Loader from "components/Loader/Loader";

// icons
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";

const statusArr = ["Draft", "Active", "Schedule"];

export function EditAd(props) {
  const { classes, history } = props;

  const adId = window.location.pathname.split("/")[4];
  const [option, setOption] = useState("Ad");
  const [adLocation, setAdLocation] = useState("Travel");
  const [status, setStatus] = useState("Draft");
  const [adName, setAdName] = useState("");
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [buttonText, setButtonText] = useState("");
  const [link, setLink] = useState("");
  const [dataLoaded, setDataloaded] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState([]);
  const [errors, setErrors] = useState({
    adName: false,
    description: false,
    buttonText: false,
    link: false,
    backgroundImage: false,
  });

  const deleteAd = () => {
    db.collection("ad-list").doc(adId).delete();
  };

  const updateForm = async () => {
    let imageUrl = "";

    if (
      !adName ||
      !description ||
      !buttonText ||
      !link ||
      backgroundImage.length == 0
    ) {
      let newErrors = {
        adName: false,
        description: false,
        buttonText: false,
        link: false,
        backgroundImage: false,
      };
      if (!adName) {
        newErrors = { ...newErrors, adName: true };
      }
      if (!description) {
        newErrors = { ...newErrors, description: true };
      }
      if (!buttonText) {
        newErrors = { ...newErrors, buttonText: true };
      }
      if (!link) {
        newErrors = { ...newErrors, link: true };
      }
      if (backgroundImage.length == 0) {
        newErrors = { ...newErrors, backgroundImage: true };
      }
      setErrors({ ...newErrors });
    } else {
      if (backgroundImage.length > 0 && typeof backgroundImage !== "string") {
        const file = backgroundImage[0];
        await firebase
          .storage()
          .ref()
          .child(`ad/${adName}/img/${file.name}/`)
          .put(file)
          .then(async () => {
            await firebase
              .storage()
              .ref()
              .child(`ad/${adName}/img/${file.name}/`)
              .getDownloadURL()
              .then(async (url) => {
                imageUrl = url;
              });
            return imageUrl;
          });
      }

      const data = {
        option,
        adLocation,
        status,
        adName,
        description: draftToHtml(convertToRaw(description.getCurrentContent())),
        buttonText,
        link,
        backgroundImage: imageUrl ? imageUrl : backgroundImage,
      };

      await db
        .collection("ad-list")
        .doc(adId)
        .update(data)
        .then(() => {
          window.location.href = "/admin/dashboard";
        });
    }
  };

  const previewAd = async () => {
    let imageUrl = "";
    if (
      !adName ||
      !description ||
      !buttonText ||
      !link ||
      backgroundImage.length == 0
    ) {
      let newErrors = {
        adName: false,
        description: false,
        buttonText: false,
        link: false,
        backgroundImage: false,
      };
      if (!adName) {
        newErrors = { ...newErrors, adName: true };
      }
      if (!description) {
        newErrors = { ...newErrors, description: true };
      }
      if (!buttonText) {
        newErrors = { ...newErrors, buttonText: true };
      }
      if (!link) {
        newErrors = { ...newErrors, link: true };
      }
      if (backgroundImage.length == 0) {
        newErrors = { ...newErrors, backgroundImage: true };
      }
      setErrors({ ...newErrors });
    } else {
      if (backgroundImage.length > 0 && typeof backgroundImage !== "string") {
        const file = backgroundImage[0];
        await firebase
          .storage()
          .ref()
          .child(`ad/${adName}/img/${file.name}/`)
          .put(file)
          .then(async () => {
            await firebase
              .storage()
              .ref()
              .child(`ad/${adName}/img/${file.name}/`)
              .getDownloadURL()
              .then(async (url) => {
                imageUrl = url;
              });
            return imageUrl;
          });
      }
      const data = {
        option,
        adLocation,
        status,
        adName,
        description: draftToHtml(convertToRaw(description.getCurrentContent())),
        buttonText,
        link,
        backgroundImage: imageUrl ? imageUrl : backgroundImage,
        id: adId,
      };

      history.push(`/admin/ads/preview/${adId}`, {
        adData: data,
      });
    }
  };

  useEffect(() => {
    if (!dataLoaded) {
      const getData = () => {
        db.collection("ad-list")
          .doc(adId)
          .get()
          .then((snapshot) => {
            let data = snapshot.data();
            setAdName(data.adName);
            setOption(data.option);
            setAdLocation(data.adLocation);
            setBackgroundImage(data.backgroundImage);
            setButtonText(data.buttonText);
            setDescription(
              EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  convertFromHTML(data.description)
                )
              )
            );
            setLink(data.link);
            setStatus(data.status);
          });
        setDataloaded(true);
      };
      getData();
    }
  }, []);

  if (!dataLoaded) return <Loader />;

  return (
    <div>
      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem xs={2}>
            <Typography variant="h2" className={classes.adFormTitle}>
              Edit Promotional Ad
            </Typography>
          </GridItem>
          <GridItem xs={3}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "-5px",
              }}
            >
              <Button
                className={classes.saveButton}
                style={{
                  background: "#1A405F",
                }}
                onClick={() => updateForm()}
              >
                <SaveIcon className={classes.icon} /> Save
              </Button>
              <Button
                className={classes.saveButton}
                onClick={() => {
                  previewAd();
                }}
              >
                <ViewIcon className={classes.icon} /> Preview
              </Button>
              <Button
                className={classes.saveButton}
                style={{
                  background: "#56CA93",
                }}
                onClick={() => deleteAd()}
              >
                <TrashIcon className={classes.icon} /> Delete
              </Button>
            </div>
          </GridItem>

          <GridItem
            xs={5}
            style={{
              justifyContent: "flex-end",
              display: "flex",
              marginTop: "-5px",
            }}
          >
            <div style={{ display: "block" }}>
              <p
                style={{
                  fontFamily: "Josefin Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                  color: "#004253",
                  marginBottom: "-5px",
                }}
              >
                Status
              </p>
              <Select
                style={status === "Active" ? { color: "#56CA93" } : {}}
                className={classes.selectStatus}
                value={status}
                disableUnderline
                classes={{
                  root: classes.selectText,
                }}
                onChange={(e) => setStatus(e.target.value)}
              >
                {statusArr.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </GridItem>

          <GridItem xs={9}>
            <AdType
              option={option}
              setOption={setOption}
              adLocation={adLocation}
              setAdLocation={setAdLocation}
            />

            <AdName adName={adName} setAdName={setAdName} errors={errors} />

            <Description
              description={description}
              setDescription={setDescription}
              errors={errors}
            />

            <TextLink
              buttonText={buttonText}
              setButtonText={setButtonText}
              link={link}
              setLink={setLink}
              errors={errors}
            />
          </GridItem>

          <GridItem xs={3}>
            <BackgroundImage
              errors={errors}
              classes={classes}
              backgroundImage={backgroundImage}
              setBackgroundImage={setBackgroundImage}
            />
          </GridItem>
        </GridContainer>
      </Hidden>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({});
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(EditAd);
