import React, { useState, useEffect } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { removeFromWishlist } from "store/actions/wishlistAction";
import { db } from "firebase/client";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";
import Loader from "components/Loader/Loader";

// mui core
import { CardContent, IconButton, Typography, Hidden } from "@material-ui/core";

// stylings
import Style from "assets/jss/material-dashboard-pro-react/views/wishlistStyle";
import withStyles from "@material-ui/core/styles/withStyles";

// icons
import { ReactComponent as Info } from "assets/img/tuzo/icons/Info.svg";
import { AiFillHeart } from "react-icons/ai";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIconYellow.svg";
import NoWishlist from "./NoWishlist";

export function MerchantWishlist(props) {
  const { classes, history, userCompany } = props;

  const merchantId = window.location.pathname.split("/")[4];
  const [wishlist, setWishList] = useState(false);
  const [merchantData, setMerchantData] = useState("");

  const numberFormatterDecimals = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  useEffect(() => {
    if (!wishlist) {
      let arr = [];
      const getWishlist = () => {
        db.collection("users")
          .doc(merchantId)
          .get()
          .then((snapshot) => {
            snapshot = snapshot.data();
            setMerchantData(snapshot);
          })
          .then(() => {
            db.collection("users")
              .doc(merchantId)
              .collection("wish-list")
              .get()
              .then((snapshots) => {
                if (!snapshots.empty) {
                  snapshots.forEach((doc) => {
                    doc = doc.data();
                    arr.push(doc);
                  });
                  setWishList(arr);
                } else {
                  setWishList([]);
                }
              });
          });
      };
      getWishlist();
    }
  }, []);

  if (!wishlist) return <Loader />;

  return (
    <div>
      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography variant="h2" className={classes.wishlistTitle}>
              Merchant's Wish List
            </Typography>
          </GridItem>

          <GridItem
            xs={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "-35px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="h2" className={classes.headerDesc}>
                Merchant Name
              </Typography>
              <Typography
                variant="h2"
                className={classes.headerDesc}
                style={{
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "18px",
                  marginTop: "10px",
                }}
              >
                {merchantData.firstName + " " + merchantData.lastName}
              </Typography>
            </div>
          </GridItem>
        </GridContainer>

        <GridContainer>
          {wishlist.length > 0 ? (
            <GridItem xs={12}>
              <Card className={classes.outerCard}>
                <GridContainer>
                  {wishlist.map((data, index) => (
                    <>
                      <GridItem
                        xs={4}
                        md={4}
                        xl={3}
                        className={classes.gridSpace}
                        key={index}
                      >
                        <Card
                          className={classes.wishlistCardListStyling}
                          key={index}
                        >
                          <CardContent style={{ padding: "0px" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <IconButton
                                onClick={() =>
                                  history.push(
                                    `/iso-admin/${userCompany}/reward-detail/${data.rewardId}`,
                                    {
                                      rewardData: data,
                                    }
                                  )
                                }
                              >
                                <Info />
                              </IconButton>
                            </div>

                            <div
                              style={{
                                textAlign: "center",
                                borderBottom: "1px solid #E2A400",
                              }}
                            >
                              <img
                                className={classes.cardListImg}
                                src={data.mainImage}
                                alt="img here"
                              />

                              <Typography
                                variant="h2"
                                className={classes.cardListTitleNoOverflow}
                              >
                                {data.rewardName.length < 33 ? <br /> : null}
                                {data.rewardName}
                              </Typography>
                            </div>

                            <div className={classes.divStyle}>
                              <div>
                                <Typography
                                  variant="h2"
                                  className={classes.cardListDesc}
                                >
                                  Price
                                </Typography>
                                <Typography
                                  variant="h2"
                                  className={classes.cardListPts}
                                >
                                  $
                                  {numberFormatterDecimals.format(
                                    Number(data.salePrice)
                                  )}{" "}
                                  / {data.salePricePoints.toLocaleString()}{" "}
                                  points
                                </Typography>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </GridItem>
                    </>
                  ))}
                </GridContainer>
              </Card>
            </GridItem>
          ) : (
            <>
              <GridItem xs={12}>
                <NoWishlist history={history} />
              </GridItem>
            </>
          )}
        </GridContainer>
      </Hidden>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userCompany: state.auth.user.companyName,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    removeFromWishlist: (data, uid, index) =>
      dispatch(removeFromWishlist(data, uid, index)),
  });
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(MerchantWishlist)
);
