/*eslint-disable*/
import { db } from 'firebase/client';




export const getPdf = () => {
  return (dispatch) => {
    return db
      .collection('TermsandCondition').get()
      .then((snapshot) => {
        if (snapshot.empty) {
          dispatch(setPdf(""))
        } else {
          snapshot = snapshot.docs
          var pdf = ""
          snapshot.forEach((doc) => {
            pdf = doc.data()
          })
          dispatch(setPdf(pdf.downloadUrl))
        }
      })
  }
}

export const setPdf = (terms) => {
  return {
    type: "SET_PDF",
    terms
  }
}
