import React from 'react';
import DonutChart from 'react-donut-chart';
// core components
import Box from '@material-ui/core/Box';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import Button from 'components/CustomButtons/Button.js';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import actionsForNavigation from 'store/actions/navigation';
import AuthActions from 'store/actions/auth';
import { actionsForDialog } from 'store/actions/dialog';
import TermsAndConditionsDialog from 'views/Components/TermsAndConditionsDialog';
import Slide from '@material-ui/core/Slide';
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import { AiOutlineDollar } from 'react-icons/ai';
import { Add, Search } from '@material-ui/icons';

import Style from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';

import withStyles from '@material-ui/core/styles/withStyles';
import { useState } from 'react';
import { InputAdornment, MenuItem, Select, TextField } from '@material-ui/core';

export function DashboardFilter(props) {
    const {
        classes,
        history,
        rewardsSwitch,
        setRewardsSwitch,
        statusDropdown,
        setStatusDropdown,
        ascDropdown,
        setAscDropdown,
        searchString,
        setSearchString,
        statusArr,
        ascArr,
        handleRewardsToggle,
        handleSearch,
        handleFilter,
    } = props;

    return (
        <div>
            <GridContainer>
                <GridItem xs={4}>
                    <div className={classes.toggleDiv}>
                        <div
                            onClick={() => {
                                handleRewardsToggle("All Users")
                                history.push('/admin/dashboard')
                            }}
                            style={
                                rewardsSwitch === "All Users"
                                    ? {
                                        background: "#1A405F",
                                        border: "2px solid #1A405F",
                                        borderTopLeftRadius: "3px",
                                        borderBottomLeftRadius: "3px",
                                        color: "white"
                                    }
                                    : {}
                            }
                            className={classes.toggleSelection}
                        >
                            All Users
                        </div>
                        <div
                            value="Rewards"
                            onClick={() => {
                                handleRewardsToggle("Rewards")
                                history.push('/admin/rewards')
                            }}
                            style={
                                rewardsSwitch === "Rewards"
                                    ? {
                                        background: "#1A405F",
                                        border: "2px solid #1A405F",
                                        // borderTopRightRadius: "4px",
                                        // borderBottomRightRadius: "4px",
                                        color: "white"
                                    }
                                    : {}
                            }
                            className={classes.toggleSelection}
                        >
                            Rewards
                        </div>
                        <div
                            value="Order History"
                            onClick={() => handleRewardsToggle("Order History")}
                            style={
                                rewardsSwitch === "Order History"
                                    ? {
                                        background: "#1A405F",
                                        border: "2px solid #1A405F",
                                        borderTopLeftRadius: "4px",
                                        borderBottomLeftRadius: "4px",
                                        color: "white"
                                    }
                                    : {}
                            }
                            className={classes.toggleSelection}
                        >
                            Order History
                        </div>
                    </div>
                </GridItem>
                <GridItem xs={5}>
                    <Select
                        className={classes.selectMenuSort}
                        value={statusDropdown}
                        disableUnderline
                        classes={{
                            root: classes.selectText,
                        }}
                        onChange={(e) => {
                            setStatusDropdown(e.target.value)
                            handleFilter(ascDropdown, e.target.value)

                        }}
                    >
                        {statusArr.map((o, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    value={o}
                                    classes={{
                                        root: classes.selectText,
                                    }}
                                >
                                    {o}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <Select
                        style={{ width: "15%" }}
                        className={classes.selectAsc}
                        value={ascDropdown}
                        disableUnderline
                        classes={{
                            root: classes.selectText,
                        }}
                        onChange={(e) => {
                            setAscDropdown(e.target.value)
                            handleFilter(e.target.value, statusDropdown)
                        }}
                    >
                        {ascArr.map((o, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    value={o}
                                    classes={{
                                        root: classes.selectText,
                                    }}
                                >
                                    {o}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </GridItem>

                <GridItem xs={3}>
                    <TextField
                        className={classes.orderSearchBar}
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch();
                            }
                        }}
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                adornedEnd: classes.adournedEnd,
                            },
                            endAdornment: (
                                <InputAdornment className={classes.adournedEnd} position="end">
                                    <Button className={classes.searchButton} onClick={() => handleSearch()}>
                                        <Search style={{ marginRight: "5px" }} />
                                        Search
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                    />
                </GridItem>
            </GridContainer>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        // nothing yet
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
        edit: (key) =>
            dispatch(
                actionsForNavigation.openChild('Profile Editor', '/admin/user/editor')
            ),
        logout: () => dispatch(AuthActions.startLogout()),
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(DashboardFilter));
