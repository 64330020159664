import React, { useEffect } from 'react';
import { useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// mui
import { Button, Typography } from "@material-ui/core";

// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from 'components/Card/Card';

// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/rewardsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import { addProduct } from 'store/actions/cart';


export function Quantity(props) {
    const {
        classes,
        history,
        quantity,
        price,
        giftCardType,
        addProduct,
        data,
        denomination,
        checkAdded,
        user
    } = props;

    const [check, setCheck] = useState(false)

    useEffect(() => {
        if (data.giftCardType && (quantity && denomination && giftCardType)) {
            setCheck(true)
        }
        else if (!data.giftCardType && quantity) {
            setCheck(true)
        }
        else {
            setCheck(false)
        }
    }, [quantity, giftCardType, denomination])

    const numberFormatterDecimals = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    const numberFormatter = new Intl.NumberFormat('en-US', {
    });

    return (
        <div>
            <Card className={classes.detailCard}>
                <GridContainer>
                    <GridItem xs={12}>
                        <Typography className={classes.rewardDetailName}>
                            Quantity
                        </Typography>
                    </GridItem>

                    <GridItem xs={12}>
                        <Typography className={classes.rewardDetailDescription} style={{ fontWeight: 600 }}>
                            {quantity ? quantity : "0"}

                            {data.categories.electronics === true ? " Electronic(s)"
                                :
                                data.categories.experiences === true ? " Experience(s)"
                                    :
                                    data.categories.giftCards === true ? " Gift Card(s)"
                                        :
                                        data.categories.luxuryGoods === true ? " Luxury Good(s)"
                                            :
                                            data.categories.raffle === true ? " Raffle (s)"
                                                :
                                                " Trip"}
                        </Typography>

                        <Typography className={classes.rewardDetailDescription} style={{ fontWeight: 600, fontSize: "20px" }}>
                            {price ? numberFormatter.format(Number(price) * 100) : "0"}
                            <span style={{ fontSize: "14px" }}>
                                {" "}points
                            </span>
                        </Typography>
                    </GridItem>

                    <GridItem xs={12}>

                        {user.userType.includes('Merchants') ?
                            !data.added ?
                                <Button
                                    className={classes.addToCartButton} style={{ width: "100%" }}
                                    disabled={!check}
                                    onClick={() => {
                                        addProduct(data)
                                        checkAdded()
                                    }}
                                >
                                    Add to Cart
                                </Button>
                                :
                                <Button
                                    className={classes.addToCartButton}
                                    // onClick={() => addProduct(data)}
                                    style={{ background: "#06667C", width: "100%" }}
                                >
                                    Added to Cart
                                </Button>
                            :
                            null
                        }

                    </GridItem>
                </GridContainer>
            </Card>
        </div>
    )
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Quantity));