import React, { useEffect } from 'react';
import { useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Datetime from "react-datetime";
import { addProduct } from 'store/actions/cart';
// mui
import { Button, TextField, Typography, InputAdornment, IconButton } from "@material-ui/core";

// components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from 'components/Card/Card';

// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/rewardsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import 'views/Pages/travel/components/calendar.css';

// icons
import { ReactComponent as CalendarIcon } from "assets/img/tuzo/icons/calendarIconWhite.svg";
import { BiMinus } from 'react-icons/bi';
import { Add } from '@material-ui/icons';


export function Search(props) {
    const {
        classes,
        history,
        quantity,
        price,
        giftCardType,
        addProduct,
        data,
        denomination,
        checkAdded,
        user,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        adults,
        setAdults,
        children,
        setChildren
    } = props;

    const [check, setCheck] = useState(false)

    useEffect(() => {
        if (data.giftCardType && (quantity && denomination && giftCardType)) {
            setCheck(true)
        }
        else if (!data.giftCardType && quantity) {
            setCheck(true)
        }
        else {
            setCheck(false)
        }
    }, [quantity, giftCardType, denomination])

    const numberFormatterDecimals = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    const numberFormatter = new Intl.NumberFormat('en-US', {
    });

    return (
        <div>
            <Card className={classes.detailCard}>
                <GridContainer>
                    <GridItem xs={12}>
                        <Typography className={classes.rewardDetailName}>
                            Search Availability
                        </Typography>
                    </GridItem>

                    <GridItem xs={6}>
                        <Typography
                            className={classes.travelDetailNightlyDesc}
                            style={{ lineHeight: "16px", fontWeight: 600 }}
                        >
                            From
                        </Typography>

                        <Datetime
                            className='travel'
                            selected={fromDate}
                            dateFormat="MM/DD/YY"
                            onChange={(date, e) => {
                                setFromDate(date.format("MM/DD/YY"));
                            }}
                            timeFormat={false}
                            closeOnSelect={true}
                            closeOnClickOutside
                            renderInput={(props, openCalendar, closeCalendar) => (
                                <div onClick={openCalendar}>
                                    <TextField
                                        style={{ paddingLeft: "0px" }}
                                        className={classes.datetime}
                                        value={fromDate}
                                        variant="outlined"
                                        InputProps={{
                                            classes: {
                                                adornedEnd: classes.adournedEnd,
                                                input: classes.inputDatetime
                                            },
                                            endAdornment: (
                                                <InputAdornment
                                                    className={classes.adournedEnd}
                                                    position="end"
                                                >
                                                    <Button className={classes.datetimeButton}>
                                                        <CalendarIcon
                                                            className={classes.calendarIcon}
                                                            onClick={openCalendar}
                                                            style={{ width: "15px", height: "15px" }}
                                                        />
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            )}
                        />
                    </GridItem>

                    <GridItem xs={6}>
                        <Typography
                            className={classes.travelDetailNightlyDesc}
                            style={{ lineHeight: "16px", fontWeight: 600 }}
                        >
                            To
                        </Typography>

                        <Datetime
                            className='travel'
                            selected={toDate}
                            dateFormat="MM/DD/YY"
                            onChange={(date, e) => {
                                setToDate(date.format("MM/DD/YY"));
                            }}
                            timeFormat={false}
                            closeOnSelect={true}
                            closeOnClickOutside
                            renderInput={(props, openCalendar, closeCalendar) => (
                                <div onClick={openCalendar}>
                                    <TextField
                                        style={{ paddingLeft: "0px" }}
                                        className={classes.datetime}
                                        value={toDate}
                                        variant="outlined"
                                        InputProps={{
                                            classes: {
                                                adornedEnd: classes.adournedEnd,
                                                input: classes.inputDatetime
                                            },
                                            endAdornment: (
                                                <InputAdornment
                                                    className={classes.adournedEnd}
                                                    position="end"
                                                >
                                                    <Button className={classes.datetimeButton}>
                                                        <CalendarIcon
                                                            className={classes.calendarIcon}
                                                            onClick={openCalendar}
                                                            style={{ width: "15px", height: "15px" }}
                                                        />
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            )}
                        />
                    </GridItem>

                    <GridItem xs={12} style={{ marginTop: "5px" }}>
                        <Typography
                            className={classes.travelDetailNightlyDesc}
                            style={{ lineHeight: "16px", fontWeight: 600 }}
                        >
                            Travelers
                        </Typography>

                        <GridContainer>
                            <GridItem xs={5}>
                                <Typography
                                    className={classes.travelDetailNightlyDesc}
                                    style={{ lineHeight: "24px", fontWeight: 400 }}
                                >
                                    Adults
                                </Typography>
                            </GridItem>

                            <GridItem xs={7} style={{ display: "flex", alignItems: "center" }}>
                                <GridContainer>
                                    <GridItem xs={4}>
                                        <BiMinus
                                            className={classes.minusAddBtn}
                                            onClick={() => {
                                                if (adults > 1) {
                                                    setAdults(prev => prev - 1)
                                                }
                                                else {
                                                    setAdults(0)
                                                }
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={4}>
                                        <Typography
                                            className={classes.travelAdultsChildrenDesc}
                                            style={{ lineHeight: "24px", fontWeight: 400 }}
                                        >
                                            {adults}
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={4}>
                                        <GridItem xs={4}>
                                            <Add
                                                className={classes.minusAddBtn}
                                                onClick={() => {
                                                    setAdults(prev => prev + 1)
                                                }}
                                            />
                                        </GridItem>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={5}>
                                <Typography
                                    className={classes.travelDetailNightlyDesc}
                                    style={{ lineHeight: "24px", fontWeight: 400 }}
                                >
                                    Children (0 to 17)
                                </Typography>
                            </GridItem>

                            <GridItem xs={7} style={{ display: "flex", alignItems: "center" }}>
                                <GridContainer>
                                    <GridItem xs={4}>
                                        <BiMinus
                                            className={classes.minusAddBtn}
                                            onClick={() => {
                                                if (children > 1) {
                                                    setChildren(prev => prev - 1)
                                                }
                                                else {
                                                    setChildren(0)
                                                }
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={4}>
                                        <Typography
                                            className={classes.travelAdultsChildrenDesc}
                                            style={{ lineHeight: "24px", fontWeight: 400 }}
                                        >
                                            {children}
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={4}>
                                        <GridItem xs={4}>
                                            <Add
                                                className={classes.minusAddBtn}
                                                onClick={() => {
                                                    setChildren(prev => prev + 1)
                                                }}
                                            />
                                        </GridItem>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </GridItem>

                    <GridItem xs={12}>
                        <Button
                            className={classes.addToCartButton} style={{ width: "100%" }}
                            onClick={() => {
                                
                            }}
                        >
                            Search
                        </Button>

                 </GridItem>
                </GridContainer>
            </Card>
        </div>
    )
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Search));