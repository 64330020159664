/*eslint-disable*/
import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { NavLink } from 'react-router-dom';
import TopNavLink from 'components/NavLink/TopNavLink';
import cx from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';
import logoFull from 'assets/img/tuzo/tuzoHorizontal.png';
import UserInfo from './UserInfo';
// core components
import AdminNavbarLinks from 'components/Navbars/AdminNavbarLinks.js';

// @material-ui/icons
import Email from '@material-ui/icons/Email';

import sidebarStyle from 'assets/jss/material-dashboard-pro-react/components/sidebarStyle.js';
import sidebarBackground from 'assets/img/market-me/Nav Bar/SidebarBackground.png';

import 'assets/css/material-dashboard-pro-react.css';


import ProfileIcon from 'assets/img/market-me/profileIcon.png'
import InstagramIcon from 'assets/img/market-me/instagramIcon.png'
import LinkedInIcon from 'assets/img/market-me/linkedInLogo.png'
import EmailIcon from 'assets/img/market-me/emailIcon.png'
import TextIcon from 'assets/img/market-me/textIcon.png'
import CampaignIcon from 'assets/img/market-me/campaignIcon.png'
import DemographicsIcon from 'assets/img/market-me/demographicsIcon.png'
import SettingsLogo from 'assets/img/market-me/settingsLogo.png'
import ContactsIcon from 'assets/img/market-me/contactsIcon.png'

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
const path = window.location.pathname;
class SidebarWrapper extends React.Component {
  sidebarWrapper = React.createRef();
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }
  render() {
    const { className, user, headerLinks, links, userType } = this.props;
    return (
      <div className={className} ref={this.sidebarWrapper}>
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      miniActive: true,
      ...this.getCollapseStates(props.routes),
    };
  }
  mainPanel = React.createRef();
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? 'active' : '';
  };
  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    const { user, classes, color, rtlActive, userType } = this.props;
    // console.log(this.props)
    if (userType) {
      return routes.map((prop, key) => {
        if (prop.redirect || !prop.name) {
          return null;
        }
        // if (userType === 'Credit Applicant' && prop.name === 'Leads') {
        //   return null;
        // }
        if (prop.collapse) {
          var st = {};
          st[prop['state']] = !this.state[prop.state];
          const navLinkClasses =
            classes.itemLink +
            ' ' +
            cx({
              [' ' + classes.collapseActive]: this.getCollapseInitialState(
                prop.views
              ),
            });
          const itemText =
            classes.itemText +
            ' ' +
            cx({
              [classes.itemTextMini]:
                this.props.miniActive && this.state.miniActive,
              [classes.itemTextMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
              [classes.itemTextRTL]: rtlActive,
            });
          const collapseItemText =
            classes.collapseItemText +
            ' ' +
            cx({
              [classes.collapseItemTextMini]:
                this.props.miniActive && this.state.miniActive,
              [classes.collapseItemTextMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
              [classes.collapseItemTextRTL]: rtlActive,
            });
          const itemIcon =
            classes.itemIcon +
            ' ' +
            cx({
              [classes.itemIconRTL]: rtlActive,
            });
          const caret =
            classes.caret +
            ' ' +
            cx({
              [classes.caretRTL]: rtlActive,
            });
          const collapseItemMini =
            classes.collapseItemMini +
            ' ' +
            cx({
              [classes.collapseItemMiniRTL]: rtlActive,
            });
          return (
            <ListItem
              key={key}
              className={cx(
                { [classes.item]: prop.icon !== undefined },
                { [classes.collapseItem]: prop.icon === undefined }
              )}
            >
              <NavLink
                to={'#'}
                className={navLinkClasses}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState(st);
                }}
              >
                {prop.icon !== undefined ? (
                  typeof prop.icon === 'string' ? (
                    <Icon className={itemIcon}>{prop.icon}</Icon>
                  ) : (
                    <prop.icon className={itemIcon} />
                  )
                ) : (
                  <span className={collapseItemMini}>
                    {rtlActive ? prop.rtlMini : prop.mini}
                  </span>
                )}
                <ListItemText
                  style={{ fontWeight: 600, fontSize: '20px' }}
                  primary={rtlActive ? prop.rtlName : prop.name}
                  secondary={
                    <b
                      className={
                        caret +
                        ' ' +
                        (this.state[prop.state] ? classes.caretActive : '')
                      }
                    />
                  }
                  disableTypography={true}
                  className={cx(
                    { [itemText]: prop.icon !== undefined },
                    { [collapseItemText]: prop.icon === undefined }
                  )}
                />
              </NavLink>
              <Collapse in={this.state[prop.state]} unmountOnExit>
                <List className={classes.list + ' ' + classes.collapseList}>
                  {this.createLinks(prop.views)}
                </List>
              </Collapse>
            </ListItem>
          );
        }
        const innerNavLinkClasses =
          classes.collapseItemLink +
          ' ' +
          cx({
            [' ' + classes[color]]: this.activeRoute(prop.path),
          });
        const collapseItemMini =
          classes.collapseItemMini +
          ' ' +
          cx({
            [classes.collapseItemMiniRTL]: rtlActive,
          });
        const navLinkClasses =
          classes.itemLink +
          ' ' +
          cx({
            [' ' + classes[color]]: this.activeRoute(prop.path),
          });
        const itemText =
          classes.itemText +
          ' ' +
          cx({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive,
          });
        const collapseItemText =
          classes.collapseItemText +
          ' ' +
          cx({
            [classes.collapseItemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextRTL]: rtlActive,
          });
        const itemIcon =
          classes.itemIcon +
          ' ' +
          cx({
            [classes.itemIconRTL]: rtlActive,
          });
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <TopNavLink
              style={
                this.props.miniActive &&
                  prop.path === window.location.pathname.slice(6)
                  ? {
                    backgroundColor: 'rgba(94, 177, 227, 1)',
                    color: 'yellow',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: "10px !important"
                  }
                  : this.props.miniActive &&
                    prop.path !== window.location.pathname.slice(6)
                    ? {
                      color: 'rgba(39, 75, 115, 1)',
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '#13294C',
                      borderRadius: "10px !important"
                    }
                    : !this.props.miniActive &&
                      prop.path === window.location.pathname.slice(6)
                      ? {
                        background:
                          'linear-gradient(153.99deg, #1D9BC9 12.91%, #005479 107.32%)',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: "10px !important"
                      }
                      : !this.props.miniActive &&
                        prop.path !== window.location.pathname.slice(6)
                        ? { color: 'white', display: 'flex', alignItems: 'center', background: "#13294C",  borderRadius: "10px !important" }
                        : {  }
              }
              to={prop.layout + prop.path}
              title={prop.name}
              className={cx(
                { [navLinkClasses]: prop.icon !== undefined },
                { [innerNavLinkClasses]: prop.icon === undefined }
              )}
            >

              {prop.name === 'Profile' ? (
                <img style={this.props.miniActive ? {    width: "30px"} : {}} className={classes.iconImg} src={ProfileIcon} />
              ) : prop.name === 'Instagram' ? (
                <img style={this.props.miniActive ? {    width: "30px"} : {}} className={classes.iconImg} src={InstagramIcon} />
              ) : prop.name === 'LinkedIn' ? (
                <img style={this.props.miniActive ? {    width: "30px"} : {}} className={classes.iconImg} src={LinkedInIcon} />
              ) : prop.name === 'Email' ? (
                <img style={this.props.miniActive ? {    width: "30px"} : {}} className={classes.iconImg} src={EmailIcon} />
              ) : prop.name === 'SMS Text' ? (
                <img style={this.props.miniActive ? {    width: "30px"} : {}} className={classes.iconImg} src={TextIcon} />
              ) : prop.name === 'Campaigns' ? (
                <img style={this.props.miniActive ? {    width: "30px"} : {}} className={classes.iconImg} src={CampaignIcon} />
              ) : prop.name === 'Demographics' ? (
                <img style={this.props.miniActive ? {    width: "30px"} : {}} className={classes.iconImg} src={DemographicsIcon} />
              ) : prop.name === 'Settings' ? (
                <img style={this.props.miniActive ? {    width: "30px"} : {}} className={classes.iconImg} src={SettingsLogo} />
              ) : prop.name === 'Contacts' ? (
                <img style={this.props.miniActive ? {    width: "30px"} : {}} className={classes.iconImg} src={ContactsIcon} />
              ) : (
                null
              )}


              <ListItemText
                style={{ fontWeight: 400, fontSize: '20px' }}
                primary={rtlActive ? prop.rtlName : prop.name}
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </TopNavLink>
          </ListItem>
        );
      });
    }
  };
  render() {
    const {
      classes,
      logo,
      image,
      logoText,
      routes,
      bgColor,
      rtlActive,
    } = this.props;

    var links = (
      <List className={classes.list}>{this.createLinks(routes)}</List>
    );

    const logoNormal =
      classes.logoNormal +
      ' ' +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: rtlActive,
      });
    const logoMini =
      classes.logoMini +
      ' ' +
      cx({
        [classes.logoMiniRTL]: rtlActive,
      });
    const logoClasses =
      classes.logo +
      ' ' +
      cx({
        [classes.whiteAfter]: bgColor === 'white',
      });
    var brand = (
      <div className={logoClasses}>
        <img src={logoFull} alt='logo' className={classes.img} style={{ width: "70%", margin: "1rem 2rem" }}/>
      </div>
    );
    const drawerPaper =
      classes.drawerPaper +
      ' ' +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive,
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      ' ' +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf('Win') > -1,
      });
    return (
      <div ref={this.mainPanel}>
        <Hidden mdUp implementation='css'>
          <Drawer
            variant='temporary'
            anchor={rtlActive ? 'left' : 'right'}
            open={this.props.open}
            classes={{
              paper: drawerPaper + ' ' + classes[bgColor + 'Background']
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              headerLinks={<AdminNavbarLinks rtlActive={rtlActive} />}
              links={links}
            />
            {image !== undefined ? (
              <div className={classes.background} />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation='css'>
          <Drawer
            // onMouseOver={() => this.setState({ miniActive: false })}
            // onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? 'right' : 'left'}
            variant='permanent'
            open
            classes={{
              paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
            }}
          >
            {/* <img src={sidebarBackground} style={{
              zIndex: 4,
              pointerEvents: "none",
              position: "absolute",
              width: "100%",
              bottom: 0,
            }}/> */}
            {brand}
            <SidebarWrapper className={sidebarWrapper} links={links} />
            {image !== undefined ? (
              <div className={classes.background} />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: 'blue',
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    'white',
    'red',
    'orange',
    'green',
    'blue',
    'purple',
    'rose',
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  headerLinks: PropTypes.object,
  links: PropTypes.object,
};

function mapStateToProps() {
  return (state) => {
    console.log(state);
    return {
      user: state.auth,
      userType:
        state.auth.userType !== 'N/A'
          ? state.auth.userType
          : state.auth.user.userType,
    };
  };
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(sidebarStyle)
)(Sidebar);
