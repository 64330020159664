const changeDropdownValue = (dropdown) => {
    return {
      type: "CHANGE_VALUE",
      dropdown,
    };
  };

  export const changeValue = (dropdown) => {
    return (dispatch) => {
      dispatch(changeDropdownValue(dropdown));
    };
  };