import React from 'react';
import moment from "moment"
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { CSVLink } from "react-csv";
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import { Hidden, IconButton, MenuItem, Select, Typography } from '@material-ui/core';

// @material-ui/icons
import { ReactComponent as CSVIcon } from "assets/img/tuzo/icons/CSVIcon.svg";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from 'react-icons/ai';

// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/reportsStyle';
import withStyles from '@material-ui/core/styles/withStyles';


export function ReportsFilter(props) {
    const {
        classes,
        history,
        sortArr,
        sortValue,
        setSortValue,
        handleReportToggle,
        reportType,
        setReportType,
        dateFilter,
        setDateFilter,
        increaseDate,
        decreaseDate,
        reportSelections,
        headers,
        csvData,
        points
    } = props;


    return (
        <div>
            <Hidden mdUp implementation='css'>
                <GridContainer>
                    <GridItem xs={12} style={{ display: "flex", }}>
                        <Typography variant="h1" className={classes.filterDesc} style={{ width: "60%" }}>
                            Switch Report
                        </Typography>
                        <Select
                            className={classes.selectMenuSortReportsPage}
                            value={sortValue}
                            disableUnderline
                            classes={{
                                root: classes.selectText,
                            }}
                            onChange={(e) => {
                                history.push(e.target.value.link)
                            }}
                            displayEmpty
                            renderValue={
                                sortValue !== "" ? undefined : () => "Batch History"
                            }
                        >
                            {reportSelections.map((o, i) => {
                                return (
                                    <MenuItem
                                        key={i}
                                        value={o}
                                        classes={{
                                            root: classes.selectMenuItem,
                                        }}
                                    >
                                        {o.name}

                                    </MenuItem>
                                );
                            })}
                        </Select>

                    </GridItem>

                    <GridItem xs={12} style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                        <div className={classes.toggleDivMobile}>
                            <div
                                onClick={() => handleReportToggle("Daily")}
                                style={
                                    reportType === "Daily"
                                        ? {
                                            height: "100%",
                                            background: "#1A405F",
                                            border: "2px solid #1A405F",
                                            borderTopLeftRadius: "3px",
                                            borderBottomLeftRadius: "3px",
                                            color: "white"
                                        }
                                        : {}
                                }
                                className={classes.toggleSelection}
                            >
                                Daily
                            </div>
                            <div
                                value="Monthly"
                                onClick={() => handleReportToggle("Monthly")}
                                style={
                                    reportType === "Monthly"
                                        ? {
                                            height: "100%",
                                            background: "#1A405F",
                                            border: "2px solid #1A405F",
                                            color: "white"
                                        }
                                        : {}
                                }
                                className={classes.toggleSelection}
                            >
                                Monthly
                            </div>
                        </div>
                    </GridItem>

                    <GridItem xs={12} style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                        <Button
                            className={classes.csvButtonMobile}
                        >
                            <CSVLink
                                style={{ color: "white" }}
                                headers={headers}
                                data={
                                    csvData.map((d, index) => ({
                                        date: moment(d.reportDate).format("dddd, MMMM Do YYYY, h:mm a"),
                                        numTransactions: d.transactionCount,
                                        total: Number(d.saleAmount).toFixed(2),
                                        referenceNum: d.batchNumber,
                                        pts: points[index]
                                    }))
                                }
                                filename={`${dateFilter}_batch_history`}

                            >
                                <CSVIcon style={{ marginRight: "10px" }} /> Export CSV

                            </CSVLink>
                        </Button>
                    </GridItem>

                    <GridItem xs={12} style={{ display: "flex", justifyContent: "space-between", margin: "10px 0px" }}>
                        <IconButton
                            className={classes.arrows}
                            onClick={() => decreaseDate(dateFilter)}
                        >
                            <AiOutlineCaretLeft style={{ color: "white" }} />
                        </IconButton>
                        <span className={classes.date}>
                            {dateFilter}
                        </span>

                        <IconButton
                            className={classes.arrows}
                            onClick={() => increaseDate(dateFilter)}
                        >
                            <AiOutlineCaretRight style={{ color: "white" }} />
                        </IconButton>
                    </GridItem>
                </GridContainer>
            </Hidden>

            <Hidden smDown implementation='css'>
                <GridContainer>
                    <GridItem xs={4} style={{ display: "flex", }}>
                        <Typography variant="h1" className={classes.filterDesc}>
                            Switch Report
                        </Typography>
                        <Select
                            className={classes.selectMenuSortReportsPage}
                            value={sortValue}
                            disableUnderline
                            classes={{
                                root: classes.selectText,
                            }}
                            onChange={(e) => {
                                history.push(e.target.value.link)
                            }}
                            displayEmpty
                            renderValue={
                                sortValue !== "" ? undefined : () => "Batch History"
                            }
                        >
                            {reportSelections.map((o, i) => {
                                return (
                                    <MenuItem
                                        key={i}
                                        value={o}
                                        classes={{
                                            root: classes.selectMenuItem,
                                        }}
                                    >
                                        {o.name}

                                    </MenuItem>
                                );
                            })}
                        </Select>

                    </GridItem>

                    <GridItem xs={4} style={{ display: "flex", justifyContent: "center" }}>
                        <div className={classes.toggleDiv}>
                            <div
                                onClick={() => handleReportToggle("Daily")}
                                style={
                                    reportType === "Daily"
                                        ? {
                                            height: "100%",
                                            background: "#1A405F",
                                            border: "2px solid #1A405F",
                                            borderTopLeftRadius: "3px",
                                            borderBottomLeftRadius: "3px",
                                            color: "white"
                                        }
                                        : {}
                                }
                                className={classes.toggleSelection}
                            >
                                Daily
                            </div>
                            <div
                                value="Monthly"
                                onClick={() => handleReportToggle("Monthly")}
                                style={
                                    reportType === "Monthly"
                                        ? {
                                            height: "100%",
                                            background: "#1A405F",
                                            border: "2px solid #1A405F",
                                            color: "white"
                                        }
                                        : {}
                                }
                                className={classes.toggleSelection}
                            >
                                Monthly
                            </div>
                        </div>
                    </GridItem>

                    <GridItem xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            className={classes.csvButton}
                        >
                            <CSVLink
                                style={{ color: "white" }}
                                headers={headers}
                                data={
                                    csvData.map((d, index) => ({
                                        date: moment(d.reportDate).format("dddd, MMMM Do YYYY, h:mm a"),
                                        numTransactions: d.transactionCount,
                                        total: Number(d.saleAmount).toFixed(2),
                                        referenceNum: d.batchNumber,
                                        pts: points[index]
                                    }))
                                }
                                filename={`${dateFilter}_batch_history`}

                            >
                                <CSVIcon style={{ marginRight: "10px" }} /> Export CSV

                            </CSVLink>
                        </Button>
                    </GridItem>

                    <GridItem xs={3} style={{ display: "flex", justifyContent: "space-between", margin: "10px 0px" }}>
                        <IconButton
                            className={classes.arrows}
                            onClick={() => decreaseDate(dateFilter)}
                        >
                            <AiOutlineCaretLeft style={{ color: "white" }} />
                        </IconButton>
                        <span className={classes.date}>
                            {dateFilter}
                        </span>

                        <IconButton
                            className={classes.arrows}
                            onClick={() => increaseDate(dateFilter)}
                        >
                            <AiOutlineCaretRight style={{ color: "white" }} />
                        </IconButton>
                    </GridItem>
                </GridContainer>
            </Hidden>
        </div >
    );
}

function mapStateToProps(state) {
    return {
        // nothing yet
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(ReportsFilter));
