import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { useDropzone } from 'react-dropzone';
import Dropzone from 'react-dropzone'
// core components
import GridContainer from 'components/Grid/GridContainer';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem';
import { Button, Card, Hidden, MenuItem, Select, TextField } from '@material-ui/core';

// icons
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";
import { BsDownload } from "react-icons/bs";


// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/rewardsStyle';


export function MainImage(props) {
    const {
        classes,
        history,
        mainImage,
        setMainImage,
    } = props;

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        maxFiles: 1
    });

    return (
        <div>
            <Hidden smDown implementation="css">
                <Card className={classes.card} style={{ marginTop: "15px" }}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <h5 className={classes.cardHeader}>Main Image</h5>
                        </GridItem>
                        <GridItem xs={12}>
                            <div style={{ marginLeft: "20px" }}>
                                <Dropzone
                                    max={104857600}
                                    multiple={false}
                                    open
                                    noClick={mainImage.length === 0 ? true : false}
                                    accept=".jpeg, .png, .jpg"
                                    onDrop={acceptedFiles => {
                                        var array = []
                                        Array.from(acceptedFiles).forEach(file => {
                                            array = [...array, file]
                                        });
                                        setMainImage(array)
                                    }}>
                                    {({ getRootProps, getInputProps, open }) => (
                                        <div {...getRootProps()}
                                            className={classes.imgSquare}>
                                            <input {...getInputProps()} />
                                            {mainImage.length === 0 ?
                                                <div style={{ textAlign: "center" }}>
                                                    <p style={{ marginTop: "10px", fontSize: "18px" }}>
                                                        <BsDownload
                                                            style={{ color: "#56CA93", fontSize: "50px", marginBottom: "-10px" }} />
                                                        {"  "}
                                                    </p>
                                                    <p>
                                                        Drop main reward image files here or  click “Set main image” below
                                                    </p>
                                                </div>
                                                :
                                                mainImage.map((f, i) => {
                                                    return (
                                                        <img
                                                            key={i}
                                                            style={{ height: "100px", width: '150px', marginTop: "20px" }}
                                                            src={URL.createObjectURL(f)}
                                                        />
                                                    )
                                                })
                                            }
                                            {mainImage.length === 0 ?
                                                <p
                                                    style={{ textDecoration: "underline", cursor: "pointer" }}
                                                    className={classes.imgDescription}
                                                    onClick={open}
                                                >
                                                    Set main image
                                                </p>
                                                :
                                                null
                                            }

                                        </div>
                                    )}
                                </Dropzone>
                            </div>
                        </GridItem>
                        {mainImage.length === 0 ?
                            <>
                                <GridItem style={{ marginTop: "15px" }} xs={12}>
                                    <p
                                        style={{ fontSize: "14px" }}
                                        className={classes.imgDescription}
                                    >
                                        For best results, upload JPEG or PNG files that are
                                        1000 by 1000 pixels or larger.
                                        Maximum upload file size: 100 MB.
                                    </p>
                                </GridItem>
                            </>
                            :
                            <>
                                <GridItem style={{ marginTop: "15px" }} xs={12}>
                                    <p
                                        className={classes.imgDescription}
                                    >
                                        Click the image to update
                                    </p>
                                    <p
                                        style={{
                                            textDecoration: "underline",
                                            cursor: "pointer"
                                        }}
                                        className={classes.imgDescription}
                                        onClick={() => setMainImage([])}
                                    >
                                        Remove main image
                                    </p>
                                </GridItem>
                            </>
                        }
                    </GridContainer>
                </Card>
            </Hidden>
        </div>
    );
}
function mapStateToProps(state) {
    return {
        // nothing yet
    };
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}
export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(MainImage));