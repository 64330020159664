import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

// core components
import GridContainer from 'components/Grid/GridContainer';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem';
import { Button, Card, Hidden, MenuItem, Select, TextField, TextareaAutosize } from '@material-ui/core';

// icons
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";


// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/rewardsStyle';


export function GiftCardsGeneral(props) {
    const {
        classes,
        history,
        regularPrice,
        setRegularPrice,
        salePrice,
        setSalePrice,
        sku,
        setSku,
        taxStatus,
        setTaxStatus,
        taxClass,
        setTaxClass,
        notes,
        setNotes,
        taxClassArr,
        taxStatusArr,
        ptsCalculatorReg,
        ptsCalculatorSale,
        salePriceConvert,
        regPriceConvert,
        units,
        setUnits,
        typeArr,
        cardType,
        setCardType
    } = props;



    return (
        <div>
            <Hidden smDown implementation="css">
                <Card className={classes.card} style={{ marginTop: "15px" }}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <h5 className={classes.cardHeader}>General</h5>
                        </GridItem>
                        <GridItem xs={6} >
                            <GridContainer>
                                <GridItem xs={5}>
                                    <p className={classes.roomLabel}>
                                        Cost ($)
                                    </p>
                                </GridItem>

                                <GridItem xs={7}>
                                    <TextField
                                        type="number"
                                        // style={{ marginTop: "0px" }}
                                        className={classes.giftCardTextField}
                                        value={regularPrice}
                                        onChange={(e) => {
                                            setRegularPrice(e.target.value)
                                            ptsCalculatorReg(e.target.value)

                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                adornedEnd: classes.adournedEnd,
                                                input: classes.input
                                            },
                                            inputProps: {
                                                min: 0,
                                            }
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={5}>
                                    <p className={classes.giftCardLabel}>
                                        Equivalent Pts
                                    </p>
                                </GridItem>

                                <GridItem xs={7}>
                                    <p className={classes.ptsLabel} >
                                        {regularPrice ? (regPriceConvert).toLocaleString() : ""}
                                    </p>
                                </GridItem>

                                <GridItem xs={5}>
                                    <p className={classes.giftCardLabel}>
                                        Listed Price ($)
                                    </p>
                                </GridItem>

                                <GridItem xs={7}>
                                    <TextField
                                        type="number"
                                        className={classes.giftCardTextField}
                                        value={salePrice}
                                        onChange={(e) => {
                                            setSalePrice(e.target.value)
                                            ptsCalculatorSale(e.target.value)
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                adornedEnd: classes.adournedEnd,
                                                input: classes.input
                                            },
                                            inputProps: {
                                                min: 0,
                                            }
                                        }}
                                    />
                                </GridItem>

                                <GridItem xs={5}>
                                    <p className={classes.giftCardLabel}>
                                        Equivalent Pts
                                    </p>
                                </GridItem>

                                <GridItem xs={7}>
                                    <p className={classes.ptsLabel} style={{ marginTop: "25px" }}>
                                        {salePrice ? (salePriceConvert).toLocaleString() : ""}
                                    </p>
                                </GridItem>

                                <GridItem xs={5}>
                                    <p className={classes.giftCardLabel}>
                                        SKU
                                    </p>
                                </GridItem>

                                <GridItem xs={7}>
                                    <TextField
                                        className={classes.giftCardTextField}
                                        value={sku}
                                        onChange={(e) => setSku(e.target.value)}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                adornedEnd: classes.adournedEnd,
                                                input: classes.input
                                            },
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={5}>
                                    <p className={classes.giftCardLabel}>
                                        Gift Card Type
                                    </p>
                                </GridItem>

                                <GridItem xs={7}>
                                    <Select
                                        className={classes.selectMenuSort}
                                        value={cardType}
                                        disableUnderline
                                        classes={{
                                            root: classes.selectText,
                                        }}
                                        onChange={(e) => setCardType(e.target.value)}
                                        style={{ width: "100%" }}
                                    >
                                        {typeArr.map((o, i) => {
                                            return (
                                                <MenuItem
                                                    key={i}
                                                    value={o}
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                    }}
                                                >
                                                    {o}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </GridItem>

                            </GridContainer>
                        </GridItem>
                        <GridItem xs={6}>
                            <GridContainer>
                                <GridItem xs={5}>
                                    <p className={classes.giftCardLabel} >
                                        Tax Status
                                    </p>
                                </GridItem>

                                <GridItem xs={7}>
                                    <Select
                                        className={classes.selectMenuSort}
                                        value={taxStatus}
                                        disableUnderline
                                        classes={{
                                            root: classes.selectText,
                                        }}
                                        onChange={(e) => setTaxStatus(e.target.value)}
                                    >
                                        {taxStatusArr.map((o, i) => {
                                            return (
                                                <MenuItem
                                                    key={i}
                                                    value={o}
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                    }}
                                                >
                                                    {o}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </GridItem>

                                <GridItem xs={5}>
                                    <p className={classes.giftCardLabel} >
                                        Tax Class
                                    </p>
                                </GridItem>

                                <GridItem xs={7}>
                                    <Select
                                        className={classes.selectMenuSort}
                                        value={taxClass}
                                        disableUnderline
                                        classes={{
                                            root: classes.selectText,
                                        }}
                                        onChange={(e) => setTaxClass(e.target.value)}
                                    >
                                        {taxClassArr.map((o, i) => {
                                            return (
                                                <MenuItem
                                                    key={i}
                                                    value={o}
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                    }}
                                                >
                                                    {o}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </GridItem>

                                <GridItem xs={5}>
                                    <p className={classes.giftCardLabel} >
                                       Units Available
                                    </p>
                                </GridItem>

                                <GridItem xs={7}>
                                    <TextField
                                        style={{ width: "90%" }}
                                        placeholder='Leave blank for unlimited'
                                        type="number"
                                        className={classes.giftCardTextField}
                                        value={units}
                                        onChange={(e) => {
                                            setUnits(e.target.value)
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                adornedEnd: classes.adournedEnd,
                                                input: classes.input
                                            },
                                            inputProps: {
                                                min: 0,
                                            }
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12}>
                                    <p className={classes.giftCardLabel}>
                                        Purchase Note <span className={classes.giftCardLabel} style={{ fontSize: "14px", whiteSpace: "normal" }}>
                                            (Displays to customer after purchase)
                                        </span>
                                    </p>
                                    <TextareaAutosize
                                        aria-label="empty textarea"
                                        className={classes.giftCardTextField}
                                        onChange={(e) => setNotes(e.target.value)}
                                        placeholder=""
                                        style={{ maxWidth: "100%", minHeight: "100px", height:"50px", marginTop:"0px" }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </Card>
            </Hidden>
        </div>
    );
}
function mapStateToProps(state) {
    return {
        // nothing yet
    };
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}
export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(GiftCardsGeneral));