/*eslint-disable*/
const terms = "N/A"

export const initState = {
    terms
}


export default (state = initState, action) => {
    switch (action.type) {
        case "SET_PDF":
            var toReturn = { ...state }
            toReturn.terms = action.terms
            return toReturn

        default:
            return state
    }
}
