import React from 'react';
import { useState } from 'react';
import Datetime from "react-datetime";

// core components
import Box from '@material-ui/core/Box';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import Button from 'components/CustomButtons/Button.js';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import actionsForNavigation from 'store/actions/navigation';
import AuthActions from 'store/actions/auth';
import Slide from '@material-ui/core/Slide';
import Hidden from "@material-ui/core/Hidden";
import { InputAdornment, MenuItem, Select, TextField, Typography } from '@material-ui/core';

// @material-ui/icons


// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/travelStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import tuzoLogo from "assets/img/tuzo/Tuzo-Icon-square.png"

export function TravelAd(props) {
    const {
        classes,
        history,
    } = props;

    return (
        <div
            style={{
                padding: "10px",
                width: "98.4vw",
                marginLeft: "-17px",
                height: "130px",
                backgroundColor: "#06667C",
                borderRadius: "7px"
            }}
        >
            <GridContainer style={{
                display: "flex",
                alignItems: "center",
            }}>
                <GridItem xs={2}>
                    <img
                        src={tuzoLogo}
                        style={{
                            width: "120px",
                            height: "120px",
                            marginLeft: "5px"
                        }}
                    />
                </GridItem>
                <GridItem xs={8}>
                    <div >
                        <Typography variant="h2" className={classes.rewardsBannerTitle} style={{ fontSize: "19px", fontWeight: 600, lineHeight: "24px" }}>
                            Ad or Promotional Material Banner Goes Here. Example:
                            ‘Save 10% or more on thousands of hotels with the Hotel Loyalty membership
                            program. Also, members save 5% on car rentals.
                        </Typography >
                    </div>
                </GridItem>

                <GridItem xs={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        style={{ background: "#1A405F", marginTop: "-5px", borderRadius: "7px" }}
                        className={classes.travelFiltersButton}
                    >
                        Sign Up
                    </Button>
                </GridItem>
            </GridContainer>

        </div>
    )
}

function mapStateToProps(state) {
    return {
        // nothing yet
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(TravelAd));
