import React, { useState, createRef, useEffect } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import moment from "moment"
import Datetime from "react-datetime";
import { CSVLink } from "react-csv";

// core components
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { Dialog, Typography, Card, Popper, TextField, InputAdornment } from "@material-ui/core";

// styling 
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import withStyles from "@material-ui/core/styles/withStyles";

// icons
import { ReactComponent as CalendarIcon } from "assets/img/tuzo/icons/calendarIconWhite.svg";
import { BsDownload } from "react-icons/bs";

const csvHeaders = [
    { label: "Merchant #", key: "merchantNumber" },
    { label: "Merchant Name", key: "name" },
    { label: "Quantity", key: "quantity" },
    { label: "Points Earned", key: "pointsEarned" },
    { label: "Day Total", key: "dayTotal" },
    { label: "Date", key: "reportDate" },
    { label: "Basis Points", key: "userBasisPts" },
];

export function DateRange(props) {
    const {
        classes,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        getIsoAdminReport,
        csvLinkRef,
        csvData
    } = props

    const [filteredData, setFilteredData] = useState([])

    const tomorrow = moment().add(0, "day");
    const disableFutureDt = (current) => {
        return current.isBefore(tomorrow);
    };

    const disableFromDt = (current) => {
        return current.isAfter(fromDate);
    };

    const numberFormatterDecimals = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    const numberFormatter = new Intl.NumberFormat('en-US', {
    });

    const filterDateReport = () => {
        if(csvData.length > 0){
            const filtered = csvData.filter((f) => {
                const formattedDate = moment(f.reportDate).format("MM/DD/YY")
                if (moment(formattedDate).isBetween(fromDate, toDate, null, [])) {
                    return true
                }
            })
            setFilteredData(filtered)
        }else{
            setFilteredData([])
        }
        
        
    }    

    return (
        <div>
            <GridContainer>
                <GridItem xs={4} style={{ display: "flex", alignItems: 'center' }}>
                    <Typography variant="h2" className={classes.cardDescBilling} style={{ marginLeft: "0px", marginTop: "0px" }}>
                        From
                    </Typography>

                    <Datetime
                        isValidDate={disableFutureDt}
                        className='travel'
                        selected={fromDate}
                        dateFormat="MM/DD/YY"
                        onChange={(date, e) => {
                            setFromDate(date.format("MM/DD/YY"));
                        }}
                        timeFormat={false}
                        closeOnSelect={true}
                        closeOnClickOutside
                        renderInput={(props, openCalendar, closeCalendar) => (
                            <div onClick={openCalendar}>
                                <TextField
                                    style={{ marginLeft: "10px" }}
                                    className={classes.datetime}
                                    value={fromDate}
                                    variant="outlined"
                                    InputProps={{
                                        classes: {
                                            adornedEnd: classes.adournedEnd,
                                            input: classes.input
                                        },
                                        endAdornment: (
                                            <InputAdornment
                                                className={classes.adournedEnd}
                                                position="end"
                                            >
                                                <Button className={classes.datetimeButton}>
                                                    <CalendarIcon
                                                        className={classes.calendarIcon}
                                                        onClick={openCalendar}
                                                    />
                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        )}
                    />
                </GridItem>

                <GridItem xs={4} style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h2" className={classes.cardDescBilling} style={{ marginLeft: "0px", marginTop: "0px" }}>
                        To
                    </Typography>
                    <Datetime
                        isValidDate={disableFromDt}
                        className='travel'
                        selected={toDate}
                        dateFormat="MM/DD/YY"
                        onChange={(date, e) => {
                            setToDate(date.format("MM/DD/YY"));
                        }}
                        timeFormat={false}
                        closeOnSelect={true}
                        closeOnClickOutside
                        renderInput={(props, openCalendar, closeCalendar) => (
                            <div onClick={openCalendar}>
                                <TextField
                                    style={{ marginLeft: "10px" }}
                                    className={classes.datetime}
                                    value={toDate}
                                    variant="outlined"
                                    InputProps={{
                                        classes: {
                                            adornedEnd: classes.adournedEnd,
                                            input: classes.input
                                        },
                                        endAdornment: (
                                            <InputAdornment
                                                className={classes.adournedEnd}
                                                position="end"
                                            >
                                                <Button className={classes.datetimeButton}>
                                                    <CalendarIcon
                                                        className={classes.calendarIcon}
                                                        onClick={openCalendar}
                                                    />
                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        )}
                    />
                </GridItem>

                <GridItem xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        style={{ marginTop: "0px", width: "200px" }}
                        className={classes.downloadButton}
                        onClick={() => filterDateReport()}
                    >
                        <CSVLink
                            style={{ color: "white" }}
                            headers={csvHeaders}
                            data={
                                filteredData.map(d => ({
                                    // merchantNumber: d[0].merchantNumber,
                                    // name: d[0].merchantName,
                                    // email: d[0].email,
                                    // netAmount: numberFormatterDecimals.format(d[0].netAmount),
                                    // prepaidDiscount: numberFormatterDecimals.format(d[0].prepaidDiscount),
                                    // reportDate: moment(d[0].reportDate).format("MM/DD/YY"),
                                    // returnAmount: numberFormatterDecimals.format(d[0].returnAmount),                                    pointsEarned: numberFormatter.format(d[1]),
                                    // saleAmount: numberFormatterDecimals.format(d[0].saleAmount),  
                                    // terminalNumber: d[0].terminalNumber,
                                    // transactionCount: d[0].transactionCount,
                                    merchantNumber: d.merchantNumber,
                                    name: d.merchantName,
                                    quantity: d.transactionCount,
                                    pointsEarned: d.ptsConversion,
                                    dayTotal: numberFormatterDecimals.format(d.netAmount),
                                    reportDate: moment(d.reportDate).format("MM/DD/YY"),
                                    userBasisPts: d.userBasisPts,

                                }))
                            }
                            filename={`${fromDate}_deposit_history`}
                            ref={csvLinkRef}
                        >
                            <BsDownload style={{ marginRight: "10px" }} /> Download Report
                        </CSVLink>
                    </Button>
                </GridItem>
            </GridContainer>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        // nothing yet
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(profileStyle)
)(DateRange));