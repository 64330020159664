import React, { useEffect } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// mui core
import { Button, Card, MenuItem, Select, SvgIcon, TextField, Tooltip, Typography } from "@material-ui/core";

//components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

//styling
import withStyles from '@material-ui/core/styles/withStyles';
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";

//icons
import { Add, CheckCircle, Close, } from '@material-ui/icons';

export function GatewayPage(props) {
    const {
        classes,
        gatewayArr,
        gateway,
        setGateway,
        globalEdit,
        apiEdit,
        setApiEdit,
        apiInfo,
        setApiInfo
    } = props;

    const maskDetails = (strKey) => {
        const mask = strKey.toLowerCase().substring(0, strKey.length - 4).replace(/[A-z0-9\W]/g, "*")
        const subStr = strKey.substring(strKey.length - 4, strKey.length)

        if(mask.length <= 3){
            let newMask = "*******" + mask
            return newMask.substring(0,4) + subStr
        }else{
            return mask.substring(0,4) + subStr
        }
    }

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} style={{ marginTop: "15px" }}>
                    <Card className={classes.profileCardStyling}>
                        <GridContainer>
                            <GridItem xs={7}>
                            <div className={classes.divHover}>
                                    <Tooltip
                                        id="tooltip-top"
                                        title={`This information is accessible through your processing gateway. 
                                    Common locations to find it are highlighted in our blog article.`}
                                        placement="top-start"
                                        classes={{ tooltip: classes.tooltipPts }}
                                        style={{ fontSize: "13px !important" }}
                                    >
                                        <Typography variant="h2" className={classes.cardHeader}>
                                            Processing Gateway
                                        </Typography>
                                    </Tooltip>
                                </div>
                            </GridItem>

                            <GridItem xs={5} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Select
                                    style={{ width: "80%", height: "30px", marginTop: "-5px"  }}
                                    className={classes.selectMenuSort}
                                    value={gateway}
                                    disableUnderline
                                    classes={{
                                        root: classes.selectText,
                                    }}
                                    onChange={(e) => {
                                        setGateway(e.target.value.apiType)
                                    }}
                                    displayEmpty
                                    renderValue={(value) => {
                                        if (gateway === "") {
                                            return <p className={classes.selectPlaceHolder}>Gateways</p>;
                                        } else {
                                            return (
                                                <div style={{ display: "flex", gap: 1, alignItems: "center" }}>
                                                    {apiInfo.map((data, index) => (
                                                        data.apiType !== gateway ? null
                                                            :
                                                            <SvgIcon>
                                                                <CheckCircle
                                                                    style={
                                                                        data.status && data.status === "Active" ?
                                                                            {
                                                                                color: "#56CA93"
                                                                            }
                                                                            :
                                                                            data.status && data.status === "Pending" ?
                                                                                {
                                                                                    color: "#FECD2C"
                                                                                }
                                                                                :
                                                                                {
                                                                                    color: "#DADADA"
                                                                                }

                                                                    }
                                                                />
                                                            </SvgIcon>
                                                    ))}
                                                    {value}
                                                </div>
                                            )
                                        }
                                    }}
                                >
                                    <MenuItem
                                        disabled
                                        value=""
                                        classes={{
                                            root: classes.selectPlaceHolder,
                                        }}
                                    >
                                        <p
                                        >
                                            Gateways
                                        </p>
                                    </MenuItem>
                                    {apiInfo.map((o, i) => {
                                        return (
                                            <MenuItem
                                                key={o.id}
                                                value={o}
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                }}
                                            >
                                                <CheckCircle
                                                    style={
                                                        apiInfo[i] === undefined ?
                                                            {
                                                                color: "#DADADA"

                                                            }
                                                            :
                                                            apiInfo[i].status && apiInfo[i].status === "Active" ?
                                                                {
                                                                    color: "#56CA93"
                                                                }
                                                                :
                                                                apiInfo[i].status && apiInfo[i].status === "Pending" ?
                                                                    {
                                                                        color: "#FECD2C"
                                                                    }
                                                                    :
                                                                    {
                                                                        color: "#DADADA"
                                                                    }

                                                    }
                                                />
                                                {o.apiType}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </GridItem>

                            {apiInfo.map((data, index) => (
                                data.apiType === gateway && index === data.id?
                                    <>
                                        <GridItem xs={12} key={index}>
                                            <GridContainer>
                                                <GridItem xs={6}>
                                                    <Typography variant="h2" className={classes.cardDesc}>
                                                        API Key/Username
                                                    </Typography>
                                                </GridItem>

                                                <GridItem xs={6}>
                                                    {globalEdit?
                                                        <>
                                                            <TextField
                                                                className={classes.userInfoTextField}
                                                                value={data.apiKey}
                                                                onChange={(e) => {
                                                                    e.persist()
                                                                    setApiInfo((prev) => {
                                                                        return prev.map((item) => {
                                                                            if (item.id === index) {
                                                                                return { ...item, apiKey: e.target.value }
                                                                            } else {
                                                                                return item
                                                                            }
                                                                        })
                                                                    })
                                                                }}
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    classes: {
                                                                        adornedEnd: classes.adournedEnd,
                                                                        input: classes.input
                                                                    },
                                                                }}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            <Typography variant="h2" className={classes.cardDescLessWeight}>
                                                                {data.apiKey ? maskDetails(data.apiKey) : "N/A"}
                                                            </Typography>
                                                        </>
                                                    }
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>

                                        <GridItem xs={12}>
                                            <GridContainer>
                                                <GridItem xs={6}>
                                                    <Typography variant="h2" className={classes.cardDesc}>
                                                        API Secret
                                                    </Typography>
                                                </GridItem>

                                                <GridItem xs={6}>
                                                    {globalEdit?
                                                        <>
                                                            <TextField
                                                                className={classes.userInfoTextField}
                                                                value={data.apiSecret}
                                                                onChange={(e) => {
                                                                    e.persist()
                                                                    setApiInfo((prev) => {
                                                                        return prev.map((item) => {
                                                                            if (item.id === index) {
                                                                                return { ...item, apiSecret: e.target.value }
                                                                            } else {
                                                                                return item
                                                                            }
                                                                        })
                                                                    })
                                                                }}
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    classes: {
                                                                        adornedEnd: classes.adournedEnd,
                                                                        input: classes.input
                                                                    },
                                                                }}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            <Typography variant="h2" className={classes.cardDescLessWeight}>
                                                                {data.apiSecret ? maskDetails(data.apiSecret) : "N/A"}
                                                            </Typography>
                                                        </>
                                                    }
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                    </>
                                    :
                                    null
                            ))}
                        </GridContainer>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(profileStyle)
)(GatewayPage));