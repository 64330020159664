import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

//styling
import Style from 'assets/jss/material-dashboard-pro-react/views/ordersStyle';
import withStyles from '@material-ui/core/styles/withStyles';

//icons
import { ReactComponent as NoRewardsIcon } from "assets/img/tuzo/icons/NoRewardsIcon.svg";
import { AiFillGift } from 'react-icons/ai';

//components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Button, Typography } from '@material-ui/core';
import Card from 'components/Card/Card';

export function NoOrdersPage(props) {
    const {
        classes,
        history
    } = props

    return (
        <div>
            <GridContainer>
                <GridItem xs={12}>
                    <Card className={classes.noOrdersCard}>
                        <NoRewardsIcon />

                        <Typography className={classes.noOrdersTitle}>
                            No orders? No problem.
                        </Typography>


                        <Typography className={classes.noOrdersNotBold}>
                            Looks like you have no orders to display at the moment, or maybe you've not made any yet. But don't let your hard work go unrewarded. It's time to treat yourself!
                        </Typography>

                        <Button
                            className={classes.noOrdersButton}
                            onClick={() => history.push(
                                '/merchant/rewards',
                                {

                                    filterName: "All"

                                }
                            )}
                        // onClick={() => handleButtonFilter("All")}
                        >
                            <AiFillGift className={classes.icon} style={{ marginRight: "10px" }} /> Browse All Rewards
                        </Button>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(NoOrdersPage));