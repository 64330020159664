/*eslint-disable*/
import { db } from 'firebase/client';

export const getNotifications = (uid) => {
    return (dispatch) => {
      return db
        .collection('users').doc(uid).collection('notifications').get()
        .then((snapshot) => {
          if (snapshot.empty) {
            dispatch(setNotifications([]))
          } else {
            snapshot = snapshot.docs
            var notifications = []
            snapshot.forEach((doc) => {
                if(!doc.data().read){
                  notifications.push(doc.data())
                }
                
            })
            dispatch(setNotifications(notifications))
          }
        })
    }
  }


export const setNotifications = (notifications) => {
    return {
      type: "SET_NOTIFICATIONS",
      notifications
    }
  }