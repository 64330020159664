import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import loadingWheel from "assets/img/Tuzo-Preloader-Transparent.gif";

const DialogLoader = ({ showModal }) => {
  return (
    <Dialog
      open={showModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <DialogContent
        id="alert-dialog-description"
        style={{ textAlign: "center", padding: "10px" }}
      >
        <center>
          <img
            style={{
              width: "110px",
              height: "110px",
              filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.7))",
            }}
            src={loadingWheel}
            alt="Loading..."
          />
        </center>
      </DialogContent>
    </Dialog>
  );
};

export default DialogLoader;
