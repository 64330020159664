import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDropzone } from 'react-dropzone';
import Dropzone from 'react-dropzone'
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Button, Card, IconButton, Hidden, TextField } from '@material-ui/core';

// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/rewardsStyle';
import withStyles from '@material-ui/core/styles/withStyles';

// icons
import { ReactComponent as Add } from "assets/img/tuzo/icons/AddCircle.svg";
import { BsDownload } from "react-icons/bs";
import { Close } from '@material-ui/icons';

export function RoomOptions(props) {
    const {
        classes,
        history,
        roomInfo,
        setRoomInfo,
        addRoom,
        getDownloadURL,
        getRoomMainImgUrl
    } = props;

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        maxFiles: 1
    });

    const removeImage = (imageIndex, arrIndex) => {
        setRoomInfo((prevValue) => {
            return prevValue.map((item) => {
                if (item.id === arrIndex) {
                    const newArr = [...item.roomGallery]
                    const urlArr = [...item.galleryUrl]
                    newArr.splice(imageIndex, 1)
                    urlArr.splice(imageIndex, 1)
                    return { ...item, roomGallery: newArr, galleryUrl: urlArr };
                } else {
                    return item;
                }
            });
        });
    }
    return (
        <div>
            <Hidden smDown implementation="css">
                <Card className={classes.card} style={{ marginTop: "15px" }}>
                    <GridContainer>
                        <GridItem xs={12} style={{ display: "flex", alignItems: "center" }}>
                            <h5 className={classes.cardHeader}>Room Options</h5>

                            <Button className={classes.addRoomButton} onClick={() => addRoom()}>
                                <Add style={{ marginRight: "10px" }} /> Add Room Options
                            </Button>
                        </GridItem>
                    </GridContainer>
                    {roomInfo.map((data, index) => (
                        <div style={{ borderBottom: "1px solid #1A405F", marginTop: "10px" }} key={index}>
                        <GridContainer key={index}>
                                <GridItem xs={6}>
                                    <GridContainer>
                                        <GridItem xs={6}>
                                            <p className={classes.roomLabel}>
                                                Room Name
                                            </p>
                                        </GridItem>

                                        <GridItem xs={6}>
                                            <TextField
                                                className={classes.roomTextField}
                                                value={data.roomName}
                                                onChange={(e) => {
                                                    setRoomInfo((prevValue) => {
                                                        return prevValue.map((item) => {
                                                            if (item.id === index) {
                                                                return { ...item, roomName: e.target.value };
                                                            } else {
                                                                return item;
                                                            }
                                                        });
                                                    });
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        adornedEnd: classes.adournedEnd,
                                                        input: classes.input
                                                    },
                                                }}
                                            />
                                        </GridItem>

                                        <GridItem xs={12}>
                                            <p className={classes.roomLabel} >
                                                Room Description
                                            </p>
                                        </GridItem>

                                        <GridItem xs={12}>
                                            <div style={{ border: "2px solid #56CA93" }}>
                                                <Editor
                                                    editorState={data.roomDescription}
                                                    onEditorStateChange={(state) => {
                                                        setRoomInfo((prevValue) => {
                                                            return prevValue.map((item) => {
                                                                if (item.id === index) {
                                                                    return { ...item, roomDescription: state };
                                                                } else {
                                                                    return item;
                                                                }
                                                            });
                                                        });
                                                    }}
                                                    wrapperClassName="wrapper-class"
                                                    editorClassName="editor-class"
                                                    toolbarClassName="toolbar-class"
                                                />
                                            </div>
                                        </GridItem>

                                        <GridItem xs={6}>
                                            <p className={classes.roomLabel}>
                                                Nightly Price ($)
                                            </p>
                                        </GridItem>

                                        <GridItem xs={6}>
                                            <TextField
                                                type="number"
                                                className={classes.roomTextField}
                                                value={data.nightlyPrice}
                                                onChange={(e) => {
                                                    setRoomInfo((prevValue) => {
                                                        return prevValue.map((item) => {
                                                            if (item.id === index) {
                                                                return { ...item, nightlyPrice: e.target.value, nightlyPricePts: e.target.value * 100 };
                                                            } else {
                                                                return item;
                                                            }
                                                        });
                                                    });
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        adornedEnd: classes.adournedEnd,
                                                        input: classes.input
                                                    },
                                                    inputProps: {
                                                        min: 0,
                                                    }
                                                }}
                                            />
                                        </GridItem>

                                        <GridItem xs={6}>
                                            <p className={classes.roomLabel}>
                                                Points Equivalent
                                            </p>
                                        </GridItem>

                                        <GridItem xs={6}>
                                            <p className={classes.ptsLabel} >
                                                {data.nightlyPricePts ? (data.nightlyPricePts).toLocaleString() : ""}
                                            </p>
                                        </GridItem>

                                        <GridItem xs={6}>
                                            <p className={classes.roomLabel}>
                                                Sale Price ($)
                                            </p>
                                        </GridItem>

                                        <GridItem xs={6}>
                                            <TextField
                                                type="number"
                                                className={classes.roomTextField}
                                                value={data.salePrice}
                                                onChange={(e) => {
                                                    setRoomInfo((prevValue) => {
                                                        return prevValue.map((item) => {
                                                            if (item.id === index) {
                                                                return { ...item, salePrice: e.target.value, salePricePts: e.target.value * 100 };
                                                            } else {
                                                                return item;
                                                            }
                                                        });
                                                    });
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        adornedEnd: classes.adournedEnd,
                                                        input: classes.input
                                                    },
                                                    inputProps: {
                                                        min: 0,
                                                    }
                                                }}
                                            />


                                        </GridItem>

                                        <GridItem xs={6}>
                                            <p className={classes.roomLabel}>
                                                Points Equivalent
                                            </p>
                                        </GridItem>

                                        <GridItem xs={6}>
                                            <p className={classes.ptsLabel} >
                                                {data.salePricePts ? (data.salePricePts).toLocaleString() : ""}
                                            </p>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>

                                <GridItem xs={6}>
                                    <GridContainer>
                                        <GridItem xs={6}>
                                            <div className={classes.roomOptionBorder}>
                                                <GridContainer>
                                                    <GridItem xs={12}>
                                                        <h5 className={classes.roomOptionHeader}>Room Main Image</h5>
                                                    </GridItem>
                                                    <GridItem xs={12}>
                                                        <div style={{ marginLeft: "20px" }}>
                                                            <Dropzone
                                                                max={104857600}
                                                                multiple={false}
                                                                open
                                                                noClick={data.mainUrl.length === 0 || !data.mainUrl ? true : false}
                                                                accept=".jpeg, .png, .jpg"
                                                                onDrop={acceptedFiles => {
                                                                    var array = []
                                                                    Array.from(acceptedFiles).forEach(file => {
                                                                        array = [...array, file]
                                                                    });
                                                                    getRoomMainImgUrl(array, index)
                                                                }}>
                                                                {({ getRootProps, getInputProps, open }) => (
                                                                    <div {...getRootProps()}
                                                                        className={classes.imgSquare}>
                                                                        <input {...getInputProps()} />
                                                                        {data.mainUrl.length === 0 || !data.mainUrl ?
                                                                            <div style={{ textAlign: "center" }}>
                                                                                <p style={{ marginTop: "10px", fontSize: "18px" }}>
                                                                                    <BsDownload
                                                                                        style={{ color: "#56CA93", fontSize: "50px", marginBottom: "-10px" }} />
                                                                                    {"  "}
                                                                                </p>
                                                                                <p>
                                                                                    Drop main reward image files here or  click “Set main image” below
                                                                                </p>
                                                                            </div>
                                                                            :
                                                                            // typeof (data.mainUrl) === "string" ?
                                                                            <img
                                                                                // key={i}
                                                                                style={{ height: "100px", width: '150px', marginTop: "20px", objectFit: "contain" }}
                                                                                src={data.mainUrl}
                                                                            />
                                                                            // :
                                                                            // data.roomMainImg.map((f, i) => {
                                                                            //     return (
                                                                            //         <img
                                                                            //             key={i}
                                                                            //             style={{ height: "100px", width: '150px', marginTop: "20px" }}
                                                                            //             src={URL.createObjectURL(f)}
                                                                            //         />
                                                                            //     )
                                                                            // })
                                                                        }
                                                                        {data.mainUrl.length === 0 || !data.mainUrl ?
                                                                            <p
                                                                                style={{ textDecoration: "underline", cursor: "pointer" }}
                                                                                className={classes.imgDescription}
                                                                                onClick={open}
                                                                            >
                                                                                Set main image
                                                                            </p>
                                                                            :
                                                                            null
                                                                        }

                                                                    </div>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                    </GridItem>
                                                    {data.mainUrl.length === 0 || !data.mainUrl ?
                                                        <>
                                                            <GridItem style={{ marginTop: "15px" }} xs={12}>
                                                                <p
                                                                    style={{ fontSize: "14px" }}
                                                                    className={classes.imgDescription}
                                                                >
                                                                    For best results, upload JPEG or PNG files that are
                                                                    1000 by 1000 pixels or larger.
                                                                    Maximum upload file size: 100 MB.
                                                                </p>
                                                            </GridItem>
                                                        </>
                                                        :
                                                        <>
                                                            <GridItem style={{ marginTop: "15px" }} xs={12}>
                                                                <p
                                                                    className={classes.imgDescription}
                                                                >
                                                                    Click the image to update
                                                                </p>
                                                                <p
                                                                    style={{
                                                                        textDecoration: "underline",
                                                                        cursor: "pointer"
                                                                    }}
                                                                    className={classes.imgDescription}
                                                                    onClick={() => {
                                                                        setRoomInfo((prevValue) => {
                                                                            return prevValue.map((item) => {
                                                                                if (item.id === index) {
                                                                                    return { ...item, roomMainImg: [], mainUrl: "" };
                                                                                } else {
                                                                                    return item;
                                                                                }
                                                                            });
                                                                        });
                                                                    }}

                                                                >
                                                                    Remove main image
                                                                </p>
                                                            </GridItem>
                                                        </>
                                                    }
                                                </GridContainer>
                                            </div>
                                        </GridItem>

                                        <GridItem xs={6}>
                                            <div className={classes.roomOptionBorder}>
                                                <GridContainer>
                                                    <GridItem xs={12}>
                                                        <h5 className={classes.roomOptionHeader}>Room Image Gallery</h5>
                                                    </GridItem>
                                                    <GridItem xs={12}>
                                                        <div style={{ marginLeft: "20px" }}>
                                                            <Dropzone
                                                                multiple
                                                                open
                                                                noClick
                                                                accept=".jpeg, .png, .jpg"
                                                                onDrop={acceptedFiles => {
                                                                    var array = [...data.roomGallery]
                                                                    Array.from(acceptedFiles).forEach(file => {
                                                                        if (!array.includes(file)) {
                                                                            array.push(file)
                                                                        }

                                                                    });
                                                                    getDownloadURL(array, index)
                                                                }}>
                                                                {({ getRootProps, getInputProps, open }) => (
                                                                    <div {...getRootProps()}
                                                                        className={classes.imgSquare}>
                                                                        <input {...getInputProps()} />
                                                                        {data.roomGallery.length === 0 ?
                                                                            <div style={{ textAlign: "center" }}>
                                                                                <p style={{ marginTop: "10px", fontSize: "18px" }}>
                                                                                    <BsDownload
                                                                                        style={{ color: "#56CA93", fontSize: "50px", marginBottom: "-10px" }} />
                                                                                    {"  "}
                                                                                </p>
                                                                                <p>
                                                                                    Drop reward image files here or click  “Add room gallery images” below
                                                                                </p>
                                                                            </div>
                                                                            :
                                                                            <GridContainer >
                                                                                {data.roomGallery.map((f, i) => {
                                                                                    return (
                                                                                        <>
                                                                                            <GridItem xs={6} style={{ display: "flex" }} key={i} >
                                                                                                <div>
                                                                                                    <IconButton
                                                                                                        style={{ width: "14px", height: "21px", color: "#56CA93" }}
                                                                                                        onClick={() => removeImage(i, index)}
                                                                                                    >
                                                                                                        <Close />
                                                                                                    </IconButton>
                                                                                                    <img
                                                                                                        key={i}
                                                                                                        style={{ height: "50px", width: '50px', margin: "5px" }}
                                                                                                        src={typeof(f) === "string" ? f : URL.createObjectURL(f)}
                                                                                                    />
                                                                                                </div>
                                                                                            </GridItem>
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </GridContainer>
                                                                        }
                                                                        <p
                                                                            style={{ textDecoration: "underline", cursor: "pointer" }}
                                                                            className={classes.imgDescription}
                                                                            onClick={open}
                                                                        >
                                                                            Add gallery images
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                    </GridItem>
                                                    {data.roomGallery.length === 0 ?
                                                        <>
                                                            <GridItem style={{ marginTop: "15px" }} xs={12}>
                                                                <p
                                                                    style={{ fontSize: "14px" }}
                                                                    className={classes.imgDescription}
                                                                >
                                                                    For best results, upload JPEG or PNG files that are
                                                                    1000 by 1000 pixels or larger.
                                                                    Maximum upload file size: 100 MB.
                                                                </p>
                                                            </GridItem>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                </GridContainer>
                                            </div>
                                        </GridItem>

                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </div>
                    ))}
                </Card>
            </Hidden>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        rewards: state.rewards.rewards
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(RoomOptions);