import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import ReactPaginate from "react-paginate";
import "./pagination.css";
import moment from "moment";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import "../../batch/components/table.css";
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

// mui core
import { MenuItem, Select } from "@material-ui/core";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// styling
import withStyles from "@material-ui/core/styles/withStyles";
import reportsStyle from 'assets/jss/material-dashboard-pro-react/views/reportsStyle';

// icons
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import { zip } from "lodash";

function BatchDetailsTable(props) {
    const {
        classes,
        reservations,
        history,
        detailsData,
        pageNumber,
        setPageNumber,
        index,
        setIndex,
        endIndex,
        setEndIndex,
        itemsPerPage,
        headers,
        points
    } = props;

    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
        setIndex(selected * itemsPerPage);
        setEndIndex(selected * itemsPerPage + itemsPerPage);
    };

    useEffect(() => {
        detailsData.slice(index, endIndex);
    }, [index]);
    const renderTableHeader = () => {
        return (
            <Tr
                // className={classes.tableRow}
                style={{
                    height: "38px",
                    border: "1px solid #8E8E8E",
                }}
            >
                {headers.map((key, index) => {
                    return (
                        <Th className={classes.tableHeaders} key={index}>
                            {key.label}
                        </Th>
                    );
                })}
            </Tr>
        );
    };

    const renderTableData = () => {
        let data = zip(detailsData, points)
        return data
            .slice(
                pageNumber * itemsPerPage,
                pageNumber * itemsPerPage + itemsPerPage
            )
            .map((d, index) => {
                return (
                    <Tr
                        key={index}
                    >
                        <Td className={classes.tableCell} style={{ textAlign: "left" }}>{d[0].cardDescription} Ending in {d[0].cardNumber.split('x')[4]}</Td>
                        <Td className={classes.tableCell}>{d[0].authorizationNumber}</Td>
                        <Td className={classes.tableCell}>
                            {d[0].transactionTime.nil ? moment(d[0].reportDate).format("MM/DD/YYYY") :
                                moment(d[0].reportDate).format("MM/DD/YYYY") + " " + moment(d[0].transactionTime, "hh:mm:ss").format("hh:mm A")}
                        </Td>
                        <Td className={classes.tableCell}>${Number(d[0].transactionAmount).toFixed(2)}</Td>
                        <Td className={classes.tableCell} style={{ fontWeight: 700 }}> {d[1] === 0 ? "< 1" : d[1]}</Td>
                    </Tr>
                );
            });
    };

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Table className={classes.reportsTable} style={{ marginBottom: "10px", marginTop: "10px" }}>
                    <Thead>
                        {renderTableHeader()}
                    </Thead>
                    <Tbody>
                        {renderTableData()}
                    </Tbody>
                </Table>
                <GridContainer>
                    <GridItem xs={8} style={{ marginTop: "5px", display: "flex", justifyContent: "flex-end" }}>
                        <h5
                            className={classes.indexes}
                        >
                            {index + 1} - {endIndex} of{" "}
                            {Math.ceil(detailsData.length)}
                        </h5>
                    </GridItem>
                    <GridItem xs={4}>
                        <ReactPaginate
                            paginationSize={0}
                            onPageChange={handlePageClick}
                            pageCount={Math.ceil(detailsData.length / itemsPerPage)}
                            nextLabel={
                                <ArrowRight
                                    style={{
                                        fontSize: 25,
                                        fontWeight: "bold",
                                        color: "white",
                                    }}
                                />
                            }
                            previousLabel={
                                <ArrowLeft
                                    style={{
                                        fontSize: 25,
                                        fontWeight: "bold",
                                        color: "white",
                                    }}
                                />
                            }
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />
                    </GridItem>
                </GridContainer>

            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(reportsStyle)
)(React.memo(BatchDetailsTable));
