import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { removeProduct } from 'store/actions/cart';
// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/cartStyle';
import withStyles from '@material-ui/core/styles/withStyles';

// icons
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";


// core components
import { Button, Checkbox, FormControlLabel, Hidden, Typography } from "@material-ui/core";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import { removeAll } from 'store/actions/cart';


export function ItemInfo(props) {
    const {
        classes,
        cart,
        totalAmount,
        removeProduct,
        removeAll,
        numberFormatterDecimals,
        handleCheck,
        deselectAll,
        history,
        user
    } = props;

    return (
        <div>
            <Hidden mdUp implementation='css'>
                <Card className={classes.card}>
                    <GridContainer style={{ alignItems: "center" }}>
                        <GridItem xs={12}>
                            <Typography className={classes.totalCost}>
                                Total ({cart.length} items): {(totalAmount * 100).toLocaleString()} points
                            </Typography>
                        </GridItem>

                        <GridItem xs={12}>
                            <Button

                                className={classes.deselectButton}
                                onClick={() => removeAll(cart)}>
                                <TrashIcon style={{ marginRight: "10px" }} />  Remove All Rewards
                            </Button>
                        </GridItem>
                    </GridContainer>

                    {cart.map((data, index) => (
                        <div className={classes.itemBorderDiv} key={index}>
                            <GridContainer>
                                <GridItem xs={2}>
                                    <FormControlLabel
                                        className={classes.checkboxes}
                                        control={
                                            <Checkbox
                                                style={{ color: "#56CA93" }}
                                                checked={data.checked}
                                                onClick={() => handleCheck(index)}
                                            />
                                        }
                                        value={data.rewardName}
                                    />
                                </GridItem>

                                <GridItem xs={10}>
                                    <img
                                        className={classes.mainImg}
                                        src={data.mainImage}
                                    />
                                </GridItem>

                                <GridItem xs={12} >
                                    <Typography className={classes.cardSubtitle}
                                        onClick={() => history.push(
                                            `/merchant/reward-detail/${data.rewardId}`,
                                            {
                                                rewardData: data
                                            }
                                        )}
                                    >
                                        {data.rewardName}
                                    </Typography>

                                    <Typography className={classes.cardDescription}>
                                        {data.topDescription.length > 8 ? <span dangerouslySetInnerHTML={{ __html: data.topDescription }}></span> : "No Description"}
                                    </Typography>

                                    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                        <Typography
                                            className={classes.cardPolicy}
                                            onClick={() => removeProduct(index)}
                                        >
                                            Remove

                                        </Typography>

                                        <Typography
                                            className={classes.cardPolicy}
                                            onClick={() => {
                                                window.open("https://www.tuzorewards.com/contact/terms-of-use/",
                                                    '_blank',
                                                    'noopener,noreferrer'
                                                )
                                            }}
                                        >
                                            Rules, policies and cancellations
                                        </Typography>

                                        <Typography
                                            className={classes.cardPolicy}
                                            onClick={() => history.push(
                                                `/merchant/reward-detail/${data.rewardId}`,
                                                {
                                                    rewardData: data
                                                }
                                            )}
                                        >
                                            Details
                                        </Typography>
                                    </div>
                                </GridItem>

                                <GridItem xs={12} style={{ display: "flex", alignItems: "flex-end", flexDirection: "column" }}>
                                    {/* <Typography className={classes.totalCost}>
                                ${numberFormatterDecimals.format(data.salePrice)}
                            </Typography> */}

                                    <Typography className={classes.totalCostMarginTop}>
                                        {(data.salePricePoints).toLocaleString()} points
                                    </Typography>

                                    <Typography className={classes.cardDescriptionFees}>
                                        Includes {data.taxClass} tax/fees
                                    </Typography>
                                </GridItem>
                            </GridContainer>
                        </div>
                    ))}
                </Card>
            </Hidden>

            <Hidden smDown implementation='css'>
                <Card className={classes.card}>
                    <GridContainer style={{ alignItems: "center" }}>
                        <GridItem xs={6}>
                            <Typography className={classes.totalCost}>
                                Total ({cart.length} items): {(totalAmount * 100).toLocaleString()} points
                            </Typography>
                        </GridItem>

                        <GridItem xs={6}>
                            <Button className={classes.deselectButton} onClick={() => removeAll(cart)}>
                                <TrashIcon style={{ marginRight: "10px" }} />  Remove All Rewards
                            </Button>
                        </GridItem>
                    </GridContainer>

                    {cart.map((data, index) => (
                        <div className={classes.itemBorderDiv} key={index}>
                            <GridContainer>
                                <GridItem xs={1}>
                                    <FormControlLabel
                                        className={classes.checkboxes}
                                        control={
                                            <Checkbox
                                                style={{ color: "#56CA93" }}
                                                checked={data.checked}
                                                onClick={() => handleCheck(index)}
                                            />
                                        }
                                        value={data.rewardName}
                                    />
                                </GridItem>

                                <GridItem xs={2}>
                                    <img
                                        className={classes.mainImg}
                                        src={data.mainImage}
                                    />
                                </GridItem>

                                <GridItem xs={6}>
                                    <Typography
                                        className={classes.cardSubtitle}
                                        onClick={() => {
                                            if (user.IsoAdminCompanyName) {
                                                history.push(
                                                    `/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/reward-detail/${data.rewardId}`,
                                                    {
                                                        rewardData: data
                                                    }
                                                )
                                            }
                                            else {
                                                history.push(
                                                    `/merchant/reward-detail/${data.rewardId}`,
                                                    {
                                                        rewardData: data
                                                    }
                                                )
                                            }

                                        }}
                                    >
                                        {data.rewardName}
                                    </Typography>

                                    <Typography className={classes.cardDescription}>
                                        {data.topDescription.length > 8 ? <span dangerouslySetInnerHTML={{ __html: data.topDescription }}></span> : "No Description"}
                                    </Typography>

                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography
                                            className={classes.cardPolicy}
                                            onClick={() => removeProduct(index)}
                                        >
                                            Remove

                                        </Typography>

                                        <Typography
                                            className={classes.cardPolicy}
                                            onClick={() => {
                                                window.open("https://www.tuzorewards.com/contact/terms-of-use/",
                                                    '_blank',
                                                    'noopener,noreferrer'
                                                )
                                            }}
                                        >
                                            Rules, policies and cancellations
                                        </Typography>

                                        <Typography
                                            className={classes.cardPolicy}
                                            onClick={() => {
                                                if (user.IsoAdminCompanyName) {
                                                    history.push(
                                                        `/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/reward-detail/${data.rewardId}`,
                                                        {
                                                            rewardData: data
                                                        }
                                                    )
                                                }
                                                else {
                                                    history.push(
                                                        `/merchant/reward-detail/${data.rewardId}`,
                                                        {
                                                            rewardData: data
                                                        }
                                                    )
                                                }
    
                                            }}
                                        >
                                            Details
                                        </Typography>
                                    </div>
                                </GridItem>

                                <GridItem xs={3} style={{ display: "flex", alignItems: "flex-end", flexDirection: "column" }}>
                                    <Typography className={classes.totalCostMarginTop}>
                                        {(data.salePricePoints).toLocaleString()} points
                                    </Typography>

                                    <Typography className={classes.cardDescriptionFees}>
                                        Quantity: {data.quantity ? data.quantity : 1}
                                    </Typography>

                                    <Typography className={classes.cardDescriptionFees}>
                                        Includes {data.taxClass} tax/fees
                                    </Typography>
                                </GridItem>
                            </GridContainer>
                        </div>
                    ))}
                </Card>
            </Hidden>
        </div>

    )
}

function mapStateToProps(state) {
    return {
        cart: state.cart.cart,
        user: state.auth.user,
        globalCalculator: state.search.globalCalculatorOpen
    };
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({
        removeProduct: (index) => dispatch(removeProduct(index)),
        removeAll: (arr) => dispatch(removeAll(arr))
    });
}


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(ItemInfo);