import React, { useState } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CardList from "./CardList";
import Calculator from "./Calculator";
import CardListMobile from "./CardListMobile";
// styling
import Style from "assets/jss/material-dashboard-pro-react/views/calculatorStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import tuzoBg from "assets/img/tuzo/carouselBackground.png";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Button, Hidden, Typography } from "@material-ui/core";

// icons
import { MdOutlineEdit } from "react-icons/md";
import { ArrowBackIos, CheckCircle } from "@material-ui/icons";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Loader from "components/Loader/Loader";

export function Preview(props) {
  const { classes, history, grossAmt, points, arr, timesRan } = props;
  const [goBack, setGoBack] = useState(false);

  if (goBack) {
    return <Calculator />;
  }

  const getBgImage = () => {
    return tuzoBg;
  };

  if (!arr) return <Loader />;

  return (
    <div>
      <Hidden mdUp implementation="css">
        <div className={classes.calculatorCardIsoAdminMobile}>
          <div>
            <GridContainer style={{ padding: "30px" }}>
              <GridItem xs={12}>
                <Typography
                  variant="h2"
                  className={classes.bannerDescTopMobile}
                >
                  Based on your average monthly processing $
                  {grossAmt.toLocaleString()}, you’re on track to earn:
                </Typography>
              </GridItem>
            </GridContainer>
          </div>
          <div
            style={{
              backgroundImage: "url(" + getBgImage() + ")",
              width: "100vw",
              height: "170px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#274b73",
              backgroundPhoto: "repeat",
              backgroundSize: "cover",
              padding: "20px",
            }}
          >
            <GridContainer style={{ alignItems: "center" }}>
              <GridItem xs={8} className={classes.gridBannerMobile}>
                <Typography variant="h2" className={classes.bannerDesc}>
                  Monthly:
                </Typography>
              </GridItem>

              <GridItem xs={4} className={classes.gridBannerMobile}>
                <Typography className={classes.bannerPtsMobile}>
                  {points.toLocaleString()}
                </Typography>
              </GridItem>

              <GridItem xs={8} className={classes.gridBannerMobile}>
                <Typography variant="h2" className={classes.bannerDesc}>
                  Yearly:
                </Typography>
              </GridItem>

              <GridItem xs={4} className={classes.gridBannerMobile}>
                <Typography className={classes.bannerPtsMobile}>
                  {(points * 12).toLocaleString()}
                </Typography>
              </GridItem>

              <GridItem xs={8} className={classes.gridBannerMobile}>
                <Typography variant="h2" className={classes.bannerDesc}>
                  In 3 Years:
                </Typography>
              </GridItem>
              <GridItem xs={4} className={classes.gridBannerMobile}>
                <Typography className={classes.bannerPtsMobile}>
                  {(points * 36).toLocaleString()}
                </Typography>
              </GridItem>
            </GridContainer>
          </div>

          <GridContainer style={{ padding: "30px" }}>
            <GridItem xs={12}>
              <Typography
                variant="h2"
                className={classes.bannerDescTopMobile}
                style={{ paddingTop: "15px" }}
              >
                {timesRan < 3
                  ? "Within a year, you could redeem all of these rewards:"
                  : "Within the next few years, you could redeem all of these rewards:"}
              </Typography>
            </GridItem>

            <GridItem xs={12}>
              <CardListMobile arr={arr} />
            </GridItem>

            <GridItem xs={12}>
              <Typography variant="h2" className={classes.bannerDescTopMobile}>
                Take a trip, pamper yourself, or buy that luxury item you've
                been eyeing for ages. Do it today, because
                <span style={{ color: "#FECD2C" }}> it’s on us!</span>
              </Typography>
            </GridItem>
          </GridContainer>

          <div style={{ padding: "0px 30px 30px 30px" }}>
              <Link to="/admin/user-page"target="_blank" rel="noopener noreferrer">
                  <Button
                      style={{
                          background: "#E2A400",
                      }}
                      className={classes.previewPageButtonMobile}
                  >
                      <ArrowBackIos style={{ marginRight: "10px", marginTop: "-3px" }} /> Go to App
                  </Button>
              </Link>
              <Button
                  className={classes.previewPageButtonMobile}
                  style={{
                      // width: "185px",
                      marginRight: "15px"
                  }}
                  onClick={() => setGoBack(true)}
              >
                  <MdOutlineEdit
                      style={{
                          height: "22px",
                          width: "22px",
                          marginRight: "15px"
                      }} />
                  Edit Amount
              </Button>

              {/* <Button
                  className={classes.previewPageButtonMobile}
                  style={{
                      background: "#56CA93",
                      // width: "165px",
                  }}
                  onClick={() => history.push('/auth/register-page')}
              >
                  <CheckCircle
                      style={{
                          height: "25px",
                          width: "25px",
                          marginRight: "15px"
                      }}
                  />
                  Sign Up Now
              </Button> */}
            </div>
        </div>
      </Hidden>

      <Hidden smDown implementation="css">
        <div className={classes.calculatorCardIsoAdmin}>
          <div>
            <GridContainer style={{ padding: "30px" }}>
              <GridItem xs={12}>
                <Typography variant="h2" className={classes.bannerDescTop}>
                  Based on your average monthly processing $
                  {grossAmt.toLocaleString()}, you’re on track to earn:
                </Typography>
              </GridItem>
            </GridContainer>
          </div>
          <div
            style={{
              backgroundImage: "url(" + getBgImage() + ")",
              width: "100vw",
              height: "130px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#274b73",
              backgroundPhoto: "repeat",
              backgroundSize: "cover",
            }}
          >
            <GridContainer style={{ alignItems: "center" }}>
              <GridItem xs={4} className={classes.gridBanner}>
                <Typography variant="h2" className={classes.bannerDesc}>
                  Monthly:
                </Typography>
                <Typography
                  style={{ fontSize: "28px", lineHeight: "27px" }}
                  className={classes.bannerPts}
                >
                  {points.toLocaleString()}
                </Typography>
              </GridItem>

              <GridItem xs={4} className={classes.gridBanner}>
                <Typography variant="h2" className={classes.bannerDesc}>
                  Yearly:
                </Typography>
                <Typography
                  style={{ fontSize: "28px", lineHeight: "27px" }}
                  className={classes.bannerPts}
                >
                  {(points * 12).toLocaleString()}
                </Typography>
              </GridItem>

              <GridItem xs={4} className={classes.gridBanner}>
                <Typography variant="h2" className={classes.bannerDesc}>
                  In 3 Years:
                </Typography>
                <Typography
                  style={{ fontSize: "28px", lineHeight: "27px" }}
                  className={classes.bannerPts}
                >
                  {(points * 36).toLocaleString()}
                </Typography>
              </GridItem>
            </GridContainer>
          </div>

          <GridContainer style={{ padding: "0px 30px" }}>
            <GridItem xs={12}>
              <Typography
                variant="h2"
                className={classes.bannerDescTop}
                style={{ paddingTop: "15px" }}
              >
                {timesRan < 3
                  ? "Within a year, you could redeem all of these rewards:"
                  : "Within the next few years, you could redeem all of these rewards:"}
              </Typography>
            </GridItem>

            <GridItem xs={12}>
              <CardList arr={arr} />
            </GridItem>

            <GridItem xs={12}>
              <Typography
                variant="h2"
                className={classes.bannerDescTop}
                style={{ textAlign: "center" }}
              >
                Take a trip, pamper yourself, or buy that luxury item you've
                been eyeing for ages. Do it today, because
                <span style={{ color: "#FECD2C" }}> it’s on us!</span>
              </Typography>
            </GridItem>
          </GridContainer>
          <div style={{ display: "flex", justifyContent: "space-between", padding: "30px" }}>
            <Button
                className={classes.addIdButton}
                style={{
                    width: "350px"
                }}
                onClick={() => setGoBack(true)}
            >
                <MdOutlineEdit
                    style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "15px"
                    }} />
                Edit Monthly Processing Amount
            </Button>

            <Link to="/admin/user-page"target="_blank" rel="noopener noreferrer">
                <Button
                    className={classes.goBackButton}
                >
                    <ArrowBackIos style={{ marginRight: "10px", marginTop: "-3px" }} /> Go to App
                </Button>
            </Link>

            {/* <Button
                className={classes.addIdButton}
                style={{
                    width: "220px",
                    background: "#56CA93"
                }}
                onClick={() => history.push('/auth/register-page')}
            >
                <CheckCircle
                    style={{
                        height: "25px",
                        width: "25px",
                        marginRight: "15px"
                    }}
                />
                Sign Up Now
            </Button> */}
            </div>
        </div>
      </Hidden>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({});
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(Preview)
);
