/*eslint-disable*/
import React, { useEffect } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import Datetime from "react-datetime";

// mui core
import {
  Box,
  Button,
  Card,
  Dialog,
  InputAdornment,
  LinearProgress,
  TextField,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";

//components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// icons
import { ReactComponent as Upload } from "assets/img/tuzo/icons/Upload.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { ReactComponent as Edit } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as Add } from "assets/img/tuzo/icons/AddCircle.svg";
import { ReactComponent as CalendarIcon } from "assets/img/tuzo/icons/calendarIconWhite.svg";

//styling
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import avatar from "assets/img/default-avatar.png";
import { Close } from "@material-ui/icons";
import "../admincalendar.css";
import loadingWheel from "assets/img/Tuzo-Preloader-Transparent.gif";

export function UserInfo(props) {
  const {
    classes,
    history,
    user,
    profilePic,
    firstNameState,
    setFirstNameState,
    lastNameState,
    setLastNameState,
    companyState,
    setCompanyState,
    birthdayState,
    setBirthdayState,
    emailState,
    setEmailState,
    cellPhoneState,
    setCellPhoneState,
    secondaryPhoneState,
    setSecondaryPhoneState,
    shippingAddressState,
    setShippingAddressState,
    globalEdit,
    handleImageUpload,
    uploading,
    progress,
    deleteImage,
    formatPhoneNumber,
    confirmDelete,
    setConfirmDelete,
  } = props;

  const formatSecondary = (input) => {
    input = input.replace(/\D/g, "");

    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 10);

    // Based upon the length of the string, we add formatting as necessary
    var size = input.length;
    if (size == 0) {
      input = input;
    } else if (size < 4) {
      input = "(" + input;
    } else if (size < 7) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } else {
      input =
        "(" +
        input.substring(0, 3) +
        ") " +
        input.substring(3, 6) +
        "-" +
        input.substring(6, 10);
    }
    setSecondaryPhoneState(input);
  };

  const formatMain = (input) => {
    input = input.replace(/\D/g, "");

    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 10);

    // Based upon the length of the string, we add formatting as necessary
    var size = input.length;
    if (size == 0) {
      input = input;
    } else if (size < 4) {
      input = "(" + input;
    } else if (size < 7) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } else {
      input =
        "(" +
        input.substring(0, 3) +
        ") " +
        input.substring(3, 6) +
        "-" +
        input.substring(6, 10);
    }
    setCellPhoneState(input);
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={4} className={classes.tighterGrid}>
          <Card
            className={classes.profileCardStyling2}
            style={{ overflow: "visible" }}
          >
            <GridContainer>
              <GridItem xs={12}>
                <Typography variant="h2" className={classes.cardHeader}>
                  Personal Details
                </Typography>
              </GridItem>

              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={6}>
                    <Typography variant="h2" className={classes.cardDesc}>
                      Username
                    </Typography>
                  </GridItem>

                  <GridItem xs={6}>
                    <Typography
                      variant="h2"
                      className={classes.cardDescLessWeight}
                    >
                      {firstNameState ? firstNameState.toLowerCase() : ""}
                    </Typography>
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={6}>
                    <Typography variant="h2" className={classes.cardDesc}>
                      First Name
                    </Typography>
                  </GridItem>

                  <GridItem xs={6}>
                    {globalEdit ? (
                      <>
                        <TextField
                          className={classes.userInfoTextField}
                          value={firstNameState}
                          onChange={(e) => setFirstNameState(e.target.value)}
                          InputProps={{
                            disableUnderline: true,
                            classes: {
                              adornedEnd: classes.adournedEnd,
                              input: classes.input,
                            },
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="h2"
                          className={classes.cardDescLessWeight}
                        >
                          {firstNameState
                            ? firstNameState
                            : "Click Edit to Input Name"}
                        </Typography>
                      </>
                    )}
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={6}>
                    <Typography variant="h2" className={classes.cardDesc}>
                      Last Name
                    </Typography>
                  </GridItem>

                  <GridItem xs={6}>
                    {globalEdit ? (
                      <>
                        <TextField
                          className={classes.userInfoTextField}
                          value={lastNameState}
                          onChange={(e) => setLastNameState(e.target.value)}
                          InputProps={{
                            disableUnderline: true,
                            classes: {
                              adornedEnd: classes.adournedEnd,
                              input: classes.input,
                            },
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="h2"
                          className={classes.cardDescLessWeight}
                        >
                          {lastNameState
                            ? lastNameState
                            : "Click Edit to Input Name"}
                        </Typography>
                      </>
                    )}
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={6}>
                    <Typography variant="h2" className={classes.cardDesc}>
                      Company
                    </Typography>
                  </GridItem>

                  <GridItem xs={6}>
                    {globalEdit ? (
                      <>
                        <TextField
                          className={classes.userInfoTextField}
                          value={companyState}
                          onChange={(e) => setCompanyState(e.target.value)}
                          InputProps={{
                            disableUnderline: true,
                            classes: {
                              adornedEnd: classes.adournedEnd,
                              input: classes.input,
                            },
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="h2"
                          className={classes.cardDescLessWeight}
                        >
                          {companyState
                            ? companyState
                            : "Click Edit to Input Name"}
                        </Typography>
                      </>
                    )}
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={6}>
                    <Typography variant="h2" className={classes.cardDesc}>
                      Birthday
                    </Typography>
                  </GridItem>

                  <GridItem xs={6}>
                    {globalEdit ? (
                      <>
                        <Datetime
                          className="drop-down"
                          selected={birthdayState}
                          dateFormat="MM/DD/YY"
                          onChange={(date, e) => {
                            setBirthdayState(date.format("MM/DD/YY"));
                          }}
                          timeFormat={false}
                          closeOnSelect={true}
                          closeOnClickOutside
                          renderInput={(props, openCalendar, closeCalendar) => (
                            <div onClick={openCalendar}>
                              <TextField
                                style={{ paddingLeft: "0px" }}
                                className={classes.userInfoTextField}
                                value={birthdayState}
                                variant="outlined"
                                InputProps={{
                                  classes: {
                                    adornedEnd: classes.adournedEnd,
                                    input: classes.inputDatetime,
                                  },
                                  endAdornment: (
                                    <InputAdornment
                                      className={classes.adournedEnd}
                                      position="end"
                                    >
                                      <Button
                                        className={classes.datetimeButton}
                                      >
                                        <CalendarIcon
                                          className={classes.calendarIcon}
                                          onClick={openCalendar}
                                          style={{
                                            width: "15px",
                                            height: "15px",
                                          }}
                                        />
                                      </Button>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                          )}
                        />
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="h2"
                          className={classes.cardDescLessWeight}
                        >
                          {birthdayState
                            ? birthdayState
                            : "Click Edit to Input Birthday"}
                        </Typography>
                      </>
                    )}
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>

        <GridItem xs={4} className={classes.tighterGrid}>
          <Card
            className={classes.profileCardStyling2}
            style={{ height: "100%" }}
          >
            <GridContainer>
              <GridItem xs={12}>
                <Typography variant="h2" className={classes.cardHeader}>
                  Profile Picture
                </Typography>
              </GridItem>

              <GridItem xs={4} style={{ marginTop: "20px" }}>
                <div>
                  <img
                    className={classes.img}
                    src={profilePic ? profilePic : avatar}
                  />
                </div>
              </GridItem>

              <GridItem xs={8}>
                <Typography
                  variant="h2"
                  className={classes.cardDesc}
                  style={{ marginTop: "20px" }}
                >
                  Upload your photo (optional)
                </Typography>

                <Typography variant="h2" className={classes.cardDescLessWeight}>
                  Ideal profile images are cropped as a square and should be at
                  least 300px x 300px.
                </Typography>

                <div style={{ display: "flex" }}>
                  <Button className={classes.uploadButton} component="label">
                    <Upload style={{ marginRight: "10px" }} /> Upload
                    <input
                      type="file"
                      hidden
                      onChange={(event) => {
                        handleImageUpload(event, "modal");
                      }}
                    />
                  </Button>

                  <Button
                    className={classes.uploadButton}
                    style={{ background: "#56CA93" }}
                    onClick={() => setConfirmDelete(true)}
                  >
                    <TrashIcon style={{ marginRight: "10px" }} /> Delete
                  </Button>
                </div>

                {uploading ? (
                  <GridContainer
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "15px",
                    }}
                  >
                    <GridItem xs={10}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ width: "80%", mr: 1 }}>
                          <LinearProgress
                            variant="determinate"
                            value={Math.round(progress) * 100}
                          />
                        </Box>
                        <Box sx={{ minWidth: 35 }}>
                          <h6
                            style={{
                              color: "#1A405F",
                              fontSize: "22px",
                              fontFamily: "Josefin Sans",
                            }}
                          >
                            {Math.round(progress)}%
                          </h6>
                        </Box>
                      </Box>
                    </GridItem>
                    <GridItem xs={2}>
                      <img
                        style={{
                          width: "110px",
                          height: "110px",
                          filter:
                            "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.7))",
                        }}
                        src={loadingWheel}
                        alt="Loading..."
                      />
                    </GridItem>
                  </GridContainer>
                ) : null}
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>

        <GridItem xs={4}>
          <Card className={classes.profileCardStyling2}>
            <GridContainer>
              <GridItem xs={12}>
                <Typography variant="h2" className={classes.cardHeader}>
                  Contact Information
                </Typography>
              </GridItem>

              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={5}>
                    <Typography variant="h2" className={classes.cardDesc}>
                      Email (required)
                    </Typography>
                  </GridItem>

                  <GridItem xs={7}>
                    {globalEdit ? (
                      <>
                        <TextField
                          className={classes.userInfoTextField}
                          value={emailState}
                          onChange={(e) => setEmailState(e.target.value)}
                          InputProps={{
                            disableUnderline: true,
                            classes: {
                              adornedEnd: classes.adournedEnd,
                              input: classes.input,
                            },
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="h2"
                          className={classes.cardDescLessWeight}
                        >
                          {emailState
                            ? emailState
                            : "Click Edit to Input Email"}
                        </Typography>
                      </>
                    )}
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={5}>
                    <Typography variant="h2" className={classes.cardDesc}>
                      Phone Number
                    </Typography>
                  </GridItem>

                  <GridItem xs={7}>
                    {globalEdit ? (
                      <>
                        <TextField
                          className={classes.userInfoTextField}
                          value={cellPhoneState}
                          onChange={(e) => formatMain(e.target.value)}
                          InputProps={{
                            disableUnderline: true,
                            classes: {
                              adornedEnd: classes.adournedEnd,
                              input: classes.input,
                            },
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="h2"
                          className={classes.cardDescLessWeight}
                        >
                          {cellPhoneState
                            ? formatPhoneNumber(cellPhoneState)
                            : "Click Edit to Input Number"}
                        </Typography>
                      </>
                    )}
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={5}>
                    <Typography variant="h2" className={classes.cardDesc}>
                      Secondary Phone
                    </Typography>
                  </GridItem>

                  <GridItem xs={7}>
                    {globalEdit ? (
                      <>
                        <TextField
                          className={classes.userInfoTextField}
                          value={secondaryPhoneState}
                          onChange={(e) => formatSecondary(e.target.value)}
                          InputProps={{
                            disableUnderline: true,
                            classes: {
                              adornedEnd: classes.adournedEnd,
                              input: classes.input,
                            },
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="h2"
                          className={classes.cardDescLessWeight}
                        >
                          {secondaryPhoneState
                            ? formatPhoneNumber(secondaryPhoneState)
                            : "Click Edit to Input Number"}
                        </Typography>
                      </>
                    )}
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={5}>
                    <Typography variant="h2" className={classes.cardDesc}>
                      Shipping Address
                    </Typography>
                  </GridItem>

                  <GridItem xs={7}>
                    {globalEdit ? (
                      <>
                        <TextareaAutosize
                          style={{
                            height: "66px",
                            color: "#1A405F",
                            lineHeight: "24px",
                          }}
                          className={classes.userInfoTextField}
                          value={shippingAddressState}
                          onChange={(e) =>
                            setShippingAddressState(e.target.value)
                          }
                          InputProps={{
                            disableUnderline: true,
                            classes: {
                              adornedEnd: classes.adournedEnd,
                              input: classes.input,
                            },
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="h2"
                          className={classes.cardDescLessWeight}
                        >
                          {shippingAddressState
                            ? shippingAddressState
                            : "Click Edit to Input Address"}
                        </Typography>
                      </>
                    )}
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>

      <Dialog
        className={classes.requestModal}
        open={confirmDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Card className={classes.confirmModalCard}>
          <GridContainer>
            <GridItem
              xs={12}
              style={{ justifyContent: "center", display: "flex" }}
            >
              <h5 className={classes.cardHeader}>
                Are you sure you want to delete this photo?
              </h5>
            </GridItem>
            <GridItem xs={6}>
              <Button
                className={classes.uploadButton}
                onClick={() => setConfirmDelete(false)}
              >
                <Close style={{ marginRight: "10px" }} /> Cancel
              </Button>
            </GridItem>

            <GridItem
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className={classes.uploadButton}
                style={{ background: "#56CA93" }}
                onClick={() => deleteImage()}
              >
                <TrashIcon style={{ marginRight: "10px" }} /> Delete
              </Button>
            </GridItem>
          </GridContainer>
        </Card>
      </Dialog>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({});
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(profileStyle)
  )(UserInfo)
);
