import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

// core components
import GridContainer from 'components/Grid/GridContainer';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem';
import { Button, Card, Checkbox, FormControl, FormControlLabel, FormGroup, Hidden, MenuItem, Select, TextField } from '@material-ui/core';

// icons


// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/rewardsStyle';


const arr = [
    "Pool",
    "Housekeeping",
    "Gym",
    "Babysitting",
    "Parking Included",
    "Breakfast Included",
    "Room Service",
    "Soundproof Rooms",
    "Restuarant",
    "Free Wifi",
    "Extra beds/cribs",
    "Bar",
    "Air Conditioning",
    "24/7 Front Desk",
    "Shuttle Service",
    "Laundary Service",
    "Baggage Storage",
    "Business Services",
    "Other"
]


export function Amenities(props) {
    const
        { classes,
            history,
            handleAmenities,
            amenities,
            other,
            setOther,
        } = props;
    return (
        <div>
            <Hidden smDown implementation="css">
                <Card className={classes.card} style={{ marginTop: "15px" }}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <h5 className={classes.cardHeader}>Popular Amenities</h5>
                        </GridItem>

                        <GridItem xs={12} >
                            <FormControl component="fieldset" variant="standard">
                                <FormGroup>
                                    <GridContainer>
                                        {arr.map((data, index) => {
                                            return (
                                                data === "Other" ?
                                                    <GridItem xs={3} style={{ display: "flex" }}>
                                                        <FormControlLabel
                                                            key={index}
                                                            classes={{
                                                                label: classes.checkboxes
                                                            }}
                                                            control={
                                                                <>
                                                                    <Checkbox
                                                                        style={{ color: "#1A405F" }}
                                                                        checked={amenities.includes(data)}
                                                                        onChange={() => handleAmenities(data)}
                                                                    />

                                                                </>
                                                            }
                                                            value={data}
                                                        />
                                                        <TextField
                                                            disabled={!amenities.includes(data) ? true : false}
                                                            placeholder={data}
                                                            value={other}
                                                            onChange={(e) => {
                                                                setOther(e.target.value)
                                                            }}
                                                            className={classes.amenitiesTextField}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                classes: {
                                                                    adornedEnd: classes.adournedEnd,
                                                                    input: classes.inputAmenities
                                                                },
                                                            }}

                                                        />
                                                    </GridItem>
                                                    :
                                                    <GridItem xs={3} >
                                                        <FormControlLabel
                                                            key={index}
                                                            classes={{
                                                                label: classes.checkboxes
                                                            }}
                                                            control={
                                                                <>
                                                                    <Checkbox
                                                                        style={{ color: "#1A405F" }}
                                                                        checked={amenities.includes(data)}
                                                                        onChange={() => handleAmenities(data)}
                                                                    />

                                                                </>
                                                            }
                                                            value={data}
                                                            label={data}
                                                        />
                                                    </GridItem>
                                            )

                                        })}
                                    </GridContainer>
                                </FormGroup>
                            </FormControl>

                        </GridItem>
                    </GridContainer>
                </Card>
            </Hidden>
        </div>
    );
}
function mapStateToProps(state) {
    return {
        // nothing yet
    };
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}
export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Amenities));