import React from 'react';
import { useState } from 'react';
import DonutChart from 'react-donut-chart';
import Datetime from "react-datetime";

// core components
import Box from '@material-ui/core/Box';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import Button from 'components/CustomButtons/Button.js';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import actionsForNavigation from 'store/actions/navigation';
import AuthActions from 'store/actions/auth';
import Slide from '@material-ui/core/Slide';
import Hidden from "@material-ui/core/Hidden";
import { InputAdornment, MenuItem, Select, TextField, Typography } from '@material-ui/core';

// @material-ui/icons
import { ReactComponent as CalendarIcon } from "assets/img/tuzo/icons/calendarIconWhite.svg";

// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/ordersStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import "./calendar.css"

export function RewardsFilter(props) {
    const {
        classes,
        history,
        sortArr,
        searchValue,
        setSearchValue,
        sortValue,
        setSortValue,
        handleOrdersToggle,
        orderType,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        handleFilter
    } = props;


    return (
        <div style={{ marginTop: "10px" }}>
            <Hidden mdUp implementation='css'>
                <GridContainer>
                    <GridItem xs={12}>
                        <div className={classes.toggleDiv}>
                            <div
                                onClick={() => handleOrdersToggle("Completed")}
                                style={
                                    orderType === ""
                                        ? {
                                            background: "#1A405F",
                                            border: "2px solid #1A405F",
                                            borderTopLeftRadius: "3px",
                                            borderBottomLeftRadius: "3px",
                                            color: "white"
                                        }
                                        : {}
                                }
                                className={classes.toggleSelection}
                            >
                                Completed
                            </div>
                            <div
                                value="Past & Canceled"
                                onClick={() => handleOrdersToggle("Past & Canceled")}
                                style={
                                    orderType === "Past & Canceled"
                                        ? {
                                            background: "#1A405F",
                                            border: "2px solid #1A405F",
                                            // borderTopRightRadius: "4px",
                                            // borderBottomRightRadius: "4px",
                                            color: "white"
                                        }
                                        : {}
                                }
                                className={classes.toggleSelection}
                            >
                                Past & Canceled
                            </div>
                            <div
                                value="Pending"
                                onClick={() => handleOrdersToggle("Pending")}
                                style={
                                    orderType === "Pending"
                                        ? {
                                            background: "#1A405F",
                                            border: "2px solid #1A405F",
                                            borderTopLeftRadius: "4px",
                                            borderBottomLeftRadius: "4px",
                                            color: "white"
                                        }
                                        : {}
                                }
                                className={classes.toggleSelection}
                            >
                                Pending
                            </div>
                        </div>
                    </GridItem>

                    <GridItem xs={12} >
                        <Select
                            className={classes.selectMenuSortOrdersPageMobile}
                            value={sortValue}
                            disableUnderline
                            classes={{
                                root: classes.selectText,
                            }}
                            onChange={(e) => {
                                setSortValue(e.target.value)
                                handleFilter(e.target.value)
                            }}
                        >
                            {sortArr.map((o, i) => {
                                return (
                                    <MenuItem
                                        key={i}
                                        value={o}
                                        classes={{
                                            root: classes.selectMenuItem,
                                        }}
                                    >
                                        {o}

                                    </MenuItem>
                                );
                            })}
                        </Select>


                    </GridItem>

                    <GridItem xs={12}>
                        <Datetime
                            selected={fromDate}
                            dateFormat="MM/DD/YY"
                            onChange={(date, e) => {
                                setFromDate(date.format("MM/DD/YY"));
                            }}
                            timeFormat={false}
                            closeOnSelect={true}
                            closeOnClickOutside
                            renderInput={(props, openCalendar, closeCalendar) => (
                                <div onClick={openCalendar}>
                                    <TextField
                                        placeholder="From"
                                        className={classes.datetimeMobile}
                                        value={fromDate}
                                        variant="outlined"
                                        InputProps={{
                                            classes: {
                                                adornedEnd: classes.adournedEnd,
                                                input: classes.input
                                            },
                                            endAdornment: (
                                                <InputAdornment
                                                    className={classes.adournedEnd}
                                                    position="end"
                                                >
                                                    <Button className={classes.datetimeButton}>
                                                        <CalendarIcon
                                                            className={classes.calendarIcon}
                                                            onClick={openCalendar}
                                                        />
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            )}
                        />
                    </GridItem>

                    <GridItem xs={12}>
                        <Datetime
                            selected={toDate}
                            dateFormat="MM/DD/YY"
                            onChange={(date, e) => {
                                setToDate(date.format("MM/DD/YY"));
                            }}
                            timeFormat={false}
                            closeOnSelect={true}
                            closeOnClickOutside
                            renderInput={(props, openCalendar, closeCalendar) => (
                                <div onClick={openCalendar}>
                                    <TextField
                                        placeholder="To"
                                        className={classes.datetimeMobile}
                                        value={toDate}
                                        variant="outlined"
                                        InputProps={{
                                            classes: {
                                                adornedEnd: classes.adournedEnd,
                                                input: classes.input
                                            },
                                            endAdornment: (
                                                <InputAdornment
                                                    className={classes.adournedEnd}
                                                    position="end"
                                                >
                                                    <Button className={classes.datetimeButton}>
                                                        <CalendarIcon
                                                            className={classes.calendarIcon}
                                                            onClick={openCalendar}
                                                        />
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            )}
                        />
                    </GridItem>
                </GridContainer>
            </Hidden>

            <Hidden smDown implementation='css'>
                <GridContainer>
                    <GridItem xs={5}>
                        <div className={classes.toggleDiv}>
                            <div
                                onClick={() => handleOrdersToggle("Completed")}
                                style={
                                    orderType === "Completed"
                                        ? {
                                            background: "#1A405F",
                                            border: "2px solid #1A405F",
                                            borderTopLeftRadius: "3px",
                                            borderBottomLeftRadius: "3px",
                                            color: "white"
                                        }
                                        : {}
                                }
                                className={classes.toggleSelection}
                            >
                                Completed
                            </div>
                            <div
                                value="Past & Canceled"
                                onClick={() => handleOrdersToggle("Past & Canceled")}
                                style={
                                    orderType === "Past & Canceled"
                                        ? {
                                            background: "#1A405F",
                                            border: "2px solid #1A405F",
                                            // borderTopRightRadius: "4px",
                                            // borderBottomRightRadius: "4px",
                                            color: "white"
                                        }
                                        : {}
                                }
                                className={classes.toggleSelection}
                            >
                                Past & Canceled
                            </div>
                            <div
                                value="Pending"
                                onClick={() => handleOrdersToggle("Pending")}
                                style={
                                    orderType === "Pending"
                                        ? {
                                            background: "#1A405F",
                                            border: "2px solid #1A405F",
                                            borderTopLeftRadius: "4px",
                                            borderBottomLeftRadius: "4px",
                                            color: "white"
                                        }
                                        : {}
                                }
                                className={classes.toggleSelection}
                            >
                                Pending
                            </div>
                        </div>

                    </GridItem>

                    <GridItem xs={6} >
                        <div style={{ display: "flex", }}>
                            <Select
                                className={classes.selectMenuSortOrdersPage}
                                value={sortValue}
                                disableUnderline
                                classes={{
                                    root: classes.selectText,
                                }}
                                onChange={(e) => {
                                    setSortValue(e.target.value)
                                    handleFilter(e.target.value)
                                }}
                            >
                                {sortArr.map((o, i) => {
                                    return (
                                        <MenuItem
                                            key={i}
                                            value={o}
                                            classes={{
                                                root: classes.selectMenuItem,
                                            }}
                                        >
                                            {o}

                                        </MenuItem>
                                    );
                                })}
                            </Select>

                            <Datetime
                                className='order'
                                selected={fromDate}
                                dateFormat="MM/DD/YY"
                                onChange={(date, e) => {
                                    setFromDate(date.format("MM/DD/YY"));
                                }}
                                timeFormat={false}
                                closeOnSelect={true}
                                closeOnClickOutside
                                renderInput={(props, openCalendar, closeCalendar) => (
                                    <div onClick={openCalendar}>
                                        <TextField
                                            placeholder="From"
                                            className={classes.datetime}
                                            value={fromDate}
                                            variant="outlined"
                                            InputProps={{
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                                endAdornment: (
                                                    <InputAdornment
                                                        className={classes.adournedEnd}
                                                        position="end"
                                                    >
                                                        <Button className={classes.datetimeButton}>
                                                            <CalendarIcon
                                                                className={classes.calendarIcon}
                                                                onClick={openCalendar}
                                                            />
                                                        </Button>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                )}
                            />

                            <Datetime
                                className='order'
                                selected={toDate}
                                dateFormat="MM/DD/YY"
                                onChange={(date, e) => {
                                    setToDate(date.format("MM/DD/YY"));
                                }}
                                timeFormat={false}
                                closeOnSelect={true}
                                closeOnClickOutside
                                renderInput={(props, openCalendar, closeCalendar) => (
                                    <div onClick={openCalendar}>
                                        <TextField
                                            placeholder="To"
                                            className={classes.datetime}
                                            value={toDate}
                                            variant="outlined"
                                            InputProps={{
                                                classes: {
                                                    adornedEnd: classes.adournedEnd,
                                                    input: classes.input
                                                },
                                                endAdornment: (
                                                    <InputAdornment
                                                        className={classes.adournedEnd}
                                                        position="end"
                                                    >
                                                        <Button className={classes.datetimeButton}>
                                                            <CalendarIcon
                                                                className={classes.calendarIcon}
                                                                onClick={openCalendar}
                                                            />
                                                        </Button>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                    </GridItem>
                </GridContainer>
            </Hidden>
        </div >
    );
}

function mapStateToProps(state) {
    return {
        // nothing yet
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
        edit: (key) =>
            dispatch(
                actionsForNavigation.openChild('Profile Editor', '/admin/user/editor')
            ),
        logout: () => dispatch(AuthActions.startLogout()),
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(RewardsFilter));
