/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from 'firebase/client';
import { NavLink, withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";


import BoundExplorerCard from "components/Explorer/BoundExplorerCard";
import BoundExplorer from "components/Explorer/BoundExplorer";
import actionsForExplorer from "store/actions/explorers/leads";
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import accessorsForExplorer from "store/reducers/explorers/leads";
import { LeadStatus } from "variables/constants";
import compose from "recompose/compose";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput";
import CardFooter from "components/Card/CardFooter";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import BoundSearchControl from "../../../components/Explorer/BoundSearchControl";
// import BoundSearchDialog from "../../../components/Explorer/BoundSearchDialog";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Input from "@material-ui/core/Input";

// import CardFields from '../../../components/Card/CardFields';
// import CardField from '../../../components/Card/CardField';
import { connect } from "react-redux";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import AuthActions from "store/actions/auth"
import { chain, handleError } from "store/actions/util";
import { push } from "connected-react-router";


import GoogleLogo from 'assets/img/market-me/googleLogo.png'
import EmailLogo from 'assets/img/market-me/Login-Sign Up/email-logo-grey 1.png'
import FacebookLogo from 'assets/img/market-me/Login-Sign Up/Facebook.png'
import { Checkbox, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { RegisterPage } from "./merchantSignUp/RegisterPage";





export function LoginBox(props) {
  const { classes, authError, history } = props
  const [emailInput, setEmailInput] = useState('')
  const [passwordInput, setPasswordInput] = useState('')
  const [passwordNotice, setPasswordNotice] = useState(null)
  const [checked, setChecked] = useState(false)
  const [showPw, setShowPw] = useState(false)

  
  return (
    <div style={{ overflow: "auto" }}>
      <Hidden mdUp implementation='css'>
        <Card className={classes.mobileMainContainer}>
          <CardHeader className={classes.cardHeaderTitle}>
            Log In
          </CardHeader>
          <CardBody className={classes.cardBodyMobile}>
            {authError ? <div className={classes.textDanger}> <p>
              <b style={{ color: "red", paddingTop: "8px" }}>Error:</b> {authError.message}
            </p>
            </div> : null}
            <CustomInput
              needWhite
              labelText="Email"
              id="email"
              formControlProps={{
                fullWidth: true
              }}
              labelProps={{
                style: {
                  fontWeight: 600,
                  size: "17px",
                  color: "#004253",
                  fontFamily: "Josefin Sans",
                }
              }}
              inputProps={{
                onChange: (e) => setEmailInput(e.target.value),
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    props.emailSignIn({ email: emailInput, password: passwordInput })
                  }
                },
                disableUnderline: true,
                form: {
                  autocomplete: 'off',
                },
                style: {
                  border: "2px solid rgba(15, 55, 87, 0.6)",
                  borderRadius: "5px",
                  height: "50px",
                }
                // endAdornment: (
                //   <InputAdornment position="end">
                //     <Email />
                //   </InputAdornment>
                // )
              }}
            />
            <CustomInput
              needWhite
              labelText="Password"
              id="password"
              formControlProps={{
                fullWidth: true
              }}
              labelProps={{
                style: {
                  fontWeight: 600,
                  size: "17px",
                  color: "#004253",
                  fontFamily: "Josefin Sans",
                }
              }}
              inputProps={{
                onChange: (e) => setPasswordInput(e.target.value),
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    props.emailSignIn({ email: emailInput, password: passwordInput })
                  }
                },
                type: "password",
                disableUnderline: true,
                form: {
                  autocomplete: 'off',
                },
                style: {
                  border: "2px solid rgba(15, 55, 87, 0.6)",
                  borderRadius: "5px",
                  height: "50px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPw(!showPw)}
                    >
                      {!showPw ? <Visibility style={{ color: "#929292" }} /> : <VisibilityOff style={{ color: "#929292" }} />}
                    </IconButton>

                  </InputAdornment>
                )
              }}
            />
            <div
              style={{
                padding: "4% 0%"
              }}
            >
              <div style={{ display: "flex" }}>
                <Checkbox
                  onClick={() => { setChecked(!checked) }}
                  checked={checked}
                  style={{ color: "#929292", marginLeft: "-10px" }}
                />
                <span className={classes.rememberMeMobile}>Remember Me</span>
              </div>

              <span className={classes.forgotYourPasswordMobile}
                onClick={() => history.push("/auth/forget-password")}
              >
                Forgot Your Password?
              </span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={() => {
                  props.emailSignIn({ email: emailInput, password: passwordInput })
                }}
                className={classes.continueButtonMobile}
                style={{ backgroundColor: "#1A405F", color: "#FFFFFF" }}
                type="submit"
                color="primary"
                simple size="lg"
                block>
                Log In
              </Button>
            </div>
          </CardBody>
        </Card>
        <div className={passwordNotice || authError? classes.linkContainerMobileErr : classes.linkContainerMobile}>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <span className={classes.termsStyle}
              onClick={() => window.open("https://www.tuzorewards.com/terms-of-use/", "_blank", 'noopener,noreferrer')}

            >
              Terms of Use
            </span>
            <span className={classes.termsStyle}
              onClick={() => window.open("https://www.tuzorewards.com/privacy-policy/", "_blank", 'noopener,noreferrer')}
            >
              Privacy Policy
            </span>
          </div>
        </div>
      </Hidden>


      <Hidden smDown implementation='css'>
        <Card className={classes.mainContainer}>
          <CardHeader className={classes.cardHeaderTitle}>
            Log In
          </CardHeader>
          <CardBody className={classes.cardBody}>
            {authError ? <div className={classes.textDanger}> <p>
              <b style={{ color: "red", paddingTop: "8px" }}>Error:</b> {authError.message}
            </p>
            </div> : null}
            <CustomInput
              needWhite
              labelText="Email"
              id="email"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (e) => setEmailInput(e.target.value),
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    props.emailSignIn({ email: emailInput, password: passwordInput })
                  }
                },
                disableUnderline: true,
                form: {
                  autocomplete: 'off',
                },
                style: {
                  border: "2px solid #929292",
                  borderRadius: "5px",
                  height: "50px",
                }
                // endAdornment: (
                //   <InputAdornment position="end">
                //     <Email />
                //   </InputAdornment>
                // )
              }}
              labelProps={{
                style: {
                  fontWeight: 600,
                  size: "17px",
                  color: "#004253",
                  fontFamily: "Josefin Sans",
                }
              }}
            />
            <CustomInput
              needWhite
              labelText="Password"
              id="password"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (e) => setPasswordInput(e.target.value),
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    props.emailSignIn({ email: emailInput, password: passwordInput })
                  }
                },
                type: !showPw ? "password" : "text",
                disableUnderline: true,
                form: {
                  autocomplete: 'off',
                },
                style: {
                  border: "2px solid #929292",
                  borderRadius: "5px",
                  height: "50px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPw(!showPw)}
                    >
                      {!showPw ? <Visibility style={{ color: "#929292" }} /> : <VisibilityOff style={{ color: "#929292" }} />}
                    </IconButton>

                  </InputAdornment>
                )
              }}
              labelProps={{
                style: {
                  fontWeight: 600,
                  size: "17px",
                  color: "#004253",
                  fontFamily: "Josefin Sans",
                }
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Checkbox
                  onClick={() => { setChecked(!checked) }}
                  checked={checked}
                  style={{ color: "#929292", marginLeft: "-10px" }}
                />
                <span className={classes.rememberMe}>Remember Me</span>
              </div>

              <span className={classes.forgotYourPassword}
                onClick={() => history.push("/auth/forget-password")}
              >
                Forgot Your Password?
              </span>
            </div>
            {authError ? (
              <div className={classes.justifyContentCenter}>
                <p className={classes.textDanger}>
                  {/* <br /><b>Error:</b> {authError.message}<br /><br /> */}
                  <b>Did you forget your password?</b>
                </p>
                <Button
                  style={{ width: "30%", height: "42px" }}
                  className={classes.resetPwButton}
                  onClick={() => history.push("/auth/forget-password")}
                >
                  Reset Password
                </Button>
              </div>
            ) : null}
            {passwordNotice ? <p className={classes.textDanger}>{passwordNotice}</p> : null}
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
              <Button
                onClick={() => {
                  props.emailSignIn({ email: emailInput, password: passwordInput })
                }}
                className={classes.continueButton}
                style={{ backgroundColor: "#1A405F", color: "#FFFFFF", width:"100% !important" }}
                type="submit"
                color="primary"
                simple size="lg"
                block>
                Log In
              </Button>
              {/* <Button
                onClick={() => history.push("/auth/register-page")}
                className={classes.continueButton}
                style={{ backgroundColor: "#56CA93", color: "white" }}
                type="submit"
                color="primary"
                simple size="lg"
                block>
                Sign Up
              </Button>
            </div> */}
          </CardBody>
        </Card>
        <div className={classes.linkContainer}>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <span className={classes.termsStyle}
              onClick={() => window.open("https://www.tuzorewards.com/terms-of-use/", "_blank", 'noopener,noreferrer')}

            >
              Terms of Use
            </span>
            <span className={classes.termsStyle}
              onClick={() => window.open("https://www.tuzorewards.com/privacy-policy/", "_blank", 'noopener,noreferrer')}
            >
              Privacy Policy
            </span>
          </div>
        </div>
      </Hidden>
    </div>
  );
}


function mapStateToProps(state) {
  return {
    authError: state.auth.error
  }
}

function mapDispatchToProps(dispatch) {
  return {
    startGoogleLogin: () => dispatch(AuthActions.startGoogleLogin()),
    emailSignIn: (credentials) => dispatch(handleError(chain(AuthActions.startEmailLogin(credentials), push('/admin/user-page')), AuthActions.setError)),
    passwordReset: (email) => dispatch(AuthActions.startPasswordReset(email)),
    startMicrosoftLogin: () => dispatch(AuthActions.startMicrosoftLogin()),
    startFacebookLogin: () => dispatch(AuthActions.startFacebookLogin)
  }
}

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(loginPageStyle)
)(LoginBox));
