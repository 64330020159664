import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from "moment"
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import Button from 'components/CustomButtons/Button.js';
import { MenuItem, Select, Typography } from '@material-ui/core';

// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/travelStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import tuzoHotel from 'assets/img/tuzo/tuzoHotel.png'
import { ptsFormula } from 'globalCalculationFunction/globalCalculationFunction';

export function HotelInfo(props) {
    const {
        classes,
        history,
        travelData,
        city,
        sortValue,
        setSortValue,
        sortArr,
        fromDate,
        toDate,
        handleHotelSort,
        addProduct,
        user
    } = props;

    const numberFormatter = new Intl.NumberFormat('en-US', {
    });

    return (
        <div>
            <GridContainer>
                <Card className={classes.travelCardStyling} style={{ marginTop: "0px" }}>
                    <GridContainer>
                        <GridItem xs={8} style={{ display: "flex", alignItems: "center" }}>
                            <Typography className={classes.hotelCardTitle}>
                                {travelData.length} Hotels Available in {city}
                            </Typography>
                        </GridItem>

                        <GridItem xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Select
                                className={classes.selectMenuHotelInfo}
                                value={sortValue}
                                disableUnderline
                                classes={{
                                    root: classes.selectText,
                                }}
                                onChange={(e) => {
                                    setSortValue(e.target.value)
                                    handleHotelSort(e.target.value)

                                }}
                            >
                                {sortArr.map((o, i) => {
                                    return (
                                        <MenuItem
                                            key={i}
                                            value={o}
                                            classes={{
                                                root: classes.selectMenuItem,
                                            }}
                                        >
                                            <div style={{ display: "flex", margin: "10px 0px 0px 10px" }}>
                                                <p style={{ fontWeight: 500, marginRight: "15px" }}>Sort:</p> {o}
                                            </div>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </GridItem>

                        {travelData.map((data) => (
                            <Card className={classes.hotelDataCard}>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <GridContainer>
                                            <GridItem xs={2}>
                                                {data.ImageURL ?
                                                    <img
                                                        className={classes.hotelImg}
                                                        src={data.ImageURL}
                                                    />
                                                    :
                                                    <img
                                                        className={classes.hotelImg}
                                                        src={tuzoHotel}
                                                    />
                                                }

                                            </GridItem>
                                            <GridItem xs={6}>
                                                <Typography className={classes.hotelCardTitle}>
                                                    {data.HotelName}
                                                </Typography>

                                                <Typography className={classes.hotelDataCardDesc}>
                                                    {data.StateProv ? data.AddressLine1 + " " + data.CityName + ", " + data.StateProv + " " + data.CountryName + ", " + data.PostalCode
                                                        :
                                                        data.AddressLine1 + " " + data.CityName + " " + data.CountryName + ", " + data.PostalCode
                                                    }
                                                </Typography>
                                            </GridItem>

                                            <GridItem xs={1} style={{ borderRight: "1px solid #56CA93" }} />

                                            <GridItem xs={3} style={{ display: "flex", flexDirection: "column" }}>
                                                <GridContainer>
                                                    <GridItem xs={12} className={classes.hotelDataCardGridItem}>
                                                        <Typography className={classes.hotelDataCardNightlyDesc}>
                                                            Nightly Rate
                                                        </Typography>
                                                        <Typography className={classes.hotelDataCardNightlyDescPrice}>
                                                            {numberFormatter.format(data.nightlyRate.AverageNightlyRate * 100)} Points
                                                        </Typography>
                                                    </GridItem>

                                                    <GridItem xs={12} className={classes.hotelDataCardGridItemMarginTop}>
                                                        <Typography className={classes.hotelDataCardNightlyDesc}>
                                                            {moment(toDate).diff(fromDate, 'days') - 1} Nights
                                                        </Typography>
                                                        <Typography className={classes.hotelDataCardNightlyDescPrice}>
                                                            {numberFormatter.format(data.nightlyRate.AmountAfterTax * 100)} Points
                                                        </Typography>
                                                    </GridItem>

                                                    <GridItem xs={12} className={classes.hotelDataCardGridItemMarginTop}>
                                                        <Typography className={classes.hotelDataCardNightlyDesc}>
                                                            Additional fees may apply
                                                        </Typography>
                                                    </GridItem>

                                                    <GridItem xs={12} className={classes.hotelDataCardGridItemMarginTop}>
                                                        {/* <Button
                                                            onClick={() => addProduct(data)}
                                                            className={classes.addToCart}>
                                                            Add to Cart
                                                        </Button> */}
                                                        <Button
                                                            onClick={() => {
                                                                if (user.IsoAdminCompanyName) {
                                                                    history.push(
                                                                        `/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/travel-detail/${data.HotelCode}`,
                                                                        {
                                                                            rewardData: data
                                                                        }
                                                                    )
                                                                }
                                                                else {
                                                                    history.push(
                                                                        `/merchant/travel-detail/${data.HotelCode}`,
                                                                        {
                                                                            rewardData: data
                                                                        }
                                                                    )
                                                                }

                                                            }}
                                                            className={classes.addToCart}>
                                                            View Details
                                                        </Button>
                                                    </GridItem>

                                                </GridContainer>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        ))}
                    </GridContainer>
                </Card>
            </GridContainer>

        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(HotelInfo));