import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { db } from 'firebase/client';

// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/adStyle';
import withStyles from '@material-ui/core/styles/withStyles';

// components
import { Button, Hidden, Typography } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

//icons
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { ReactComponent as Edit } from "assets/img/tuzo/icons/EditIcon.svg";
import { AiOutlineCaretLeft } from 'react-icons/ai';
export function Preview(props) {
    const {
        history,
        classes,
        adData
    } = props;
    
    const deleteAd = () => {
        db.collection('ad-list').doc(adData.id).delete()
    }

    return (
        <div>
            <GridContainer>
                <GridItem xs={2}>
                    <Typography variant="h2" className={classes.adFormTitle}>
                        View Promotional Ad
                    </Typography>
                </GridItem>
                <GridItem xs={3}>
                    <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "-5px" }}>
                        {/* <Button
                            className={classes.saveButton}
                            style={{
                                background: "#1A405F",
                            }}
                            onClick={() => history.push('/admin/ad')}
                        >
                            <AiOutlineCaretLeft className={classes.icon} /> Back
                        </Button> */}
                        <Button
                            className={classes.saveButton}
                            onClick={() => history.push(`/admin/ads/edit/${adData.id}`)}
                        >
                            <Edit className={classes.icon} /> Edit
                        </Button>
                        <Button
                            className={classes.saveButton}
                            style={{
                                background: "#56CA93",
                            }}
                            onClick={() => deleteAd()}
                        >
                            <TrashIcon className={classes.icon} /> Delete
                        </Button>
                    </div>
                </GridItem>
                <GridItem xs={12} style={{ marginTop: "30px" }}>
                    <div
                        style={{
                            backgroundImage: "linear-gradient(rgba(0,0,0,.75), rgba(0,0,0,.75)), url(" + (adData.backgroundImage) + ")",
                            padding: "10px",
                            minHeight: "250px",
                            backgroundColor: "#06667C",
                            borderRadius: "7px",
                            display: "flex",
                            alignItems: "center",
                            backgroundPhoto: "no-repeat",
                            backgroundSize: "cover",
                            textAlign: "center",
                            marginTop: "0px",
                            maxWidth: "390px"
                        }}
                    >
                        <GridContainer>
                            <GridItem xs={12} style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-evenly",
                                minHeight: "350px"
                            }}>
                                <Typography
                                    style={{ fontSize: "28px", lineHeight: "28px", marginBottom: "10px" }}
                                    className={classes.bannerTitle}
                                >
                                    {adData.adName}
                                </Typography>


                                <Typography className={classes.picDesc}>
                                    {adData.description.length > 8 ? <span dangerouslySetInnerHTML={{ __html: adData.description }}></span> : "No Description"}
                                </Typography>
                                <Button
                                    style={{ width: "90%", height: "40px", alignSelf: "center", marginTop: "10px" }}
                                    className={classes.transferButton}
                                >
                                    {adData.buttonText}
                                </Button>

                            </GridItem>
                        </GridContainer>
                    </div>
                </GridItem>
            </GridContainer>

        </div>
    )
}

function mapStateToProps(state, location) {
    return {
        adData: location.history.location.state?.adData,
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Preview));