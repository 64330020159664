import React, { useState, createRef, useEffect } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import actionsForNavigation from "store/actions/navigation";
import AuthActions from "store/actions/auth";
import { db, firebase, storage } from "firebase/client";
import MerchantInfo from "./components/MerchantInfo";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import Slide from "@material-ui/core/Slide";
import { Card, Dialog, Typography } from "@material-ui/core";

import {
  updateUserData,
  updateUserPhoto,
  updateUserLogo,
} from "store/actions/auth.js";

// @material-ui/icons
import { ReactComponent as Edit } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";
import { ReactComponent as Delete } from "assets/img/tuzo/icons/Delete.svg";

//styling
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import tuzoBg from "assets/img/tuzo/carouselBackground.png";
import EditMerchantInfo from "./components/EditMerchantInfo";
import { BsCheckCircleFill } from "react-icons/bs";
import { Close } from "@material-ui/icons";
import Loader from "components/Loader/Loader";

const intervalArr = ["60", "90", "120", "180"];

export function EditMerchantProfile(props) {
  const {
    classes,
    history,
    logo,
    user,
    editState,
    userCompany,
    userAutoApprove,
  } = props;

    const merchantId = window.location.pathname.split('/')[4]
    const [merchantData, setMerchantData] = useState(false)
    const [wishListData, setWishListData] = useState(false)
    const [rewardsData, setRewardsData] = useState(false)
    const itemsPerPage = 2;
    const [pageNumber, setPageNumber] = useState(0);
    const [index, setIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(itemsPerPage);
    const [edit, setEdit] = useState(editState ? editState : false)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [contactFirstName, setContactFirstName] = useState("")
    const [contactLastName, setContactLastName] = useState("")
    const [cellPhone, setCellPhone] = useState("")
    const [secondaryPhone, setSecondaryPhone] = useState("")
    const [shippingAddress, setShippingAddress] = useState("")
    const [userBasisPts, setUserBasisPts] = useState("")
    const [signUpBonus, setSignUpBonus] = useState("")
    const [autoApprove, setAutoApprove] = useState(userAutoApprove ? userAutoApprove : false)
    const [interval, setInterval] = useState("60")
    const [emailSent, setEmailSent] = useState(false)
    const [gateway, setGateway] = useState("Fiserv AccessOne")
    const [apiInfo, setApiInfo] = useState([
        {
        apiType: "Fiserv AccessOne",
        apiKey: "",
        apiSecret: "",
        status: "Unused",
        id: 0
        },
        {
            apiType: "IRIS",
            apiKey: "",
            apiSecret: "",
            status: "Unused",
            id: 1
        },
        {
            apiType: "Maverick",
            apiKey: "",
            apiSecret: "",
            status: "Unused",
            id: 2
        },
  
    ])

  function formatPhoneNumber(phoneNumber) {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return "";
  }

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage);
    setEndIndex(selected * itemsPerPage + itemsPerPage);
  };

  const getBgImage = () => {
    return tuzoBg;
  };

  const handlePasswordReset = (e) => {
    e.preventDefault();
    props.startPasswordReset(merchantData.email).then(() => {
      setEmailSent(true);
    });
  };

    const updateUserInfo = (e) => {
        e.preventDefault();
        var newInfo = {
            firstName,
            lastName,
            companyName,
            cellPhone,
            secondaryPhone,
            shippingAddress,
            autoApprove,
            ptsReleaseDay: interval,
            userBasisPts: userBasisPts,
            signUpBonus: signUpBonus,
            contactFirstName,
            contactLastName,
            apiInfo
        };
        db.collection('users').doc(merchantId).update(newInfo).then(() => {
            setEdit(false)
        })
    };

    useEffect(() => {
        if (!merchantData) {
            let wishListArr = [];
            let rewardsArr = [];
            var userPts = 0
            const getData = () => {
                db.collection('users').doc(merchantId).get()
                    .then((snapshot) => {
                        snapshot = snapshot.data()
                        userPts = snapshot.tuzoPoints
                        setMerchantData(snapshot)
                        setFirstName(snapshot.firstName)
                        setLastName(snapshot.lastName)
                        setCompanyName(snapshot.companyName)
                        setCellPhone(snapshot.cellPhone ? snapshot.cellPhone : "")
                        setSecondaryPhone(snapshot.secondaryPhone ? snapshot.secondaryPhone : "")
                        setShippingAddress(snapshot.shippingAddress ? snapshot.shippingAddress : snapshot.address ? snapshot.address : "")
                        setUserBasisPts(snapshot.userBasisPts)
                        setSignUpBonus(snapshot.signUpBonus ? snapshot.signUpBonus : "")
                        setInterval(snapshot.ptsReleaseDay ? snapshot.ptsReleaseDay : "60")
                        setContactFirstName(snapshot.contactFirstName ? snapshot.contactFirstName : "")
                        setContactLastName(snapshot.contactLastName ? snapshot.contactLastName : "")
                        setApiInfo(snapshot.apiInfo? snapshot.apiInfo: apiInfo)
                    })
                    .then(() => {
                        db.collection('rewards-list-test')
                            .where("salePricePoints", "<=", userPts)
                            .get()
                            .then((snapshots) => {
                                if (!snapshots.empty) {
                                    snapshots.forEach((doc) => {
                                        rewardsArr.push(doc.data())
                                    })
                                    setRewardsData(rewardsArr)
                                }
                                else {
                                    setRewardsData([])
                                }
                            })
                    })

            }

      const getWishList = () => {
        db.collection("users")
          .doc(merchantId)
          .collection("wish-list")
          .get()
          .then((snapshots) => {
            if (!snapshots.empty) {
              snapshots.forEach((doc) => {
                wishListArr.push(doc.data());
              });
              setWishListData(wishListArr);
            } else {
              setWishListData([]);
            }
          });
      };
      getData();
      getWishList();
    }
  }, []);

  if (!merchantData || !wishListData || !rewardsData) return <Loader />;

  return (
    <div>
      <Hidden mdUp implementation="css">
        <div>
          <Typography
            variant="h2"
            className={classes.headerTitle}
            style={{ color: "#1A405F" }}
          >
            Please View Page on Desktop / Laptop
          </Typography>
        </div>
      </Hidden>

      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem xs={8} style={{ display: "flex" }}>
            <Typography variant="h2" className={classes.headerTitle}>
              {merchantData.firstName + " " + merchantData.lastName}
            </Typography>

            {!edit ? (
              <div>
                <Button
                  onClick={() => setEdit(true)}
                  className={classes.editButton}
                >
                  <Edit style={{ marginRight: "10px", marginTop: "-5px" }} />{" "}
                  Edit
                </Button>

                <Button
                  onClick={handlePasswordReset}
                  className={classes.editButton}
                  style={{ background: "#56CA93", width: "350px" }}
                >
                  <BsCheckCircleFill
                    style={{ marginRight: "10px", marginTop: "-5px" }}
                  />{" "}
                  Send Reset Password Email
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  className={classes.editButton}
                  style={{ background: "#1A405F", width: "128px" }}
                  onClick={updateUserInfo}
                >
                  <SaveIcon
                    style={{ marginRight: "10px", marginTop: "-5px" }}
                  />{" "}
                  Save
                </Button>

                <Button
                  onClick={() => setEdit(false)}
                  className={classes.editButton}
                  style={{ background: "#E2A400", width: "143px" }}
                >
                  <Delete style={{ marginRight: "10px", marginTop: "-5px" }} />{" "}
                  Cancel
                </Button>
              </div>
            )}
          </GridItem>

          <GridItem
            xs={4}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "-10px",
                marginRight: "40px",
              }}
            >
              <Typography variant="h2" className={classes.headerDesc}>
                User Type
              </Typography>
              <Typography
                variant="h2"
                className={classes.headerDesc}
                style={{
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "18px",
                  marginTop: "10px",
                }}
              >
                {merchantData.userType.includes("Merchant")
                  ? "Merchant"
                  : merchantData.userType}
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "-10px",
              }}
            >
              <Typography variant="h2" className={classes.headerDesc}>
                MID
              </Typography>
              <Typography
                variant="h2"
                className={classes.headerDesc}
                style={{
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "18px",
                  marginTop: "10px",
                }}
              >
                {merchantData.merchantId}
              </Typography>
            </div>
          </GridItem>
        </GridContainer>

        <div
          style={{
            backgroundImage: "url(" + getBgImage() + ")",
            width: "100vw",
            marginLeft: "-30px",
            height: "100%",
            marginTop: "15px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#274b73",
            backgroundPhoto: "no-repeat",
            backgroundSize: "contain",
            padding: "20px",
          }}
        >
          <GridContainer style={{ alignItems: "center" }}>
            <GridItem xs={4} style={{ textAlign: "center", marginTop: "10px" }}>
              <Typography variant="h2" className={classes.profileBannerText}>
                User has{" "}
                <span className={classes.profileBannerPts}>
                  {" "}
                  {merchantData.approvedPts
                    ? merchantData.approvedPts.toLocaleString()
                    : 0}{" "}
                </span>{" "}
                Tuzo points now and{" "}
                <span className={classes.profileBannerPts}>
                  {" "}
                  {merchantData.tuzoPoints
                    ? merchantData.tuzoPoints.toLocaleString()
                    : 0}{" "}
                </span>{" "}
                pending!
              </Typography>
            </GridItem>

            <GridItem xs={4} style={{ textAlign: "center", marginTop: "10px" }}>
              <Typography
                variant="h2"
                className={classes.profileBannerText}
                style={{ lineHeight: "30px" }}
              >
                Based on your average monthly processing (
                {merchantData.approvedPts
                  ? merchantData.approvedPts.toLocaleString()
                  : 0}
                ), you’re on track to have:
              </Typography>
            </GridItem>

            <GridItem
              xs={4}
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-evenly",
                textAlign: "center",
              }}
            >
              <div>
                <Typography
                  variant="h2"
                  className={classes.profileBannerDurationText}
                >
                  1 Month:
                </Typography>
                <Typography
                  variant="h2"
                  className={classes.profileBannerDurationPts}
                >
                  {merchantData.approvedPts
                    ? merchantData.approvedPts.toLocaleString()
                    : 0}
                </Typography>
              </div>

              <div>
                <Typography
                  variant="h2"
                  className={classes.profileBannerDurationText}
                >
                  1 Year:
                </Typography>
                <Typography
                  variant="h2"
                  className={classes.profileBannerDurationPts}
                >
                  {merchantData.approvedPts
                    ? (merchantData.approvedPts * 12).toLocaleString()
                    : 0}
                </Typography>
              </div>

              <div>
                <Typography
                  variant="h2"
                  className={classes.profileBannerDurationText}
                >
                  3 Years:
                </Typography>
                <Typography
                  variant="h2"
                  className={classes.profileBannerDurationPts}
                >
                  {merchantData.approvedPts
                    ? (merchantData.approvedPts * 36).toLocaleString()
                    : 0}
                </Typography>
              </div>
            </GridItem>
          </GridContainer>
        </div>

                <GridContainer >
                    <GridItem xs={12} style={{ marginTop: "20px" }}>
                        <EditMerchantInfo
                            edit={edit}
                            setEdit={setEdit}
                            merchantId={merchantId}
                            rewardsData={rewardsData}
                            classes={classes}
                            history={history}
                            wishListData={wishListData}
                            merchantData={merchantData}
                            formatPhoneNumber={formatPhoneNumber}
                            itemsPerPage={itemsPerPage}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                            index={index}
                            setIndex={setIndex}
                            endIndex={endIndex}
                            setEndIndex={setEndIndex}
                            handlePageClick={handlePageClick}
                            firstName={firstName}
                            setFirstName={setFirstName}
                            lastName={lastName}
                            setLastName={setLastName}
                            companyName={companyName}
                            setCompanyName={setCompanyName}
                            contactFirstName={contactFirstName}
                            setContactFirstName={setContactFirstName}
                            contactLastName={contactLastName}
                            setContactLastName={setContactLastName}
                            cellPhone={cellPhone}
                            setCellPhone={setCellPhone}
                            secondaryPhone={secondaryPhone}
                            setSecondaryPhone={setSecondaryPhone}
                            shippingAddress={shippingAddress}
                            setShippingAddress={setShippingAddress}
                            userBasisPts={userBasisPts}
                            setUserBasisPts={setUserBasisPts}
                            signUpBonus={signUpBonus}
                            setSignUpBonus={setSignUpBonus}
                            autoApprove={autoApprove}
                            setAutoApprove={setAutoApprove}
                            interval={interval}
                            setInterval={setInterval}
                            intervalArr={intervalArr}
                            gateway={gateway}
                            setGateway={setGateway}
                            apiInfo={apiInfo}
                            setApiInfo={setApiInfo}
                        />
                    </GridItem>
                </GridContainer>
            </Hidden>

      <Dialog
        className={classes.requestModal}
        open={emailSent}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Card className={classes.confirmModalCard}>
          <GridContainer>
            <GridItem xs={12}>
              <h5 className={classes.cardHeader}>
                Email has been sent to user to reset their password.{" "}
              </h5>
            </GridItem>
            <GridItem
              xs={12}
              style={{ justifyContent: "center", display: "flex" }}
            >
              <Button
                className={classes.uploadButton}
                onClick={() => {
                  setEmailSent(false);
                  history.push(
                    `/iso-admin/${userCompany
                      .replace(/ /g, "")
                      .toLowerCase()}/merchants`
                  );
                }}
              >
                <Close style={{ marginRight: "10px" }} /> Close
              </Button>
            </GridItem>
          </GridContainer>
        </Card>
      </Dialog>
    </div>
  );
}

EditMerchantProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, location) {
  return {
    user: state.auth.user,
    userSet: state.auth.user.userSet,
    editState: location.history.location.state?.editState,
    userCompany: state.auth.user.companyName,
    userAutoApprove: state.auth.user.autoApprove,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    updateUserData: (uid, newInfo) => dispatch(updateUserData(uid, newInfo)),
    updateUserPhoto: (id, document) => dispatch(updateUserPhoto(id, document)),
    updateUserLogo: (id, document) => dispatch(updateUserLogo(id, document)),
    logout: () => dispatch(AuthActions.startLogout()),
    startPasswordReset: (email) =>
      dispatch(AuthActions.startPasswordReset(email)),
  });
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(EditMerchantProfile);
