import Dashboard from "views/Pages/subisodashboard/Dashboard";
import Rewards from "views/Pages/isoadminreward/Rewards";
import MerchantProfile from "views/Pages/isoadminmerchantprofile/MerchantProfile";
import EditMerchantProfile from "views/Pages/isoadminmerchantprofile/EditMerchantProfile";
import TempMerchantProfile from "views/Pages/isoadmintempprofile/TempMerchantProfile";
import EditTempMerchantProfile from "views/Pages/isoadmintempprofile/EditTempMerchantProfile";
import Profile from "views/Pages/isoadminprofile/Profile";
import NewProfile from "views/Pages/subisonewprofile/NewProfile";


var subIsoRoutes = [
    {
        path: '/:iso/merchants',
        name: 'Merchants',
        component: Dashboard,
        layout: '/sub-iso',
      },
      {
        path: '/:iso/rewards',
        name: 'Merchants',
        component: Rewards,
        layout: '/sub-iso',
      },
      {
        path: '/:iso/add-merchant',
        name: 'Add Merchant',
        component: NewProfile,
        layout: '/sub-iso',
      },
      {
        path: '/:iso/user-profile/:id',
        name: 'Merchant Profile',
        component: MerchantProfile,
        layout: '/sub-iso',
      },
      {
        path: '/:iso/temp-user-profile/:id',
        name: 'Merchant Profile',
        component: TempMerchantProfile,
        layout: '/sub-iso',
      },
      {
        path: '/:iso/edit-temp-user-profile/:id',
        name: 'Merchant Profile',
        component: EditTempMerchantProfile,
        layout: '/sub-iso',
      },
      {
        path: '/:iso/edit-user-profile/:id',
        name: 'Merchant Profile',
        component: EditMerchantProfile,
        layout: '/sub-iso',
      },
      {
        path: '/:iso/profile',
        name: 'Profile',
        component: Profile,
        layout: '/sub-iso',
      },
]

export default subIsoRoutes;