import React from 'react';
import Carousel from "react-multi-carousel";
import '../rewards/carousel.css';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CardContent, Typography } from '@material-ui/core';

// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/calculatorStyle';
import withStyles from '@material-ui/core/styles/withStyles';

// icons
import { ReactComponent as CaretLeft } from "assets/img/tuzo/icons/CaretLeft.svg";
import { ReactComponent as CaretRight } from "assets/img/tuzo/icons/CaretRight.svg";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1900 },
        items: 4,
    },
    desktop: {
        breakpoint: { max: 1900, min: 1366 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1350, min: 1024 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 1000, min: 0 },
        items: 1,
    },
};

export function CardListMobile(props) {
    const {
        classes,
        arr
    } = props;

    const CustomRightArrow = ({ onClick }) => (
        <button
            className={classes.rightArrow}
            onClick={onClick}>
            <CaretRight />
        </button>
    );
    const CustomLeftArrow = ({ onClick }) => (
        <button
            className={classes.leftArrow}
            onClick={onClick}
        >
            <CaretLeft />
        </button>
    );

    return (
        <div>
            <Carousel
                autoPlay={false}
                autoPlaySpeed={3000}
                infinite={true}
                swipeable={true}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                draggable={false}
                showDots={false}
                responsive={responsive}
                style={{ display: "flex" }}
                arrows={true}
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}
            >
                {arr.map((data, index) => (
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <Card className={classes.rewardsCardListStylingMobile}>
                            <CardContent style={{ padding: "0px 0px 0px 0px" }}>
                                <div style={{ textAlign: "center", borderBottom: "1px solid #56CA93" }}>
                                    <img
                                        className={classes.mainImg}
                                        src={data.mainImage}
                                        alt="picture here"
                                    />

                                    <Typography variant="h2" className={classes.cardListTitleNoOverflowMobile}>
                                        {(data.rewardName).length < 33 ? <br /> : null}
                                        {data.rewardName}
                                    </Typography>
                                </div>


                                <div>
                                    <Typography variant="h2" className={classes.cardListDesc}>
                                        Points Price
                                    </Typography>
                                    <Typography variant="h2" className={classes.cardListPts}>
                                        {(data.salePricePoints).toLocaleString()}
                                    </Typography>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                ))}
            </Carousel>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        // userId: state.auth.user.uid
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(CardListMobile));
