import React, { useEffect, useState } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { db, firebase } from "firebase/client";
import SearchReward from "./components/SearchReward";

// core components
import { Button, Hidden, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Loader from "components/Loader/Loader";

// styling
import Style from "assets/jss/material-dashboard-pro-react/views/assignRewardStyle";
import withStyles from "@material-ui/core/styles/withStyles";

// icons
import { ReactComponent as Trash } from "assets/img/tuzo/icons/TrashIcon.svg";
import Summary from "./components/Summary";
import SearchUser from "./components/SearchUser";
import { sendAssignReward } from "firebase/client";

export function AssignReward(props) {
  const { classes, history, detailData } = props;

  const [rewardData, setRewardData] = useState(detailData ? detailData : "");
  const [userArr, setUsersArr] = useState(false);
  const [userData, setUserData] = useState("");
  const [redeemPoints, setRedeemPoints] = useState("");
  const [option, setOption] = useState("Only");
  const [total, setTotal] = useState(
    detailData ? Number(detailData.salePrice) * 100 : 0
  );
  const [ptsRemaining, setPtsRemaining] = useState(0);
  const [dollarAmt, setDollarAmt] = useState(0);

  const numberFormatter = new Intl.NumberFormat("en-US", {});

  const numberFormatterDecimals = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const getUserArr = () => {
    let arr = [];
    db.collection("users")
      .get()
      .then((snapshots) => {
        if (!snapshots.empty) {
          snapshots.forEach((doc) => {
            doc = doc.data();
            if (doc.userType.includes("Merchants")) {
              arr.push(doc);
            }
          });
          setUsersArr(arr);
        } else {
          setUsersArr([]);
        }
      });
  };

  const handleEmail = () => {
    const docId = db
      .collection("users")
      .doc(userData.uid)
      .collection("orders")
      .doc().id;
    let newData = [];
    newData.push(rewardData);
    for (let i = 0; i < newData.length; i++) {
      newData[i].orderDate = firebase.firestore.Timestamp.fromDate(new Date());
      newData[i].orderId = docId;
      newData[i].orderStatus = "Pending";
      newData[i].firstName = userData.firstName;
      newData[i].lastName = userData.lastName;
      newData[i].uid = userData.uid;
    }
    let obj = {
      orderData: newData,
      paymentType:
        option === "Only"
          ? "Points"
          : option === "Partial"
          ? "Points + Payment"
          : "Payment",
      orderId: docId,
    };
    db.collection("orders").doc(docId).set(obj);
    db.collection("users")
      .doc(userData.uid)
      .collection("notifications")
      .doc(docId)
      .set({
        data: "An Admin has assigned you a reward.",
        message: "",
        read: false,
        timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
        type: "Assign Reward",
        docId: docId,
        rewardData,
      });
    db.collection("users")
      .doc(userData.uid)
      .collection("orders")
      .doc(docId)
      .set(obj)
      .then(() => {
        db.collection("users").doc(userData.uid).update({
          approvedPts: ptsRemaining,
        });
      })
      .then(() => {
        sendAssignReward(obj);
      })
      .then(() => {
        history.push("/admin");
      });
  };

  const handleOption = (value) => {
    setOption(value);
    if (value !== "Only") {
      if (userData.approvedPts) {
        setPtsRemaining(userData.approvedPts);
      } else {
        setPtsRemaining(0);
      }
      if (value === "None") {
        setDollarAmt(rewardData.salePrice);
      }
    }
    if (value === "Only") {
      if (userData.approvedPts) {
        updatePts(userData.approvedPts);
      }
    }
  };

  const updatePartialPts = (ptsRemaining, pts) => {
    const newTotal = ptsRemaining - pts;
    const leftToPay = (total - pts) / 100;
    setDollarAmt(leftToPay);
    setPtsRemaining(newTotal);
  };

  const updatePts = (approvedPts) => {
    const newTotal = approvedPts - total;
    setPtsRemaining(newTotal);
  };

  useEffect(() => {
    if (userData && rewardData) {
      if (userData.approvedPts && option === "Only") {
        updatePts(userData.approvedPts);
      }
    }
  }, [userData, rewardData]);

  useEffect(() => {
    if (!userArr) {
      getUserArr();
    }
  }, []);

  if (!userArr) return <Loader />;

  return (
    <div>
      <Hidden mdUp implementation="css">
        <div>
          <Typography
            variant="h2"
            className={classes.title}
            style={{ color: "#1A405F" }}
          >
            Please View Page on Desktop / Laptop
          </Typography>
        </div>
      </Hidden>

      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h2" className={classes.title}>
              Assign Reward
            </Typography>

            <Button
              className={classes.cancel}
              onClick={() => history.push("/admin")}
            >
              <Trash className={classes.icon} /> Cancel
            </Button>
          </GridItem>

          <GridItem xs={8}>
            <SearchReward
              rewardData={rewardData}
              setRewardData={setRewardData}
              setTotal={setTotal}
            />
          </GridItem>

          <GridItem xs={4}>
            <Summary
              userData={userData}
              data={rewardData}
              numberFormatter={numberFormatter}
              numberFormatterDecimals={numberFormatterDecimals}
              redeemPoints={redeemPoints}
              total={total}
              ptsRemaining={ptsRemaining}
              option={option}
              dollarAmt={dollarAmt}
              setDollarAmt={setDollarAmt}
              handleEmail={handleEmail}
            />
          </GridItem>

          <GridItem xs={12}>
            <SearchUser
              userArr={userArr}
              userData={userData}
              setUserData={setUserData}
              history={history}
              numberFormatter={numberFormatter}
              rewardData={rewardData}
              redeemPoints={redeemPoints}
              setRedeemPoints={setRedeemPoints}
              option={option}
              setOption={setOption}
              handleOption={handleOption}
              updatePts={updatePts}
              updatePartialPts={updatePartialPts}
            />
          </GridItem>
        </GridContainer>
      </Hidden>
    </div>
  );
}

function mapStateToProps(state, location) {
  return {
    detailData: location.history.location.state?.rewardData,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({});
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(AssignReward);
