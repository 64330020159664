/*eslint-disable*/
import { db } from 'firebase/client';


export const getWishlist = (uid) => {
  return (dispatch) => {
    return db
      .collection('users').doc(uid).collection('wish-list').get()
      .then((snapshot) => {
        if (snapshot.empty) {
          dispatch(setWishlist([]))
        } else {
          snapshot = snapshot.docs
          var wishlist = []
          snapshot.forEach((doc) => {
            wishlist.push(doc.data())
          })
          dispatch(setWishlist(wishlist))
        }
      })
  }
}


export const addToWishList = (data, uid) => {
  let newObj = {
    ...data,
    wishlist: true
  }
  return (dispatch) => {
    return db
      .collection('users')
      .doc(uid)
      .collection('wish-list')
      .doc(data.rewardId)
      .set(newObj)
      .then(() => {
        dispatch(updateAddWishList(data))
      })
  }
}

export const removeFromWishlist = (data, uid, index) => {
  return (dispatch) => {
    return db
    .collection('users')
    .doc(uid)
    .collection('wish-list')
    .doc(data.rewardId)
    .delete(data)
    .then(() => {
      dispatch(updateRemoveWishList(index))
    })
  }
}


export const setWishlist = (wishlist) => {
  return {
    type: "SET_WISHLIST",
    wishlist
  }
}

export const updateAddWishList = (product) => {
  return {
    type: "ADD_WISHLIST",
    product
  }
}

export const updateRemoveWishList = (index) => {
  return {
    type: "REMOVE_WISHLIST",
    index
  }
}