/*eslint-disable*/
const initState = {
  user: {},
  authenticated: false,
  userType: 'N/A'
}

export default function reduce(state = initState, action) {
  switch (action.type) {
    case 'AUTH_SET_USER':
      return {
        ...state,
        user: action.user,
        error: undefined,
        authenticated: true
      }
    case "UPDATE_USER_TYPE":
      var toReturn = { ...state }
      toReturn.userType = action.userType
      return toReturn
    case "AUTH_SET_USER_SIGNUP":
      console.log('herererre', 'setusersignup')
      var toReturn = { ...state }
      toReturn.user = action.user
      toReturn.error = undefined
      toReturn.authenticated = true
      toReturn.firstName = action.userInfo.firstName ? action.userInfo.firstName : 'N/A'
      toReturn.lastName = action.userInfo.lastName ? action.userInfo.lastName : "N/A"
      toReturn.officePhone = action.userInfo.officePhone ? action.userInfo.officePhone : "N/A",
        toReturn.companyName = action.userInfo.companyName ? action.userInfo.companyName : "N/A",
        toReturn.cellPhone = action.userInfo.cellPhone ? action.userInfo.cellPhone : "N/A"
      toReturn.email = action.user.user.email ? action.user.user.email : "N/A"
      toReturn.dateOfBirth = action.user.dateOfBirth ? action.user.dateOfBirth : "N/A"
      toReturn.profilePic = action.userInfo.profilePic ? action.userInfo.profilePic : ""
      toReturn.userSet = true
      return toReturn
    case 'AUTH_UPDATE_USER':
      return {
        ...state,
        user: action.user,
        error: undefined,
        authenticated: true
      }
      case 'AUTH_UPDATE_TEMP_USER':
      return {
        ...state,
        user: action.user,
        error: undefined,
        authenticated: true
      }
    case 'AUTH_SET_ERROR':
      return {
        ...state,
        user: {},
        error: action.error,
        authenticated: false
      }
    case 'AUTH_LOGOUT':
      return {
        ...state,
        user: {},
        error: undefined,
        authenticated: false
      }
    default:
      return state
  }
}
