import React from 'react';
import { useState } from 'react';
import DonutChart from 'react-donut-chart';

// core components
import Box from '@material-ui/core/Box';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import Button from 'components/CustomButtons/Button.js';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import actionsForNavigation from 'store/actions/navigation';
import AuthActions from 'store/actions/auth';
import Slide from '@material-ui/core/Slide';
import Hidden from "@material-ui/core/Hidden";
import { InputAdornment, MenuItem, Select, TextField, Typography } from '@material-ui/core';

// @material-ui/icons
import { ReactComponent as SearchIcon } from "assets/img/tuzo/icons/SearchIcon.svg";
import { ReactComponent as Heart } from "assets/img/tuzo/icons/Heart.svg";

// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/rewardsStyle';
import withStyles from '@material-ui/core/styles/withStyles';


export function RewardsFilter(props) {
    const {
        classes,
        history,
        user,
        sortArr,
        searchValue,
        setSearchValue,
        sortValue,
        setSortValue,
        handleSearch,
        handleFilter
    } = props;


    return (
        <div>
            <Hidden mdUp implementation='css'>
                <GridContainer>
                    <GridItem xs={12} style={{ display: "flex" }}>
                        <TextField
                            variant="standard"
                            className={classes.searchBarMobile}
                            placeholder="Search Rewards"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                            InputProps={{
                                classes: {
                                    adornedEnd: classes.adournedEnd,
                                    input: classes.input
                                },
                                startAdornment: (

                                    <InputAdornment
                                        position="start"
                                    >
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </GridItem>

                    <GridItem xs={12}>
                        <Select
                            className={classes.selectMenuSortRewardsPageMobile}
                            value={sortValue}
                            disableUnderline
                            classes={{
                                root: classes.selectText,
                            }}
                            onChange={(e) => {
                                setSortValue(e.target.value)
                                handleFilter(e.target.value)
                            }}
                        >
                            {sortArr.map((o, i) => {
                                return (
                                    <MenuItem
                                        key={i}
                                        value={o}
                                        classes={{
                                            root: classes.selectMenuItem,
                                        }}
                                    >
                                        <div style={{ display: "flex", margin: "10px 0px 0px 10px" }}>
                                            <p style={{ fontWeight: 500, marginRight: "15px" }}>Sort:</p> {o}
                                        </div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </GridItem>

                    <GridItem xs={12} >
                        <Button
                            style={{width:"100%"}}
                            className={classes.wishListButton}
                            onClick={() => {
                                if (user.IsoAdminCompanyName) {
                                    history.push(`/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/wish-list`)
                                }
                                else {
                                    history.push('/merchant/wish-list')
                                }

                            }}
                        >
                            <Heart style={{ marginRight: "10px" }} /> View Wishlist
                        </Button>
                    </GridItem>
                </GridContainer>
            </Hidden>

            <Hidden smDown implementation='css'>

                <GridContainer>
                    <GridItem xs={6} style={{ display: "flex" }}>
                        <TextField
                            variant="standard"
                            className={classes.searchBar}
                            placeholder="Search Rewards"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                            InputProps={{
                                classes: {
                                    adornedEnd: classes.adournedEnd,
                                    input: classes.input
                                },
                                startAdornment: (

                                    <InputAdornment
                                        position="start"
                                    >
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Select
                            className={classes.selectMenuSortRewardsPage}
                            value={sortValue}
                            disableUnderline
                            classes={{
                                root: classes.selectText,
                            }}
                            onChange={(e) => {
                                setSortValue(e.target.value)
                                handleFilter(e.target.value)
                            }}
                        >
                            {sortArr.map((o, i) => {
                                return (
                                    <MenuItem
                                        key={i}
                                        value={o}
                                        classes={{
                                            root: classes.selectMenuItem,
                                        }}
                                    >
                                        <div style={{ display: "flex", margin: "10px 0px 0px 10px" }}>
                                            <p style={{ fontWeight: 500, marginRight: "15px" }}>Sort:</p> {o}
                                        </div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </GridItem>

                    <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            className={classes.wishListButton}
                            onClick={() => {
                                if (user.IsoAdminCompanyName) {
                                    history.push(`/iso-merchant/${(user.IsoAdminCompanyName).replace(/ /g, '').toLowerCase()}/${(user.companyName).replace(/ /g, '').toLowerCase()}/wish-list`)
                                }
                                else {
                                    history.push('/merchant/wish-list')
                                }

                            }}
                        >
                            <Heart style={{ marginRight: "10px" }} /> View Wishlist
                        </Button>
                    </GridItem>
                </GridContainer>
            </Hidden>

        </div>
    );
}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
        edit: (key) =>
            dispatch(
                actionsForNavigation.openChild('Profile Editor', '/admin/user/editor')
            ),
        logout: () => dispatch(AuthActions.startLogout()),
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(RewardsFilter));
