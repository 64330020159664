import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import RouteLoader from "components/Loader/RouteLoader";
// creates a beautiful scrollbar
import withStyles from "@material-ui/core/styles/withStyles";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import compose from "recompose/compose";
import { connect } from "react-redux";
import {
  setGlobalArrowOpen,
  setGlobalCalulcatorOpen,
} from "store/actions/search/searchActions";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import Heading from "components/Heading/Heading";
import routes from "routes/isoMerchantRoutes";

import Button from "components/CustomButtons/Button";
import AuthActions from "store/actions/auth";
import Hidden from "@material-ui/core/Hidden";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import "assets/css/material-dashboard-pro-react.css";
import IsoAdminNavbar from "components/Navbars/IsoAdminNavbar";
import IsoMerchantNavBar from "components/Navbars/IsoMerchantNavBar";

var ps;

const useStyles = makeStyles(styles);

function IsoAdmin(props) {
  const {
    globalCalulcatorOpen,
    setGlobalCalulcator,
    globalArrowOpen,
    setGlobalArrow,
    ...rest
  } = props;

  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image, setImage] = React.useState(require("assets/img/sidebar-2.jpg"));
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(
    require("assets/img/tuzo/tuzoLogo.png")
  );
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  const history = useHistory();
  const routeChange = (route) => {
    history.push(route);
  };

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });
  // functions for changeing the states from components
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleBgColorClick = (bgColor) => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.svg"));
        break;
      default:
        setLogo(require("assets/img/logo-white.svg"));
        break;
    }
    setBgColor(bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.pathname.includes("lead-detail")) {
          activeRoute = "Lead Detail";
        } else if (window.location.pathname.includes("customer-detail")) {
          activeRoute = "Customer Detail";
        } else {
          if (
            window.location.href.indexOf(routes[i].layout + routes[i].path) !==
            -1
          ) {
            //return routes[i].name;
            return [routes[i].name, routes[i].category];
          }
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === `/iso-merchant`) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      {/* <Sidebar
        routes={routes}
        logoText={'Creative Tim'}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        userType={userType}
        {...rest}
      /> */}
      <div className={mainPanelClasses} ref={mainPanel}>
        <IsoMerchantNavBar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          routes={routes}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          globalArrowOpen={globalArrowOpen}
          setGlobalArrow={setGlobalArrow}
          globalCalulcatorOpen={globalCalulcatorOpen}
          setGlobalCalulcator={setGlobalCalulcator}
          {...rest}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* <Heading title={getActiveRoute(routes)} /> */}
                {/* <Button
                    onClick={(e)=> props.logout()}
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid #48b4f7',
                      color: '#48b4f7',
                      fontWeight: 'normal',
                      textTransform: 'none',
                      }}>Log out</Button> */}
              </div>
              <RouteLoader>
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="/iso-merchant" to={`/admin/user-page`} />
                </Switch>
              </RouteLoader>
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <RouteLoader>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/iso-merchant" to={`/admin/user-page`} />
              </Switch>
            </RouteLoader>
          </div>
        )}
      </div>
      {/* <Hidden mdUp implementation="css">
        <Footer fluid />
      </Hidden> */}
    </div>
  );
}

function mapStateToProps() {
  return (state) => {
    return {
      userType: state.auth.user.userType,
      globalCalulcatorOpen: state.search.globalCalulcatorOpen,
      globalArrowOpen: state.search.globalArrowOpen,
    };
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    logout: () => dispatch(AuthActions.startLogout()),
    setGlobalCalulcator: (boolean) =>
      dispatch(setGlobalCalulcatorOpen(boolean)),
    setGlobalArrow: (boolean) => dispatch(setGlobalArrowOpen(boolean)),
  });
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(IsoAdmin);
