import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import ReactPaginate from "react-paginate";
import "./pagination.css";
// core components
import { MenuItem, Select } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";


// icons
import { ReactComponent as Add } from "assets/img/tuzo/icons/AddCircle.svg";
import { ReactComponent as ViewIcon } from "assets/img/tuzo/icons/ViewIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";



const headers = [
    "Name",
    "Cost",
    "Sold",
    "Remaining",
    "Sku",
    "Category",
    "Actions",
];

const analyticsHeader = [
    "Rank",
    "Name",
    "Original Cost",
    "Revenue",
    "Sold",
    "Remaining",
    "Actions"
]


function RewardTable(props) {
    const {
        classes,
        reservations,
        history,
        rewardsData,
        pageNumber,
        setPageNumber,
        index,
        setIndex,
        endIndex,
        setEndIndex,
        itemsPerPage,
        handlePageClick,
        listSwitch,
        deleteReward
    } = props;



    const getCategories = (data) => {
        let str = ""
        if (data.categories.electronics) {
            str += "Electronics, "
        }
        if (data.categories.experiences) {
            str += "Experiences, "
        }
        if (data.categories.giftCards) {
            str += "Gift Cards, "
        }
        if (data.categories.luxuryGoods) {
            str += "Luxury Goods, "
        }
        if (data.categories.raffle) {
            str += "Raffle, "
        }
        if (data.categories.travel) {
            str += "Travel, "
        }
        return str.replace(/,\s*$/, "")
    }

    const numberFormatter = new Intl.NumberFormat('en-US', {
    });

    const numberFormatterDecimals = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    useEffect(() => {
        rewardsData.slice(index, endIndex);
    }, [index]);
    const renderTableHeader = () => {
        return (
            <tr
                className={classes.tableRow}
                style={{
                    height: "38px",
                    border: "1px solid #8E8E8E",
                }}
            >
                {listSwitch === "List" ?
                    <>
                        {headers.map((key, index) => {
                            return (
                                <th className={classes.tableHeaders} key={key}>
                                    {key}
                                </th>
                            );
                        })}
                    </>
                    :
                    <>
                        {analyticsHeader.map((key, index) => {
                            return (
                                <th className={classes.tableHeaders} key={key}>
                                    {key}
                                </th>
                            );
                        })}
                    </>
                }

            </tr>
        );
    };

    const renderTableData = () => {
        return rewardsData
            .slice(
                pageNumber * itemsPerPage,
                pageNumber * itemsPerPage + itemsPerPage
            )
            .map((d, index) => {
                return (
                    <tr
                        className={index % 2 === 0 ? classes.tableRow : classes.tableRowOdd}
                        key={index}
                    >
                        <td className={classes.tableCell} style={{ fontWeight: 700, textAlign: "left" }}>{d.rewardName}</td>
                        <td className={classes.tableCell}>{numberFormatter.format(d.salePricePoints) + " Points"} / ${numberFormatterDecimals.format(d.salePrice)}</td>
                        <td className={classes.tableCell}>{d.sold ? d.sold : 0}</td>
                        <td className={classes.tableCell}>{d.remaining ? d.remaining : 0}</td>
                        <td className={classes.tableCell}>{d.sku}</td>
                        <td className={classes.tableCell}>
                            {getCategories(d)}
                        </td>
                        <td className={classes.tableCell}>
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                <Tooltip
                                    id="tooltip-top"
                                    title="View"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        style={{ background: "#FECD2C" }}
                                        className={classes.actionButton}
                                        onClick={() => history.push(`/admin/reward-detail/${d.rewardId}`)}
                                    >
                                        <ViewIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Edit"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    {d.categories.travel ?
                                        <Button
                                            onClick={() => history.push(`/admin/rewards/edit-form/travel/${d.rewardId}`)}
                                            className={classes.actionButton}
                                            style={{ background: "#E2A400", }}
                                        >
                                            <EditIcon />
                                        </Button>
                                        :
                                        d.categories.giftCards ?
                                            <Button
                                                onClick={() => history.push(`/admin/rewards/edit-form/gift-cards/${d.rewardId}`)}
                                                className={classes.actionButton}
                                                style={{ background: "#E2A400", }}
                                            >
                                                <EditIcon />
                                            </Button>
                                            :
                                            <Button
                                                onClick={() => history.push(`/admin/rewards/edit-form/${d.rewardId}`)}
                                                className={classes.actionButton}
                                                style={{ background: "#E2A400", }}
                                            >
                                                <EditIcon />
                                            </Button>
                                    }

                                </Tooltip>

                                <Tooltip
                                    id="tooltip-top"
                                    title="Assign Reward"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        className={classes.actionButton}
                                        style={{ background: "#56CA93" }}
                                        onClick={() => {
                                            history.push('/admin/assign-reward',
                                                {
                                                    rewardData: d
                                                }
                                            )
                                        }}
                                    >
                                        <Add />
                                    </Button>
                                </Tooltip>

                                <Tooltip
                                    id="tooltip-top"
                                    title="Delete"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        className={classes.actionButton}
                                        style={{ background: "#1A405F" }}
                                        onClick={(e) => deleteReward(e,d.rewardId)}
                                    >
                                        <TrashIcon />
                                    </Button>
                                </Tooltip>
                            </div>
                        </td>
                    </tr>
                );
            });
    };

    const renderAnalyticsTableData = () => {
        return rewardsData
            .slice(
                pageNumber * itemsPerPage,
                pageNumber * itemsPerPage + itemsPerPage
            )
            .map((d, index) => {
                return (
                    <tr
                        className={index % 2 === 0 ? classes.tableRow : classes.tableRowOdd}
                        key={index}
                    >
                        <td className={classes.tableCell}>{index + 1}. </td>
                        <td className={classes.tableCell} style={{ textAlign: "left", fontWeight: 700 }}>{d.rewardName}</td>
                        <td className={classes.tableCell}>${d.regularPrice ? numberFormatterDecimals.format(d.regularPrice) : numberFormatterDecimals.format(d.salePrice)} </td>
                        <td className={classes.tableCell}>{d.sold ? d.sold : 0}</td>
                        <td className={classes.tableCell}>{d.remaining ? d.remaining : 0}</td>
                        <td className={classes.tableCell}>{d.remaining ? d.remaining : 0}</td>

                        <td className={classes.tableCell}>
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                <Tooltip
                                    id="tooltip-top"
                                    title="View"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        style={{ background: "#FECD2C" }}
                                        className={classes.actionButton}
                                        onClick={() => history.push(`/admin/reward-detail/${d.rewardId}`)}
                                    >
                                        <ViewIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Edit"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        onClick={() => history.push(`/admin/rewards/edit-form/${d.rewardId}`)}
                                        className={classes.actionButton}
                                        style={{ background: "#E2A400", }}
                                    >
                                        <EditIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Assign Reward"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        className={classes.actionButton}
                                        style={{ background: "#56CA93" }}
                                        onClick={() => {
                                            history.push('/admin/assign-reward',
                                                {
                                                    rewardData: d
                                                }
                                            )
                                        }}
                                    >
                                        <Add />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Delete"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        className={classes.actionButton}
                                        style={{ background: "#1A405F" }}
                                        onClick={(e) => deleteReward(e,d.rewardId)}
                                    >
                                        <TrashIcon />
                                    </Button>
                                </Tooltip>
                            </div>
                        </td>
                    </tr>
                );
            });
    };

    return (
        <GridContainer>
            <GridItem xs={12}>
                <table className={classes.rewardsTable} style={{ marginBottom: "10px", marginTop: "10px" }}>
                    <tbody>
                        {renderTableHeader()}
                        {listSwitch === "List" ? renderTableData() : renderAnalyticsTableData()}
                    </tbody>
                </table>
                <GridContainer>
                    <GridItem xs={11} style={{ marginTop: "5px", display: "flex", justifyContent: "flex-end" }}>
                        <h5
                            className={classes.indexes}
                        >
                            {index + 1} - {endIndex} of{" "}
                            {Math.ceil(rewardsData.length)}
                        </h5>
                    </GridItem>
                    <GridItem xs={1}>
                        <ReactPaginate
                            forcePage={pageNumber}
                            paginationSize={0}
                            onPageChange={handlePageClick}
                            pageCount={Math.ceil(rewardsData.length / itemsPerPage)}
                            nextLabel={
                                <ArrowRight
                                    style={{
                                        fontSize: 25,
                                        fontWeight: "bold",
                                        color: "white",
                                    }}
                                />
                            }
                            previousLabel={
                                <ArrowLeft
                                    style={{
                                        fontSize: 25,
                                        fontWeight: "bold",
                                        color: "white",
                                    }}
                                />
                            }
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />
                    </GridItem>
                </GridContainer>

            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(dashboardStyle)
)(React.memo(RewardTable));
