/*eslint-disable*/
const rewards = []
const initDataRetrieved = false

export const initState = {
  rewards,
  initDataRetrieved
}


export default (state = initState, action) => {
  switch (action.type) {
    case "SET_REWARDS":
      var toReturn = {...state}
      toReturn.rewards = action.rewards
      toReturn.initDataRetrieved = true
      return toReturn

    default:
      return state
  }
}
