/*eslint-disable*/
import React, { useParams, useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";
import { NavLink, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';




// core components
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import AuthActions from 'store/actions/auth';
import { setPathname } from 'store/actions/windowActions'
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MerchantCustomDropdownMobile from "components/CustomDropdown/MerchantCustomDropdownMobile";

import powerTuzo from "assets/img/tuzo/PoweredByTuzoLogo.png"
import tuzoLogo from "assets/img/tuzo/tuzoHorizontal.png"
import { ReactComponent as CartIcon } from "assets/img/tuzo/icons/CartIcon.svg";
import { ReactComponent as CartIconWhite } from "assets/img/tuzo/icons/CartIconWhite.svg";
import { ReactComponent as Calculator } from "assets/img/tuzo/icons/calculator.svg";
import { ReactComponent as TransferPoints } from "assets/img/tuzo/icons/TransferPoints.svg";

import IsoStaffDropdownMobile from "components/CustomDropdown/IsoStaffDropdownMobile";
import IsoStaffDropdown from "components/CustomDropdown/IsoStaffDropdown";


const useStyles = makeStyles(styles);

export function AdminNavbar(props) {
  const classes = useStyles();
  const {
    color,
    rtlActive,
    brandText,
    routes,
    history,
    user,
    globalCalulcatorOpen,
    setGlobalCalulcator,
    globalArrowOpen,
    setGlobalArrow,
    cart,
    userCompany,
    userLogo
  } = props;
  const [clickDropdown, setClickDropdown] = useState(false);
  let company = userCompany?.replace(/ /g, '').toLowerCase()

  const isoStaffRoutesMobile = [
    { name: "Merchants", link: `/iso-staff/${company}/merchants` },
    // { name: "Rewards", link: `/iso-staff/${company}/rewards`, },
  ]

  const isoStaffRoutes = [
    { name: "Merchants", link: `/iso-staff/${company}/merchants` },
    { name: "Rewards", link: `/iso-staff/${company}/rewards`, },
    //   // { name: "Travel", link: "/merchant/travel" },
    //   { name: "Orders", link: "/merchant/orders" },
    //   { name: "Reports", link: `/iso-staff/${company}/reports/sales-by-merchant` },
  ]

  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive
    });

  const [pathName, setPathName] = useState(window.location.pathname)
  const path = window.location.pathname

  useEffect(() => {
    setPathName(path)
  }, [path])


  return (
    <div>
      <Hidden mdUp implementation="css">
        <AppBar id="merchant-navbar" className={classes.appBarMobile + appBarClasses}>
          <Toolbar className={classes.container} style={{ display: "block" }}>
            <GridContainer className={classes.sixtyPixelsMobile}>
              <GridItem className={classes.gridItemHover} xs={6}
                onClick={() => {
                  history.push(`/iso-staff/${company}/merchants`)
                  setGlobalCalulcator(false)
                }}
                style={{ marginTop: "-5px" }}
              >
                <img src={userLogo ? userLogo : tuzoLogo} className={classes.logoImg} />
                {/* <span className={classes.notificationsCount}>
                  {" "}
                  4
                </span> */}
              </GridItem>
              <GridItem xs={2}>
                <GridContainer className={classes.routesGridContainerMobile}>
                  <GridItem xs={12} className={classes.gridItemNoPadding}>
                    {
                      isoStaffRoutesMobile.map((r) => {
                        return (
                          <GridItem
                            onClick={() => {
                              history.push(r.link)
                              setGlobalCalulcator(false)
                            }}
                            className={
                              window.location.pathname === r.link
                                ? classes.routesGridItemUnderlineMobile
                                : classes.routesGridItem
                            }
                            key={r.name}
                            xs={7}
                          >
                            <span className={classes.headerLinksText}>{r.name}</span>
                          </GridItem>
                        );
                      })
                    }

                  </GridItem>
                </GridContainer>
              </GridItem>

              {/* <GridItem xs={2} className={classes.gridItemNoPadding}>
                <GridContainer className={classes.iconsGridContainerMobile} >
                  <GridItem xs={12}
                    onClick={() => {
                      setGlobalCalulcator(!globalCalulcatorOpen)
                      // history.push('/auth/calculator')
                      history.push('/merchant/cart')
                    }}
                    className={
                      !globalCalulcatorOpen
                        ? classes.iconGridItemMobile
                        : classes.iconGridItemTrueMobile
                    }
                  >
                    <CartIcon style={{}} />
                    <div>
                      <span className={classes.cartCountMobile}>
                        {" "}
                        {cart.length}
                      </span>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem> */}

              <GridItem
                xs={4}
                className={classes.gridItemNoPadding}
              >
                <IsoStaffDropdownMobile
                  setClickDropdown={setClickDropdown}
                  clickDropdown={clickDropdown}
                  adminNavbar
                  history={history}
                  dropdownList={
                    [
                      // "Activity",
                      // "Rewards",
                      // "Travel",
                      // "Orders",
                      // "Reports",
                      "Profile",
                      "Request Assistance",
                      { divider: true },
                      "Log Out",
                    ]}
                />
              </GridItem>

            </GridContainer>
          </Toolbar>
        </AppBar>
      </Hidden>

      <Hidden className={classes.hiddenSmall} smDown implementation="css">
        <AppBar id="merchant-navbar" className={classes.appBar + appBarClasses}>
          <Toolbar className={classes.container}>
            <GridContainer className={classes.sixtyPixels}>
              <GridItem className={classes.gridItemHover} xs={2}
                onClick={() => {
                  history.push(`/iso-staff/${company}/merchants`)
                  setGlobalCalulcator(false)
                }}
              >
                <img src={userLogo ? userLogo : tuzoLogo} style={{ height: "35px" }} />
                {/* <span className={classes.notificationsCount}>
                {" "}
                4
              </span> */}
              </GridItem>
              <GridItem className={classes.gridItem} xs={6}>
                <GridContainer className={classes.routesGridContainer}>
                  <GridItem xs={12} className={classes.gridItem}>
                    {
                      isoStaffRoutes.map((r) => {
                        return (
                          <GridItem
                            onClick={() => {
                              history.push(r.link)
                              setGlobalCalulcator(false)
                            }}
                            className={
                              window.location.pathname === r.link
                                ? classes.routesGridItemUnderline
                                : classes.routesGridItem
                            }
                            key={r.name}
                            xs={2}
                          >
                            <span className={classes.headerLinksText}>{r.name}</span>
                          </GridItem>
                        );
                      })
                    }
                  </GridItem>
                </GridContainer>
              </GridItem>


              {userLogo ?
                <GridItem xs={1} className={classes.gridItemNoPadding}>
                  <img src={powerTuzo} style={{ height: "35px", marginLeft:"-15px" }} />
                </GridItem>
                :
                null
              }

              <GridItem xs={userLogo? 2: 3} className={classes.gridItemSpaceBetween} style={{ background: "#06667C", }}>
                <div>
                  <span className={classes.name}>
                    {user.firstName ? user.firstName + " " + user.lastName : ""}
                  </span>
                </div>
                <IsoStaffDropdown
                  setClickDropdown={setClickDropdown}
                  clickDropdown={clickDropdown}
                  adminNavbar
                  history={history}
                  dropdownList={[
                    "Profile",
                    { divider: true },
                    "Log Out",
                  ]}
                />
              </GridItem>

              <GridItem xs={1} className={classes.gridItemNoPadding}>
                <GridContainer className={classes.iconsGridContainer} >
                  <GridItem xs={12}
                    onClick={() => {
                      history.push(`/iso-staff/${company}/calculator`)
                      setGlobalCalulcator(!globalCalulcatorOpen)
                    }}
                    className={
                      !globalCalulcatorOpen
                        ? classes.iconGridItem
                        : classes.iconGridItemTrue
                    }
                  >
                    <Calculator style={{ marginRight: "15px" }} />
                  </GridItem>
                </GridContainer>
              </GridItem>

            </GridContainer>
          </Toolbar >
        </AppBar >
      </Hidden>
    </div>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};


function mapStateToProps(state) {
  return {
    userLogo: state.auth.user.logo ? state.auth.user.logo : "",
    userCompany: state.auth.user.companyName,
    user: state.auth.user,
    cart: state.cart.cart
  };
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AuthActions.startLogout()),
  setPathname: (path) => dispatch(setPathname(path))
});

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(AdminNavbar));
