import React from 'react';
import DonutChart from 'react-donut-chart';
// core components
import Box from '@material-ui/core/Box';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import Button from 'components/CustomButtons/Button.js';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import actionsForNavigation from 'store/actions/navigation';
import AuthActions from 'store/actions/auth';
import { Checkbox, FormControl, Typography } from '@material-ui/core';

// @material-ui/icons
import { AiOutlineDollar } from 'react-icons/ai';
import { Add, Search } from '@material-ui/icons';

import Style from 'assets/jss/material-dashboard-pro-react/views/isoAdminDashboardStyle';

import withStyles from '@material-ui/core/styles/withStyles';
import { useState } from 'react';
import { InputAdornment, MenuItem, Select, TextField } from '@material-ui/core';

export function DashboardFilter(props) {
    const {
        classes,
        history,
        statusDropdown,
        setStatusDropdown,
        ascDropdown,
        setAscDropdown,
        searchString,
        setSearchString,
        listSwitch,
        setListSwitch,
        statusArr,
        ascArr,
        itemsPerPage,
        setItemsPerPage,
        entriesArr,
        handleSearch,
        emptyData,
        resetEmptyData,
        userTypeArr,
        userTypeArrFilter,
        handleUserTypeFilter,
        setEndIndex,
        handleFilter,
    } = props;
    return (
        <div>
            <GridContainer>
                <GridItem xs={4} style={{ display: "flex", alignItems: "center" }}>
                    <Typography className={classes.sortByTypography}>
                        Sort By
                    </Typography>
                    <Select
                        className={classes.selectMenuSort}
                        value={statusDropdown}
                        disableUnderline
                        classes={{
                            root: classes.selectText,
                        }}
                        onChange={(e) => {
                            setStatusDropdown(e.target.value)
                            handleFilter(ascDropdown, e.target.value)
                        }}
                    >
                        {statusArr.map((o, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    value={o}
                                    classes={{
                                        root: classes.selectMenuItem,
                                    }}
                                >
                                    {o}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <Select
                        className={classes.selectAsc}
                        value={ascDropdown}
                        disableUnderline
                        classes={{
                            root: classes.selectText,
                        }}
                        onChange={(e) => {
                            setAscDropdown(e.target.value)
                            handleFilter(e.target.value, statusDropdown)
                        }}
                    >
                        {ascArr.map((o, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    value={o}
                                    classes={{
                                        root: classes.selectMenuItem,
                                    }}
                                >
                                    {o}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </GridItem>

                <GridItem xs={3}>
                    <div>
                        <FormControl style={{ margin: 1, width: "100%" }}>
                            <Select
                                style={{ width: "100%", paddingTop:"10px" }}
                                className={classes.selectMenuSort}
                                value={userTypeArrFilter}
                                disableUnderline
                                classes={{
                                    root: classes.selectText,
                                }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                multiple
                                displayEmpty
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <p className={classes.selectPlaceHolder}>User Type</p>;
                                    }
                                    return selected.join(', ');
                                }}
                                onChange={handleUserTypeFilter}
                            >
                                <MenuItem
                                    disabled
                                    value=""
                                    classes={{
                                        root: classes.selectPlaceHolder,
                                    }}
                                >
                                    <p
                                    >
                                        User Type
                                    </p>
                                </MenuItem>
                                {userTypeArr.map((o, i) => {
                                    return (
                                        <MenuItem
                                            key={o}
                                            value={o}
                                            classes={{
                                                root: classes.selectText,
                                            }}
                                        >
                                            <Checkbox
                                                style={{ color: "#1A405F" }}
                                                checked={userTypeArrFilter.indexOf(o) > -1}
                                            />
                                            {o}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </GridItem>

                <GridItem xs={3}>
                    <TextField
                        className={classes.searchBar}
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch();
                            }
                        }}
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                adornedEnd: classes.adournedEnd,
                                input: classes.input
                            },
                            endAdornment: (
                                !emptyData ?
                                    <InputAdornment className={classes.adournedEnd} position="end">
                                        <Button
                                            className={classes.searchButton}
                                            onClick={() => handleSearch()}
                                        >
                                            <Search style={{ marginRight: "5px" }} />
                                            Search
                                        </Button>
                                    </InputAdornment>
                                    :
                                    <InputAdornment className={classes.adournedEnd} position="end">
                                        <Button
                                            className={classes.searchButton}
                                            onClick={() => resetEmptyData()}
                                            style={{ backgroundColor: "#06667C" }}
                                        >
                                            <Search style={{ marginRight: "5px" }} />
                                            Reset
                                        </Button>
                                    </InputAdornment>
                            ),
                        }}
                    />
                </GridItem>

                <GridItem xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <Typography className={classes.sortByTypography}>
                        Show
                    </Typography>

                    <Select
                        className={classes.selectAsc}
                        value={itemsPerPage}
                        disableUnderline
                        classes={{
                            root: classes.selectText,
                        }}
                        onChange={(e) => {
                            setItemsPerPage(e.target.value)
                            setEndIndex(e.target.value)
                        }}
                    >
                        {entriesArr.map((o, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    value={o}
                                    classes={{
                                        root: classes.selectMenuItem,
                                    }}
                                >
                                    {o}
                                </MenuItem>
                            );
                        })}
                    </Select>

                    <Typography className={classes.sortByTypography}>
                        Entries
                    </Typography>
                </GridItem>
            </GridContainer>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        // nothing yet
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
        edit: (key) =>
            dispatch(
                actionsForNavigation.openChild('Profile Editor', '/admin/user/editor')
            ),
        logout: () => dispatch(AuthActions.startLogout()),
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(DashboardFilter));
