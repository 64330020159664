import React, { useState } from 'react';
import moment from "moment"
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Datetime from "react-datetime";
import { CSVLink } from "react-csv";

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import { Card, Dialog, DialogActions, DialogContent, IconButton, InputAdornment, MenuItem, Select, TextField, Typography } from '@material-ui/core';

// @material-ui/icons
import { ReactComponent as CSVIcon } from "assets/img/tuzo/icons/CSVIcon.svg";
import { Search } from '@material-ui/icons';


// stylings
import Style from 'assets/jss/material-dashboard-pro-react/views/reportsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import { zip } from 'lodash';




export function ReportsFilter(props) {
    const {
        classes,
        history,
        sortValue,
        fromDate,
        toDate,
        reportSelections,
        headers,
        numberFormatterDecimals,
        numberFormatter,
        searchString,
        setSearchString,
        handleSearch,
        csvData
    } = props;

    return (
        <div>
            <GridContainer>
                <GridItem xs={4} >
                    <div style={{ display: "flex", }}>
                        <Typography variant="h2" className={classes.filterDesc} >
                            Switch Report
                        </Typography>
                        <Select
                            className={classes.selectMenuSortReportsPage}
                            value={sortValue}
                            disableUnderline
                            classes={{
                                root: classes.selectText,
                            }}
                            onChange={(e) => {
                                history.push(e.target.value.link)
                            }}
                            displayEmpty
                            renderValue={
                                sortValue !== "" ? undefined : () => "Sales by Merchant"
                            }
                        >
                            {reportSelections.map((o, i) => {
                                return (
                                    <MenuItem
                                        key={i}
                                        value={o}
                                        classes={{
                                            root: classes.selectMenuItem,
                                        }}
                                    >
                                        {o.name}

                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </div>
                </GridItem>

                <GridItem xs={4}>
                    <TextField
                        className={classes.searchBar}
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch();
                            }
                        }}
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                // adornedEnd: classes.adournedEnd,
                                input: classes.input
                            },
                            endAdornment: (
                                <InputAdornment className={classes.adournedEnd} position="end">
                                    <Button
                                        onClick={() => handleSearch()}
                                        className={classes.searchButton}>
                                        <Search style={{ marginRight: "5px", marginTop: "-4px" }} />
                                        Search
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                    />
                </GridItem>


                <GridItem xs={4} style={{ display: "flex", justifyContent: "flex-end", }}>
                    <Button
                        className={classes.csvButton}
                    >
                        <CSVLink
                            style={{ color: "white" }}
                            headers={headers}
                            data={
                                csvData.map(d => ({
                                    // merchantNumber: d[0].merchantNumber,
                                    // name: d[0].merchantName,
                                    // email: d[0].email,
                                    // netAmount: numberFormatterDecimals.format(d[0].netAmount),
                                    // prepaidDiscount: numberFormatterDecimals.format(d[0].prepaidDiscount),
                                    // reportDate: moment(d[0].reportDate).format("MM/DD/YY"),
                                    // returnAmount: numberFormatterDecimals.format(d[0].returnAmount),                                    pointsEarned: numberFormatter.format(d[1]),
                                    // saleAmount: numberFormatterDecimals.format(d[0].saleAmount),  
                                    // terminalNumber: d[0].terminalNumber,
                                    // transactionCount: d[0].transactionCount,
                                    merchantNumber: d[0].merchantNumber,
                                    name: d[0].merchantName,
                                    quantity: d[0].transactionCount,
                                    pointsEarned: numberFormatter.format(d[1]),
                                    dayTotal: numberFormatterDecimals.format(d[0].netAmount),
                                    reportDate: moment(d[0].reportDate).format("MM/DD/YY"),
                                    userBasisPts: d[0].userBasisPts,

                                }))
                            }
                            filename={`${fromDate}_deposit_history`}
                        >
                            <CSVIcon style={{ marginRight: "10px", }} /> Export CSV

                        </CSVLink>
                    </Button>
                </GridItem>
            </GridContainer>
        </div >
    );
}

function mapStateToProps(state) {
    return {
        // nothing yet
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(ReportsFilter));
