/*eslint-disable*/
import React, { useState, createRef, useEffect } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import actionsForNavigation from "store/actions/navigation";
import AuthActions from 'store/actions/auth';
import { db, firebase, storage } from "firebase/client";
import UserInfo from "./components/UserInfo";
import BillingInfo from "./components/BillingInfo";
import GatewayPage from "./components/Gateway";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";


import Slide from "@material-ui/core/Slide";
import { Typography } from "@material-ui/core";

import { updateUserData, updateUserPhoto, updateUserLogo } from "store/actions/auth.js";

// @material-ui/icons
import { ReactComponent as Edit } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";
import { ReactComponent as Delete } from "assets/img/tuzo/icons/Delete.svg";


//styling 
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import tuzoBg from "assets/img/tuzo/carouselBackground.png";
import { deleteUserPhoto } from "store/actions/auth";
import "./admincalendar.css"
import { deleteUserLogo } from "store/actions/auth";

const intervalArr = [
  "30",
  "60",
  "90"
]

const gatewayArr = [
  {
    type: "Fiserv AccessOne",
    id: 0
  },
  {
    type: "IRIS",
    id: 1
  },
  {
    type: "Maverick",
    id: 2
  }
]

export function UserProfile(props) {
  const {
    classes,
    firstName,
    lastName,
    email,
    cellPhone,
    secondaryPhone,
    shippingAddress,
    birthday,
    companyName,
    uid,
    user,
    userInfo,
    authInfo,
    profilePic,
    updateUserLogo,
    logo
  } = props;
  const [firstNameState, setFirstNameState] = useState(
    userInfo.firstName ? userInfo.firstName : firstName ? firstName : ""
  );
  const [lastNameState, setLastNameState] = useState(
    userInfo.lastName ? userInfo.lastName : lastName ? lastName : ""
  );
  const [emailState, setEmailState] = useState(
    authInfo.email ? authInfo.email : email ? email : ""
  );
  const [cellPhoneState, setCellPhoneState] = useState(
    userInfo.cellPhone ? userInfo.cellPhone : cellPhone ? cellPhone : ""
  );

  const [secondaryPhoneState, setSecondaryPhoneState] = useState(
    userInfo.secondaryPhone ? userInfo.secondaryPhone : secondaryPhone ? secondaryPhone : ""
  )

  const [shippingAddressState, setShippingAddressState] = useState(
    userInfo.shippingAddress ? userInfo.shippingAddress : shippingAddress ? shippingAddress : ""
  )

  const [companyState, setCompanyState] = useState(
    userInfo.companyName
      ? userInfo.companyName
      : companyName
        ? companyName
        : ""
  );

  const [birthdayState, setBirthdayState] = useState(
    userInfo.birthday ? userInfo.birthday : birthday ? birthday : ""
  )
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [globalEdit, setGlobalEdit] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [billingEdit, setBillingEdit] = useState(false)
  const [cardNumber, setCardNumber] = useState("")
  const [expDate, setExpDate] = useState("")
  const [nameOnCard, setNameOnCard] = useState("")
  const [billingAddress, setBillingAddress] = useState("")
  const [asDefault, setAsDefault] = useState(true)
  const [newPayment, setNewPayment] = useState(false)
  const [typeOfPayment, setTypeOfPayment] = useState({
    card: true,
    ach: false
  })
  const [autoApprove, setAutoApprove] = useState(user?.autoApprove ? user.autoApprove : false)
  const [interval, setInterval] = useState(user?.ptsReleaseDay ? user.ptsReleaseDay : "60")
  const [gateway, setGateway] = useState("Fiserv AccessOne")
  const [apiEdit, setApiEdit] = useState(false)
  const [apiInfo, setApiInfo] = useState(user.apiInfo ? user.apiInfo :
    [
      {
      apiType: "Fiserv AccessOne",
      apiKey: "",
      apiSecret: "",
      status: "Unused",
      id: 0
      },
      {
          apiType: "IRIS",
          apiKey: "",
          apiSecret: "",
          status: "Unused",
          id: 1
      },
      {
          apiType: "Maverick",
          apiKey: "",
          apiSecret: "",
          status: "Unused",
          id: 2
      },

  ])

  useEffect(() => {
    if (progress === 100) {
      setUploading(false);
    }
  }, [progress]);


  function formatPhoneNumber(phoneNumber) {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return "";
  }

  const updateIsoMerchantsApproval = () => {
    db
      .collection('users')
      .where("IsoAdminCompanyName", "==", companyName)
      .get()
      .then((snapshots) => {
        if (!snapshots.empty) {
          snapshots.forEach((s) => {
            db.collection('users').doc(s.id).update({
              autoApprove,
              ptsReleaseDay: autoApprove ? interval : "60",
              apiInfo
            })
          })
        }
      })
  }

  const updateUserInfo = (e) => {
    e.preventDefault();
    var newInfo = {
      firstName: firstNameState,
      lastName: lastNameState,
      companyName: companyState,
      birthday: birthdayState,
      email: emailState,
      cellPhone: cellPhoneState,
      secondaryPhone: secondaryPhoneState,
      shippingAddress: shippingAddressState,
      autoApprove,
      ptsReleaseDay: autoApprove ? interval : "60",
      apiInfo
    };
    props.updateUserData(uid, newInfo)
      .then(() => {
        updateIsoMerchantsApproval()
      })
      .then(() => {
        setGlobalEdit(false)
      })
  };

  const handleImageUpload = (event) => {
    var previewUrl = URL.createObjectURL(event.target.files[0]);
    var file = event.target.files[0];
    const storageRef = storage.ref(uid);
    const fileRef = storageRef.child("profilePic");

    var uploadTask = fileRef.put(file);

    // fileRef.put(file).then((snapshot) => {
    setUploading(true)

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      (snapshot) => {
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then((downloadURL) => {
            console.log("downloadURL", downloadURL);
            updateUserLogo(uid, { url: downloadURL, });
          })
          .catch(() => { });
      }
    );
  };

  const deleteImage = () => {
    props.deleteUserLogo(uid)
    setConfirmDelete(false)
  }

  return (
    <div>
      <Hidden mdUp implementation='css'>
        <div>
          <Typography variant="h2" className={classes.headerTitle} style={{ color: "#1A405F" }}>
            Please View Page on Desktop / Laptop
          </Typography >
        </div>
      </Hidden>

      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem xs={6} style={{ display: "flex" }}>
            <Typography variant="h2" className={classes.headerTitle}>
              {firstNameState + " " + lastNameState + "  "}123
            </Typography>


            {!globalEdit ?
              <Button
                onClick={() => setGlobalEdit(true)}
                className={classes.editButton}
              >
                <Edit style={{ marginRight: "10px", marginTop: "-5px" }} /> Edit
              </Button>
              :
              <div>
                <Button
                  className={classes.editButton}
                  style={{ background: "#1A405F", width: "128px" }}
                  onClick={updateUserInfo}
                >
                  <SaveIcon style={{ marginRight: "10px", marginTop: "-5px" }} /> Save
                </Button>
                <Button
                  onClick={() => setGlobalEdit(false)}
                  className={classes.editButton}
                  style={{ background: "#E2A400", width: "143px" }}
                >
                  <Delete style={{ marginRight: "10px", marginTop: "-5px" }} /> Cancel
                </Button>
              </div>
            }
          </GridItem>

          <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>

            <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: "-10px" }}>
              <Typography variant="h2" className={classes.headerDesc}>
                User Type
              </Typography>
              <Typography variant="h2" className={classes.headerDesc} style={{ fontWeight: 500, fontSize: "18px", lineHeight: "18px", marginTop: "10px" }}>
                {user.userType}
              </Typography>
            </div>

          </GridItem>
        </GridContainer>

        <GridContainer >
          <GridItem xs={12} style={{ marginTop: "20px" }}>
            <UserInfo
              logo={logo}
              user={user}
              profilePic={profilePic}
              firstNameState={firstNameState}
              setFirstNameState={setFirstNameState}
              lastNameState={lastNameState}
              setLastNameState={setLastNameState}
              companyState={companyState}
              setCompanyState={setCompanyState}
              birthdayState={birthdayState}
              setBirthdayState={setBirthdayState}
              emailState={emailState}
              setEmailState={setEmailState}
              cellPhoneState={cellPhoneState}
              setCellPhoneState={setCellPhoneState}
              secondaryPhoneState={secondaryPhoneState}
              setSecondaryPhoneState={setSecondaryPhoneState}
              shippingAddressState={shippingAddressState}
              setShippingAddressState={setShippingAddressState}
              globalEdit={globalEdit}
              handleImageUpload={handleImageUpload}
              uploading={uploading}
              progress={progress}
              deleteImage={deleteImage}
              formatPhoneNumber={formatPhoneNumber}
              confirmDelete={confirmDelete}
              setConfirmDelete={setConfirmDelete}
              autoApprove={autoApprove}
              setAutoApprove={setAutoApprove}
              interval={interval}
              setInterval={setInterval}
              intervalArr={intervalArr}
            />
          </GridItem>

          <GridItem xs={8}>
            <GatewayPage
              gateway={gateway}
              setGateway={setGateway}
              globalEdit={globalEdit}
              apiInfo={apiInfo}
              setApiInfo={setApiInfo}
            />
          </GridItem>
        </GridContainer>
      </Hidden>
    </div>
  );
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authInfo: state.form.registration.steps.basicDetails.fields,
    userInfo: state.form.registration.steps.commonProfile
      ? state.form.registration.steps.commonProfile.fields
      : {},
    profilePic: state.auth.profilePic
      ? state.auth.profilePic
      : state.auth.user.profilePic
        ? state.auth.user.profilePic
        : false,
    logo: state.auth.user.logo ? state.auth.user.logo : "",
    uid: state.auth.user.uid,
    firstName: state.auth.firstName
      ? state.auth.firstName
      : state.auth.user.firstName,
    lastName: state.auth.lastName
      ? state.auth.lastName
      : state.auth.user.lastName,
    userType:
      state.auth.userType !== ""
        ? state.auth.userType
        : state.auth.user.userType,
    email: state.auth.email ? state.auth.email : state.auth.user.email,
    dateOfBirth: state.auth.dateOfBirth
      ? state.auth.dateOfBirth
      : state.auth.user.dateOfBirth,
    phoneNumber: state.auth.user && state.auth.user.phoneNumber,
    cellPhone: state.auth.cellPhone
      ? state.auth.cellPhone
      : state.auth.user.cellPhone,
    secondaryPhone: state.auth.secondaryPhone
      ? state.auth.secondaryPhone
      : state.auth.user.secondaryPhone,
    officePhone: state.auth.officePhone
      ? state.auth.officePhone
      : state.auth.user.officePhone,
    companyName: state.auth.companyName
      ? state.auth.companyName
      : state.auth.user.companyName,
    shippingAddress: state.auth.shippingAddress
      ? state.auth.shippingAddress
      : state.auth.user.shippingAddress,
    birthday: state.auth.birthday
      ? state.auth.birthday
      : state.auth.user.birthday,
    about: state.auth.about ? state.auth.about : state.auth.user.about,
    user: state.auth.user,
    userSet: state.auth.user.userSet,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    edit: (key) =>
      dispatch(
        actionsForNavigation.openChild("Profile Editor", `/admin/user/editor`)
      ),
    updateUserData: (uid, newInfo) => dispatch(updateUserData(uid, newInfo)),
    updateUserPhoto: (id, document) => dispatch(updateUserPhoto(id, document)),
    deleteUserPhoto: (id) => dispatch(deleteUserPhoto(id)),
    updateUserLogo: (id, document) => dispatch(updateUserLogo(id, document)),
    deleteUserLogo: (id) => dispatch(deleteUserLogo(id)),
    logout: () => dispatch(AuthActions.startLogout()),
  });
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(UserProfile);
